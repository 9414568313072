import React, { ChangeEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../../store/store/store';
import { CurrentState } from '../../../store/types/CurrentState.Types';
import { FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutocontroleTab } from '../../../store/types/FicheAutocontrole.Types';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { colorsAutocontrole } from '../../../styles/ColorsAutocontrole.style';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled, RadioAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import StateService from '../../../utils/state.service';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";


interface PropsFromState {
    critere: FicheAutoControleCritere,
    updateCritere: Function,
    data: FicheAutocontrole,
    currentState: CurrentState,
    updateTab: Function
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const RadioAutocontrole: React.FC<AllProps> = ({ critere, updateCritere, goBackStateHasChanged, data, currentState, updateTab }) => {

    const choices = !!critere.choices ? critere.choices as string[] : ["Oui", "Non"]

    const initConditional = () => {
        const myMap = new Map();

        critere.criteresConditionnes?.forEach((critaria, key) => {
            myMap.set(critaria[key].displayWhenValue, critaria);
        })
        localStorage.pictureValuesCacheForRB = JSON.stringify(Array.from(myMap.entries()));
    }

    const isValidationDirectionQualite = () => {
        let listeValidation = ['vdq_logements', 'vdq_pc_super', 'vdq_pc_infra', 'vdq_vrd', 'vdq_rg_rss'];
        return listeValidation.includes(critere.id as string);
    }

    useEffect(() => {
        if (critere && critere.criteresConditionnes && critere.criteresConditionnes.length > 0) {
            let cacheMap = JSON.parse(localStorage.getItem("pictureValuesCacheForRB") || '{}');
            if (!cacheMap || JSON.stringify(cacheMap) === JSON.stringify({}) || cacheMap.size === 0) {
                initConditional();
            }
        }
    }, []);

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        goBackStateHasChanged();
        critere.activated = event.target.checked;
        updateCritere(critere);
    };

    const handleChangeRadio = (event: ChangeEvent<{}>, checked: boolean) => {
        goBackStateHasChanged();
        critere.value = (event.target as HTMLInputElement).value as unknown as string;
        updateCritere(critere);
        if (critere.criteresConditionnes && critere.criteresConditionnes.length > 0) {
            let cacheMap = new Map(JSON.parse(localStorage.pictureValuesCacheForRB));

            let critariaCondiCached: any = cacheMap.size > 0 ? cacheMap.get(critere.value) : [];

            if (critariaCondiCached && critariaCondiCached.length > 0) {
                critere.criteresConditionnes.forEach((critCondi, key) => {
                    if (critCondi[key].displayWhenValue === critere.value) {
                        critCondi = critariaCondiCached;
                    }
                });
                updateTab(StateService.changeCriteraState(data.tabs[currentState.currentPage - 1], currentState.currentGroup, critere), currentState.currentPage - 1);
            } else {
                updateTab(StateService.initCriteraConditionalState(data.tabs[currentState.currentPage - 1], currentState.currentGroup, critere), currentState.currentPage - 1);
            }
        }
    };


    const selectBorderColor = (choice: string) => {
        if (!critere.activated) {
            return { borderColor: colorsAutocontrole.disabled, color: colorsAutocontrole.disabled }
        }
        return critere.value === choice ? { borderColor: colorsAutocontrole.primary }
            : { borderColor: colorsAutocontrole.checkboxDefault, color: colorsAutocontrole.checkboxDefault }
    }

    const createRadioButton = () => {
        let radioButtons: JSX.Element[] = [];

        choices.forEach((choice: string) => {
            radioButtons.push(
                <div key={critere.index.toString().concat(choice)} className={"critera-box".concat(isCheckboxesAlign() ? " critera-box-align" : "")} style={selectBorderColor(choice)}>
                    <RadioAutocontroleStyled value={choice} onChange={handleChangeRadio} checked={(critere.value === choice) && (critere.activated || isValidationDirectionQualite())} disabled={!critere.activated} />
                    {choice}
                </div>
            )
        })

        return radioButtons;
    }

    const isCheckboxesAlign = () => {
        return ((critere.choices?.length === 2) && (critere.choices.join().length <= 7))
    }

    return (<div>
        <div className="critera-label">
            <LabelCritereAutocontroleStyled>{critere.title}
                {critere.active ?
                    <SwitchAutocontroleStyled className="elt-float-right" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt="" />} />
                    : ''
                }
            </LabelCritereAutocontroleStyled>
        </div>
        <div style={isCheckboxesAlign() ? { display: "flex" } : {}}>
            {createRadioButton()}
        </div>
    </div>);
};


const mapStateToProps = ({ goBackState, ficheAutocontrole, currentState }: ApplicationState) => ({
    goBackState: goBackState,
    data: ficheAutocontrole.data,
    currentState: currentState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE }),
        updateTab: (data: FicheAutocontroleTab, currentPage: number) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: currentPage }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioAutocontrole);