import React from 'react';
import {FicheAutoControleCritere} from '../../../store/types/FicheAutocontrole.Types';
import { SliderAutocontroleStyled, SwitchAutocontroleStyled, LabelCritereAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import SliderIconActive from "./../../../assets/value-indicator-shape.svg";
import SliderIconDefault from "./../../../assets/value-indicator-default.svg";
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { connect } from 'react-redux';

interface PropsFromState {
    critere:FicheAutoControleCritere,
    updateCritere: Function,
    goBackStateHasChanged: Function
}

export const valuetext = (value: number) => {
  return `${value}`;
}

export const  valueLabelFormat = (value: number) => {
  return value + "%";
}

const SliderAutocontrole: React.FC<PropsFromState> = ({critere, updateCritere, goBackStateHasChanged}) => {

    const handleChangeSlider = (event: any, newValue: number | number[]) => {

            critere.value = newValue as number;
            updateCritere(critere);
            goBackStateHasChanged();
       
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.activated = event.target.checked;
        updateCritere(critere);
    };

    const pointThumbComponent = (props: any)=>{
      if(critere.activated && critere.value !== -1){
        return (
          <span {...props}>
            <span className='slider-icon-value'>{critere.value + "%"}</span>
            <img className='slider-icon' 
              src={SliderIconActive} alt=""/>
          </span>)
      }
      return (
        <span {...props}>
          <span className={!critere.activated ? 'slider-icon-value-disabled' : 'slider-icon-value-default'}>{!critere.activated ? '[Désactivé]' : '[A compléter]'}</span>
          <img className='slider-icon-default' src={SliderIconDefault} alt=""/>
        </span>)
    }

    
    return(<div style={{paddingBottom:48}}>
                <div className="critera-label-slider">
               
                  <LabelCritereAutocontroleStyled> {critere.title}
                  {critere.active ? 
                            <SwitchAutocontroleStyled className="elt-float-right" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SliderIconDefault} alt=""/>}/> 
                            : ""
                         }
                         </LabelCritereAutocontroleStyled>
                </div>

                <SliderAutocontroleStyled 
                  className={(critere.value === -1 && critere.activated) ? "slider-not-define" : ""} 
                  ThumbComponent={pointThumbComponent}  
                  value={(critere.value === -1 || !critere.activated)? 0 :critere.value as number} 
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-restrict"
                  step={critere.step ? critere.step : 5} min={0} max={100} 
                  onChange={handleChangeSlider} 
                  disabled={!critere.activated}
                  valueLabelDisplay="on"
                />
            </div>); 
};

const mapStateToProps = ({ goBackState }: ApplicationState) => ({
  goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
      goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderAutocontrole);