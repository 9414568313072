import React from 'react';
import {FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutocontroleTab, FicheAutocontroleTabInfos} from '../../store/types/FicheAutocontrole.Types';
import SliderAutocontrole from './TypesCriteres/SliderAutocontrole';
import RadioAutocontrole from './TypesCriteres/RadioAutocontrole';
import SelectAutocontrole from './TypesCriteres/SelectAutocontrole';
import InputTextAutocontrole from './TypesCriteres/InputTextAutocontrole';
import CheckBoxAutocontrole from './TypesCriteres/CheckboxAutocontrole';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store/store/store';
import { connect } from 'react-redux';
import { CurrentState } from '../../store/types/CurrentState.Types';
import StateService from '../../utils/state.service';
import PictureAutocontrole from './TypesCriteres/PictureAutocontrole';
import DateAutocontrole from './TypesCriteres/DateAutocontrole';
import PeriodeAutocontrole from './TypesCriteres/PeriodeAutocontrole';



interface PropsFromState {
    critere:FicheAutoControleCritere
}

interface PropsFromDispatch {
    data: FicheAutocontrole,
    currentState:CurrentState,
    updateTab:Function,
    updateTabInfos:Function
}

type AllProps = PropsFromState & PropsFromDispatch;

const Critere: React.FC<AllProps> = ({critere, data, updateTab, updateTabInfos, currentState}) => {

    const updateCritere = (newCritere: FicheAutoControleCritere) => {
        if(currentState.isTabInfos){
            updateTabInfos(StateService.changeCriteraTabInfo(data.tabInfos, newCritere));
        }else{
            updateTab(StateService.changeCriteraState(data.tabs[currentState.currentPage -1], currentState.currentGroup, newCritere), currentState.currentPage -1);
        }
    }

    const createCritere = () => {
        switch(critere.typeCriteria){
            case 'IT':
                return <InputTextAutocontrole critere={critere} updateCritere={updateCritere} />;
            case 'RB':
                return <RadioAutocontrole critere={critere} updateCritere={updateCritere}/>;
            case 'SE':
                return <SelectAutocontrole critere={critere} updateCritere={updateCritere}/>;
            case 'SL':
                return <SliderAutocontrole critere={critere} updateCritere={updateCritere}/>;
            case 'CB': 
                return <CheckBoxAutocontrole critere={critere} updateCritere={updateCritere}/>;
            case 'PI': 
                // PS: j'ai laissé cette condition pour une évolution future... ca concerne la story 20 
                    return <PictureAutocontrole critere={critere} updateCritere={updateCritere}/>;
            case 'DA':
                return <DateAutocontrole critere={critere} updateCritere={updateCritere} disablePast={false}/>;
            case 'PE':
                return <PeriodeAutocontrole critere={critere} updateCritere={updateCritere}/>;
            default:
                return (<div style={{color:'red'}}>Critere {critere.typeCriteria} non valide</div>)
        }
    }

    return(
        <div>
         {!!critere.attachedElementIndex ? 
                    data.tabs[currentState.currentPage].groups[currentState.currentGroup].criterias[critere.attachedElementIndex].value :''}
            {createCritere()}
        </div>
    )

};

const mapStateToProps = ({ ficheAutocontrole, currentState }: ApplicationState) => ({
    data: ficheAutocontrole.data, 
    currentState: currentState
  });
  
  const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
      updateTab:(data: FicheAutocontroleTab, currentPage:number) => dispatch({type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: currentPage}),
      updateTabInfos:(data: FicheAutocontroleTabInfos) => dispatch({type:FicheAutocontroleActionTypes.UPDATE_TAB_INFOS, payload:data})
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Critere);