import React from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutocontroleIntervenant, FicheAutocontroleTabInfos } from "../../store/types/FicheAutocontrole.Types";
import SelectAutocontrole from "../../components/Criteres/TypesCriteres/SelectAutocontrole";
import { Backdrop, Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Slide, Theme } from "@material-ui/core";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { useHistory } from "react-router-dom";
import { userRoles, UserState } from "../../store/types/user.Types";
import { AUTOCONTROLE_STATUT, GeneralInformationsState, GeneralInformationsTypes } from "../../store/types/generalInformations.Types";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/store/store";
import { TransitionProps } from "@material-ui/core/transitions";
import DateAutocontrole from "../../components/Criteres/TypesCriteres/DateAutocontrole";
import DateUtils from "../../utils/DateUtils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { API } from "../../utils/callAPI";
import { REDIRECT_HOME_ACQ } from "../../utils/constantes/contantes";
import { getEmptyDateCritere, getEmptySelectCritere } from "../../utils/constantes/emptyObjects";
import { ReferentielUtils } from "../../utils/ReferentielUtils";
import { generalInformationsToCreateAutocontroleParams, GeneralInformationsTotabInfos, parametresAutocontroleStateToGeneralInformations } from "../../utils/mappers/generalInformationsMapper";
import { getUserRole } from "../../utils/AccessToken";
import IntervenantUtils from "../../utils/IntervenantUtils";
import { GoBackCurrentStateActions } from "../../store/types/GoBackState.Types";
import { AutocontroleIntervenants, IntervenantsAutocontroleTypes, IntervenantsState } from "../../store/types/intervenants.Types";
import MessageDeadline from "../../utils/InformationMessage";


interface PropsFromDispatch {
    data: FicheAutocontrole,
    user: UserState,
    generalInformations: GeneralInformationsState,
    intervenantsState: IntervenantsState,
    setGeneralInformations: Function,
    updateParametresState: Function,
    resetParametresState: Function,
    updateTabInfos: Function,
    fetchData: Function,
    initGoBackState: Function
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }),
);

const ParametresAutocontrole: React.FC<PropsFromDispatch> = ({ data, updateTabInfos, user, generalInformations, setGeneralInformations, intervenantsState, updateParametresState, resetParametresState, fetchData, initGoBackState }) => {

    /**
     * Constantes et state :
     */
    const history = useHistory();

    const [isSaved, setIsSaved] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [,setAlertOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [valueRPR, setValueRPR] = React.useState(generalInformations.responsableProgramme?.nom);
    const [valueRTE, setValueRTE] = React.useState(generalInformations.responsableTechnique?.nom);
    const [valueCMQ, setValueCMQ] = React.useState(generalInformations.chargeMissionQualite?.nom);
    const [valueCTR, setValueCTR] = React.useState(generalInformations.conducteurTravaux?.nom);
    const [loadingRPR, setLoadingRPR] = React.useState(false);
    const [loadingRTE, setLoadingRTE] = React.useState(false);
    const [loadingCMQ, setLoadingCMQ] = React.useState(false);
    const [loadingCTR, setLoadingCTR] = React.useState(false);
    const [originRPR] = React.useState(generalInformations.responsableProgramme?.nom);
    const [originRTE] = React.useState(generalInformations.responsableTechnique?.nom);
    const [originCMQ] = React.useState(generalInformations.chargeMissionQualite?.nom);
    const [originCTR] = React.useState(generalInformations.conducteurTravaux?.nom);
    const [disableBtnSave, setDisableBtnSave] = React.useState(false);
    const [originDate] = React.useState(generalInformations.dateLimite);
    const [valueDate, setValueDate] = React.useState(generalInformations.dateLimite);

    const classes = useStyles();

    const getAutocontrole = () => {
        return new Promise((res, reject) => {
            setLoading(true);
            API.getAutocontrole(generalInformations.trancheTravaux.id, generalInformations.typeAutocontrole)
                .then(function (response: any) {
                    fetchData(response.data)
                    res({ msg: "Réponse de /tranchetravaux/" + generalInformations.trancheTravaux.id + "/type/" + generalInformations.typeAutocontrole, data: response.data });
                    setLoading(false);
                }).catch(function (error: any) {
                    fetchData(null);
                    res({ msg: "Réponse de /tranchetravaux/" + generalInformations.trancheTravaux.id + "/type/" + generalInformations.typeAutocontrole, error });
                    setLoading(false);
                });
        })

    }

    /**
     * Appels API :
     */
    const getReferentiels = (refType: string) => {
        getAutocontrole().then((res) => {
            console.log("Get Autocontrole done !");
        }).catch(err => {
            console.log(err);
        });

        const codeFiliale = refType !== AutocontroleIntervenants.chargesMissionQualite ?
            refType === AutocontroleIntervenants.responsablesProgramme ? Number(generalInformations.programme.regroupementResp).toString() : Number(generalInformations.filiale.codeSociete).toString() : '';
        API.getReferentiels(refType, codeFiliale)
            .then(function (response: any) {
                let refList: FicheAutocontroleIntervenant[] = response.data.map((item: any) => {
                    let tabInfosIntervant: FicheAutocontroleIntervenant = {
                        nom: item.prenom.toUpperCase() + " " + item.nom.toUpperCase(),
                        mail: item.mail,
                        isInterne: (item.typeInterneExterne === 'I' || item.typeInterneExterne === ''),
                        id_gpi: item.id_gpi
                    }
                    return tabInfosIntervant;
                });

                refList = IntervenantUtils.removeDuplicate(refList);

                switch (refType) {
                    case AutocontroleIntervenants.chargesMissionQualite:
                        intervenantsState.chargesMissionQualite = refList;
                        setLoadingCMQ(false);
                        break;
                    case AutocontroleIntervenants.responsablesProgramme:
                        intervenantsState.responsablesProgramme = refList;
                        setLoadingRPR(false);
                        break;
                    case AutocontroleIntervenants.conducteursTravaux:
                        intervenantsState.conducteursTravaux = refList;
                        setLoadingCTR(false)
                        break;
                    case AutocontroleIntervenants.responsablesTechnique:
                        intervenantsState.responsablesTechnique = refList;
                        setLoadingRTE(false)
                        break;
                }
                updateParametresState(intervenantsState)
            }).catch(function (error: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType, error);
            });
    }

    const savePage = () => {
        data.tabInfos = GeneralInformationsTotabInfos(generalInformations, data.tabInfos);
        API.updateAutocontrole(data.tabInfos, data.percentCompletion, data.statut)
            .then(function (response: any) {
                console.log("Save autocontorle done !");
                updateTabInfos(data.tabInfos)
                setLoading(false);
                history.push(REDIRECT_HOME_ACQ);
            }).catch(function (error: any) {
                console.log("Erreur Save");
                setLoading(false);
                history.push(REDIRECT_HOME_ACQ);
            });
    }



    const createAutocontrole = () => {
        let params = generalInformationsToCreateAutocontroleParams(generalInformations);
        API.createAutocontrole(params)
            .then(() => {
                console.log("Autocontrole créé");
                history.push(REDIRECT_HOME_ACQ);
            }).catch(function (error: any) {
                console.log("Problème lors de la création de l'autocontrole", error)
            })
    }

    /**
     * useEffect :
     */

    const callReferentielsAPI = () => {
        if (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase() && !intervenantsState.chargesMissionQualite && !loadingCMQ) {
            setLoadingCMQ(true);
            getReferentiels(AutocontroleIntervenants.chargesMissionQualite);
        }

        if (!intervenantsState.responsablesProgramme && !loadingRPR) {
            setLoadingRPR(true);
            getReferentiels(AutocontroleIntervenants.responsablesProgramme);
        }

        if (!intervenantsState.conducteursTravaux && !loadingCTR) {
            setLoadingCTR(true);
            getReferentiels(AutocontroleIntervenants.conducteursTravaux);
        }

        if (!intervenantsState.responsablesTechnique && !loadingRTE) {
            setLoadingRTE(true);
            getReferentiels(AutocontroleIntervenants.responsablesTechnique);
        }
    }




    React.useEffect(() => {
        if (!loading) {
            callReferentielsAPI();
            disableSave();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueRPR, valueRTE, valueCMQ, valueCTR, valueDate]);

    const handlePopEvent = (e: any) => {
        localStorage.setItem('popListenerAdded', "true");
        e.preventDefault();
        window.history.pushState(null, document.title, window.location.href);

        if (localStorage.getItem('showPopIn') === 'true') {
            setOpen(true);
        } else {
            history.push(REDIRECT_HOME_ACQ);
        }

    }


    const handleBackNavigatorEvent = () => {
        if (!(localStorage.getItem('popListenerAdded') === 'true')) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handlePopEvent);
        }

    }

    React.useEffect(() => {
        getAutocontrole().then((res) => {
            localStorage.setItem('popListenerAdded', "false");
            localStorage.setItem('showPopIn', "false");
            handleBackNavigatorEvent();
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * Criteres :
     */
    const saveReferentiel = (critere: FicheAutoControleCritere) => {
        // goBackStateHasChanged();
        switch (critere.options) {
            case AutocontroleIntervenants.chargesMissionQualite:
                setValueCMQ(critere.value as string)
                break;
            case AutocontroleIntervenants.responsablesProgramme:
                setValueRPR(critere.value as string)
                break;
            case AutocontroleIntervenants.conducteursTravaux:
                setValueCTR(critere.value as string)
                break;
            case AutocontroleIntervenants.responsablesTechnique:
                setValueRTE(critere.value as string)
                break;
        }
        console.log("saveReferentiel !");

        localStorage.setItem('showPopIn', 'true');
        setIsSaved(false)
    }

    const sortIntervenants = (referentiels: string[]) => {
        return referentiels ? referentiels.sort((a: string, b: string) => a.localeCompare(b)) : [] as string[]
    }

    const getSelectCritere = (refType: string) => {
        let critere = getEmptySelectCritere();
        critere.options = refType;

        switch (refType) {
            case AutocontroleIntervenants.responsablesProgramme: {
                critere.value = valueRPR;
                if (!!intervenantsState.responsablesProgramme)
                    critere.choices = sortIntervenants((intervenantsState.responsablesProgramme as FicheAutocontroleIntervenant[]).map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Responsable programme'
                critere.activated = !disableRPAndCT()
                break;
            }
            case AutocontroleIntervenants.conducteursTravaux: {
                critere.value = valueCTR;
                if (!!intervenantsState.conducteursTravaux)
                    critere.choices = sortIntervenants((intervenantsState.conducteursTravaux as FicheAutocontroleIntervenant[]).map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = intervenantsState.conducteursTravaux !== null ? intervenantsState.conducteursTravaux : undefined
                critere.title = 'Conducteur de travaux'
                critere.activated = !disableRPAndCT()
                break;
            }
            case AutocontroleIntervenants.chargesMissionQualite: {
                critere.value = valueCMQ;
                if (!!intervenantsState.chargesMissionQualite)
                    critere.choices = sortIntervenants((intervenantsState.chargesMissionQualite as FicheAutocontroleIntervenant[]).map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Chargé de mission qualité'
                break;
            }
            case AutocontroleIntervenants.responsablesTechnique: {
                critere.value = valueRTE;
                if (!!intervenantsState.responsablesTechnique)
                    critere.choices = sortIntervenants((intervenantsState.responsablesTechnique as FicheAutocontroleIntervenant[]).map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = intervenantsState.responsablesTechnique !== null ? intervenantsState.responsablesTechnique : undefined
                critere.title = 'Responsable technique'
                break;
            }
        }

        return critere;
    }

    const updateDate = (critere: FicheAutoControleCritere) => {
        if (DateUtils.isBeforeNow(critere.value as number)) {
            setAlertOpen(true)
            setValueDate(generalInformations.dateLimite)
        } else {
            setValueDate(critere.value as number)
            localStorage.setItem('showPopIn', "true");
            setIsSaved(false)
        }
    }

    const getDateCritere = () => {
        let critereDate = getEmptyDateCritere();
        critereDate.title = 'Date de limite de saisie';
        critereDate.value = valueDate;

        return critereDate
    }

    /**
     * Metier :
     */

    const quitParameters = () => {
        history.push(REDIRECT_HOME_ACQ);
        initGoBackState();
    }

    const saveParameters = () => {
        if (!isSaved) {
            setLoading(true);

            let values = {
                valueCMQ: valueCMQ,
                valueRPR: valueRPR,
                valueRTE: valueRTE,
                valueCTR: valueCTR,
                valueDate: valueDate
            }
            generalInformations = parametresAutocontroleStateToGeneralInformations(generalInformations, intervenantsState, values)
            setGeneralInformations(generalInformations)

            if (!!data) {
                savePage();
            } else {
                createAutocontrole();
            }

        } else {
            quitParameters();
        }
        initGoBackState();
    }

    const backToMenu = () => {
        if (isSaved) {
            quitParameters();
        } else {
            setOpen(true);
        }
    }

    const disableSave = () => {

        const rpr = !!originRPR ? valueRPR !== originRPR && valueRPR !== undefined : !!valueRPR;
        const rte = !!originRTE ? valueRTE !== originRTE && valueRTE !== undefined : !!valueRTE;
        const cmq = !!originCMQ ? valueCMQ !== originCMQ && valueCMQ !== undefined : !!valueCMQ;
        const ctr = !!originCTR ? valueCTR !== originCTR && valueCTR !== undefined : !!valueCTR;
        const date = !!originDate ? valueDate !== originDate && valueDate !== undefined : !!valueDate;


        setDisableBtnSave(!(rpr || rte || cmq || ctr || date));
    }

    const disableRPAndCT = () => {
        return !!data && !!data.statut && data.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE;
    }


    /**
     * Display :
     */
    return (<div className='card-content'>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <div className='parametres-title-card'>
            <span className='parametre-title-arrow'>
                <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToMenu()} />
            </span>
            <span className='parametres-title-text'>
                Paramètres de l'autocontrôle
            </span>
        </div>

        <div className='parametre-recapitulatif-card'>
            <div className='parametre-recapitulatif-value'> Récapitulatif</div>
            <div className='parametre-recapitulatif-title'> Nom de la filiale : </div>
            <div className='parametre-recapitulatif-value'> {ReferentielUtils.getFilialeLibelle(generalInformations.filiale)} </div>
            <div className='parametre-recapitulatif-title'> Nom interne du programme :</div>
            <div className='parametre-recapitulatif-value'> {ReferentielUtils.getProgrammeLibelle(generalInformations.programme)} </div>
            <div className='parametre-recapitulatif-title'> Tranche travaux</div>
            <div className='parametre-recapitulatif-value'> {ReferentielUtils.getTrancheTravauxLibelle(generalInformations.trancheTravaux)} </div>
            <div className='parametre-recapitulatif-title'> Type d'autocontrôle :</div>
            <div className='parametre-recapitulatif-value'> {generalInformations.typeAutocontrole}</div>
        </div>

        <div className='parametre-criteres-card'>
            <div className='parametre-criteres-container'>
                <div className="tabInfos-criterias-helper">
                    <div className="tabInfos-criterias-helper-icon">
                        <InfoOutlinedIcon />
                    </div>
                    <div className="tabInfos-criterias-helper-text">
                        <div className="tabInfos-criterias-helper-title">
                            Informations requises
                        </div>
                        <div className="tabInfos-criterias-helper-infos">
                            Merci de renseigner l'ensemble des informations ci-dessous
                        </div>
                    </div>
                </div>

                <div>
                    {getUserRole().toLowerCase() === userRoles.cmq.toLowerCase() ?
                        <DateAutocontrole critere={getDateCritere()} updateCritere={updateDate} disablePast={true} />
                        : ''
                    }

                    {
                        getUserRole().toLowerCase() === userRoles.cmq.toLowerCase() ?
                            MessageDeadline(generalInformations.ficheStatut, DateUtils.dateTransform(getDateCritere().value))
                            : ''
                    }

                    <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentiel} />
                    <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentiel} />
                    <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.responsablesTechnique)} updateCritere={saveReferentiel} />
                    {getUserRole().toLowerCase() === userRoles.cmq.toLowerCase() ?
                        <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.chargesMissionQualite)} updateCritere={saveReferentiel} />
                        : ''
                    }

                </div>
            </div>
        </div>
        <div className="parametre-button">
            <Button disabled={disableBtnSave} className={disableBtnSave ? "btn-radio btn-go-autocontrole desactivate" : "btn-radio btn-go-autocontrole active"} key="btn-autocontrole" variant="outlined" onClick={() => saveParameters()}> Enregistrer les parametres </Button>
        </div>

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <span>Êtes vous sûr de vouloir abandonner votre saisie ? <br /> Les données saisies seront perdues.</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => quitParameters()} color="primary">
                    OUI
                </Button>
                <Button onClick={() => setOpen(false)} color="primary">
                    NON
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
}

const mapStateToProps = ({ ficheAutocontrole, user, generalInformations, intervenantsState }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    user: user,
    generalInformations: generalInformations,
    intervenantsState: intervenantsState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
        updateTabInfos: (data: FicheAutocontroleTabInfos) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB_INFOS, payload: data }),
        setGeneralInformations: (params: GeneralInformationsState) => dispatch({ type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params }),
        updateParametresState: (params: IntervenantsState) => dispatch({ type: IntervenantsAutocontroleTypes.UPDATE_STATE, payload: params }),
        resetParametresState: () => dispatch({ type: IntervenantsAutocontroleTypes.RESET_STATE }),
        initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ParametresAutocontrole);
