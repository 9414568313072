import { HelperTypeEnum } from "../../components/Helper/HelperTypeEnum";
import { TrancheTravauxLiee } from "./generalInformations.Types";
import { TabSynthese } from "./TabSynthese.Types";

export interface FicheAutocontroleState {
    data: FicheAutocontrole,
    isSaved: boolean
}

export interface FicheAutocontrole {
    id_pk?: string,
    id_sk?: string,
    id: string,
    idAutocontrole?: string,
    title: string,
    index: number,
    active?: boolean,
    activated?: boolean,
    percentCompletion: number,
    type: string,
    createdAt?: number,
    updatedAt?: number,
    idUserCreated?: string,
    idUserUpdated?: string,
    tabInfos: FicheAutocontroleTabInfos,
    tabs: FicheAutocontroleTab[],
    tabSynthese: TabSynthese,
    statut?: string,
    statutHisto?: HistoStatut[],
    tranchesTravauxLiees?: TrancheTravauxLiee[]
    trancheTravauxPere?: TrancheTravauxLiee
}

export interface HistoStatut {
    libelle: string,
    user: UserHistoStatut,
    dateMAJ: number
}

export interface UserHistoStatut {
    name: string;
    mail: string;
}

export interface ParamsSynthese {
    id_pk: string,
    id_sk?: string,
    id?: string,
    infosGeneralesSynthese?: ParamsSyntheseInfosGenerales,
    photos?: FicheAutoControleCritere[],
    commentaires?: FicheAutoControleCritere[],
    commentaireCmq?: FicheAutoControleCritere,
    validationDirectionQualite?: FicheAutoControleCritere[]
}

export interface ParamsSyntheseInfosGenerales {
    id_pk: string,
    id_sk?: string,
    responsableProgramme: FicheAutocontroleIntervenant,
    responsableTechnique: FicheAutocontroleIntervenant,
    CMQ: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    directeurProgrammes: FicheAutocontroleIntervenant,
    directeurOperationnel: FicheAutocontroleIntervenant,
    dateLimite?: number,
    dateLivraisionGPI: number,
    dateValidation: number
}

export interface ParamsSyntheseState {
    data: ParamsSynthese,
    isSaved: boolean
}

export interface FicheAutocontroleTabInfos {
    id: string,
    iconeTab: string,
    title: string,
    index: number,
    active: boolean,
    activated: boolean,
    completion: number,
    completionLM4?: number,
    responsableProgramme: FicheAutocontroleIntervenant,
    responsableTechnique: FicheAutocontroleIntervenant,
    CMQ: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    directeurProgrammes: FicheAutocontroleIntervenant,
    directeurOperationnel: FicheAutocontroleIntervenant,
    filiale: string,
    programme: string,
    programmeCommercial: string,
    trancheTravaux: string,
    nbLots: number,
    dateLimite: number,
    idUserCreated: string,
    idUserUpdated: string,
    createdAt: number,
    updatedAt: number,
    dateLivraisionGPI: number,
    dateValidation: number,
    groups: FicheAutoControleGroup[],
}

export interface FicheAutocontroleIntervenant {
    nom: string,
    mail: string,
    isInterne: boolean,
    id_gpi: string,
}

export interface FicheAutocontroleTab {
    id_pk: string,
    id_sk: string,
    id: string,
    iconeTab: string,
    idAutocontrole: string,
    title: string,
    index: number,
    active: boolean,
    activated: boolean,
    completion: number,
    type?: string,
    createdAt?: number,
    updatedAt?: number,
    idUserCreated?: string,
    idUserUpdated?: string,
    groups: FicheAutoControleGroup[],
}

export interface FicheAutoControleGroup {
    value: string | undefined,
    type: string | undefined,
    id?: string,
    idTab?: string,
    idAutocontrole?: string,
    title: string,
    name?: string,
    index: number,
    completion: number,
    active: boolean,
    activated: boolean,
    statut?: string,
    createdAt?: number,
    updatedAt?: number,
    required: boolean,
    criterias: FicheAutoControleCritere[],
}

export interface FicheAutoControleCritere {
    attachedPageIndex: number | undefined,
    displayWhenValue: string | undefined,
    attachedElementIndex: number | undefined,
    id?: string,
    idGroup?: string,
    idTab?: string,
    idAutocontrole?: string,
    title: string,
    index: number,
    active: boolean,
    activated: boolean,
    typeCriteria: string,
    value: number | number[] | string | string[] | null,
    minValue?: number,
    maxValue?: number,
    step?: number,
    options?: string,
    choices?: number[] | string[],
    intervenants?: FicheAutocontroleIntervenant[],
    weightingActor: boolean,
    weighting: number,
    text?: string,
    dateMin?: string,
    dateMax?: string,
    photoName?: string,
    photoId?: string,
    photoType?: string,
    createdAt?: number,
    updatedAt?: number,
    isConditionnal?: boolean,
    isCritereInformatif?: boolean,
    criteresConditionnes?: FicheAutoControleCritere[][],
    tooltip?: TooltipCritere
}

export interface TooltipCritere {
    type?: HelperTypeEnum | string,
    title: string,
    description?: string
}

export enum FicheAutocontroleActionTypes {
    FETCH_DATA = "@@ficheAutocontrole/FETCH_DATA",
    UPDATE_TAB = "@@ficheAutocontrole/UPDATE_TAB",
    UPDATE_TAB_INFOS = "@@ficheAutocontrole/UPDATE_TAB_INFOS",
    SET_ISSAVED = "@@ficheAutocontrole/SET_ISSAVED",
    UPDATE_STATUT = "@@ficheAutocontrole/UPDATESTATUT"
}