import React from 'react';
import { FicheAutoControleCritere } from '../../../store/types/FicheAutocontrole.Types';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import Helper from '../../Helper/Helper'
import { HelperTypeEnum } from '../../Helper/HelperTypeEnum'
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core';


interface PropsFromState {
    critere: FicheAutoControleCritere,
    updateCritere: Function
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const PeriodAutocontrole: React.FC<AllProps> = ({ critere, updateCritere, goBackStateHasChanged }) => {

    const rootTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#7d1e4f"
            }

        },
    });

    const [isPeriodValide, setIsPeriodValide] = React.useState(true);

    const getDateForInput = (indexValue: number) => {
        let value = critere.value as string[]
        return value[indexValue] ? new Date(value[indexValue]) : null
    };

    const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(getDateForInput(0));
    const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(getDateForInput(1));

    const handleDateStartChange = (date: Date | null) => {
        setSelectedDateStart(date);
        let dateDebut = !!date ? new Date(date).getTime() : 0;
        let dateFin = !!selectedDateEnd ? selectedDateEnd.getTime() : 0
        let dates: number[] = [dateDebut, dateFin];
        if (isPeriodValid(dateDebut.toString(), dateFin.toString())) {
            setIsPeriodValide(true);
            critere.value = dates;
            updateCritere(critere);
            goBackStateHasChanged();
        } else {
            setIsPeriodValide(false);
        }
    };
    const handleDateEndChange = (date: Date | null) => {
        setSelectedDateEnd(date);
        let dateFin = !!date ? new Date(date).getTime() : 0;
        let dateDebut = !!selectedDateStart ? selectedDateStart.getTime() : 0
        let dates: number[] = [dateDebut, dateFin];
        if (isPeriodValid(dateDebut.toString(), dateFin.toString())) {
            setIsPeriodValide(true);
            critere.value = dates;
            updateCritere(critere);
            goBackStateHasChanged();
        } else {
            setIsPeriodValide(false);
        }
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        goBackStateHasChanged();
        critere.activated = event.target.checked;
        updateCritere(critere);
    };

    const isPeriodValid = (dateDebut: string, dateFin: string) => {
        if (!dateDebut || !dateFin) {
            let value = critere.value as string[]
            return (!value[0] && !value[1])
        }
        return (dateDebut <= dateFin)
    }

    return (<div>
        <div>
            <LabelCritereAutocontroleStyled>{critere.title}
                {critere.active ?
                    <SwitchAutocontroleStyled className="elt-float-right" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt="" />} />
                    : ""
                }
            </LabelCritereAutocontroleStyled>
        </div>

        <div className="critera-period">
            <div className={"critera-date-period-box " + (!critere.activated ? "critera-date-period-box-disabled" : "")}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <ThemeProvider theme={rootTheme}>
                        <KeyboardDatePicker
                            autoOk
                            disableToolbar={false}
                            showTodayButton={true}
                            invalidDateMessage=''
                            allowKeyboardControl
                            className='widthInherit'
                            helperText=''
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder='jj/mm/aaaa'
                            margin="none"
                            id="dateDebut"
                            disabled={!critere.activated}
                            value={selectedDateStart}
                            onChange={handleDateStartChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </div>
            <div className="critera-period-middle">à</div>
            <div className={"critera-date-period-box " + (!critere.activated ? "critera-date-period-box-disabled" : "")}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <ThemeProvider theme={rootTheme}>
                        <KeyboardDatePicker
                            autoOk
                            disableToolbar={false}
                            showTodayButton={true}
                            invalidDateMessage=''
                            allowKeyboardControl
                            className='widthInherit'
                            helperText=''
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder='jj/mm/aaaa'
                            margin="none"
                            id="dateFin"
                            disabled={!critere.activated}
                            value={selectedDateEnd}
                            onChange={handleDateEndChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </div>
        </div>
        {!isPeriodValide ?
            <Helper type={HelperTypeEnum.WARNING} title="La période de dates n'est pas valide." />
            : ''}
    </div>);
};


const mapStateToProps = ({ goBackState }: ApplicationState) => ({
    goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodAutocontrole);