import React, { useEffect } from 'react';
import './../SyntheseTabs/SyntheseTab.scss';
import './SyntheseInfosGenerales.scss';
import { connect } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { InfosCriteraTabSynthese, TabSynthese } from '../../../store/types/TabSynthese.Types';
import { ApplicationState } from '../../../store/store/store';
import SyntheseInfoTerrain from './SyntheseInfoTerrain/SyntheseInfoTerrain'
import SyntheseInfoCmq from './SyntheseInfoCmq/SyntheseInfoCmq';
import DateUtils from "../../../utils/DateUtils"
import { TYPE_AUTOCONTROLE_L15} from '../../../utils/constantes/contantes'
import { getUserRole } from '../../../utils/AccessToken'
import { userRoles } from '../../../store/types/user.Types'
import { CurrentStateActions } from '../../../store/types/CurrentState.Types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GeneralInformationsState, TrancheTravauxLiee } from '../../../store/types/generalInformations.Types';
import { CardMedia } from '@material-ui/core';
import { API } from '../../../utils/callAPI';
import PicProg from '../../../assets/image_programme.png';


interface PropsFromDispatch {
    tabSynthese: TabSynthese,
    updateCurrentPage:Function,
    updateCurrentGroup:Function,
    updateIsTabInfos:Function,
    generalInformations: GeneralInformationsState
}

const buildInfo = (title: string, value: string | number) => {
    return <div key={title} className="synthese-info">
        <div className="info-title">{title}</div>         
        <div className={(value && value !== "" && typeof(value) !== typeof(true)) ? "info-value" : "info-value-to-complete"}>
            { (value && value !== "" && typeof(value) !== typeof(true)) ? value : "A compléter"}
        </div>
    </div>
}

const buildInfoDate = (title: string, value: string | number, numberDate: number) => {
    return <div className="synthese-info">
        <div className="info-title">{title}</div>
        <div className={(value && value !== "" && typeof(value) !== typeof(true)) ? "info-value" : "info-value-to-complete"}>
            <span className={DateUtils.getDateLimiteColor(numberDate)}>{ (value && value !== "" && typeof(value) !== typeof(true)) ? value : "A compléter"}</span>
        </div>     
    </div>
}

const buildInfoCheckBox = (title: string, value: number[] | string[]) => {
    return <div key={title} className="synthese-info">
        <div className="info-title">{title}</div>
        <div className={(Array.isArray(value) && value.length) ? "info-value" : "info-value-to-complete"}>{ (Array.isArray(value) && value.length) ? value.join(", ") : "A compléter"}</div>
    </div>
}

const buildInfosCriterias = (infosGenerales: InfosCriteraTabSynthese[]) => {
    return infosGenerales.map(info => {
        return Array.isArray(info.value) ? buildInfoCheckBox(info.title, info.value) : buildInfo(info.title, info.value);
    })
}

const buildSpecificTrancheTravaux = (tranche: string, nbLots: number, tranchesLiees: TrancheTravauxLiee[]) => {
    return <div className="synthese-info">
                <div className="info-title">Tranche travaux : </div>
                <div className="info-value">{tranche} - {nbLots} Lots</div>
                { tranchesLiees && tranchesLiees.length > 0 ? 
                    (tranchesLiees as TrancheTravauxLiee[])
                        .sort((a, b) => a.codeTranche.localeCompare(b.codeTranche))
                        .map(tt => {
                        return(<div className="info-value">{tt.codeTranche} - { tt.libelleTranche} - { tt.nbLots } Lots</div>)
                    }) : '' }
            </div>
}

const isDateValide = (date: number) => {
    return (date && date !== 0) 
}

const SyntheseTab : React.FC<PropsFromDispatch> = ({tabSynthese, updateCurrentPage, updateCurrentGroup, updateIsTabInfos, generalInformations}) =>{


    const [progMiniature, setProgMiniature] = React.useState<{thumbnailUrl:string} | null>(null);


    useEffect(() => {
        if(generalInformations.programme && generalInformations.programme.codeProgramme) {
            API.getProgrammePicture(generalInformations.programme.codeProgramme).then((resp)=> {
                setProgMiniature(resp?.data?.response);
        }).catch(err => console.log(err));
        }
       
    }, [generalInformations]);
   
    const goToTabInfos = () => {
        window.scroll(0,0)
        updateCurrentPage(0);
        updateCurrentGroup(-1);
        updateIsTabInfos(true)
    
    }

    return (
        <div className="synthese-tab">
            <div className={(getUserRole().toLowerCase() !== userRoles.cmq.toLowerCase() || getUserRole().toLowerCase() !== userRoles.terrain.toLowerCase()) ? "synthese-tab-header cursor-pointer" : "synthese-tab-header"} onClick={() => goToTabInfos()}>
                <div className="synthese-title-completion">
                    <div className="synthese-tab-title">
                        <span>Récapitulatif</span>
                    </div>
                </div>
                <div className="synthese-right-arrow"><ArrowForwardIosIcon/></div>
            </div>
            <div className="corps-synthese">
                <div className="left-side">
                    {buildInfo("Nom de la filiale :", tabSynthese?.infosGenerales?.filiale)}
                    {buildInfo("Nom interne du programme :", tabSynthese?.infosGenerales?.programme)}
                    {buildInfo("Nom commercial du programme :", tabSynthese?.infosGenerales?.programmeCommercial)}
                    <div className="bloc-field">
                        <CardMedia
                            className="critera-picture-img-miniature"
                            component="img"
                            image={!!progMiniature ? progMiniature.thumbnailUrl : PicProg}
                            />
                    </div>
                    {buildSpecificTrancheTravaux(tabSynthese?.infosGenerales?.trancheTravaux, tabSynthese?.infosGenerales?.nbLots,tabSynthese.tranchesTravauxLiees)}
                    { tabSynthese?.infosGenerales?.typeAutocontrole === TYPE_AUTOCONTROLE_L15 && 
                        (tabSynthese?.infosGenerales?.completionLM4 && tabSynthese.infosGenerales.completionLM4 !== -1) ?
                            buildInfo("Pourcentage d'avancement L-4 :", tabSynthese.infosGenerales.completionLM4+ ' %') : ''
                    }
                    { getUserRole().toLowerCase() === userRoles.terrain.toLowerCase() ?
                    <SyntheseInfoTerrain  /> : <SyntheseInfoCmq /> }
                    { tabSynthese?.infosGenerales?.typeAutocontrole === TYPE_AUTOCONTROLE_L15 ?
                    <div>
                    {isDateValide(tabSynthese?.infosGenerales?.dateReunionInfos) ?
                        buildInfo("Date de la réunion d'information :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.dateReunionInfos)) : ''
                    }
                    {isDateValide(tabSynthese?.infosGenerales?.datePremAG) ?
                        buildInfo("Date de la 1ère AG :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.datePremAG)) : ''
                    }
                    {isDateValide(tabSynthese?.infosGenerales?.dateLivraisonPartieCommunes) ?
                        buildInfo("Date de livraison des parties communes :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.dateLivraisonPartieCommunes)) : ''
                    }
                    {isDateValide(tabSynthese?.infosGenerales?.PeriodeLivraison[0]) && isDateValide(tabSynthese?.infosGenerales?.PeriodeLivraison[1]) ?
                        buildInfo("Période de livraison :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.PeriodeLivraison[0]) + ' à ' + DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.PeriodeLivraison[1])) : ''
                    }
                    </div> : ''}
                    {isDateValide(tabSynthese?.infosGenerales?.dateLivraisionGPI) ?
                        buildInfoDate("Date de livraison GPI :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.dateLivraisionGPI), tabSynthese?.infosGenerales?.dateLivraisionGPI) : '' 
                    }
                </div>
                <div className="right-side">
                    {buildInfosCriterias(tabSynthese?.infosGenerales?.infosCriteras)}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({ ficheAutocontrole, tabSyntheseState, generalInformations }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    tabSynthese: tabSyntheseState.data,
    generalInformations: generalInformations
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
      updateCurrentPage: (params: number) => dispatch({type: CurrentStateActions.CHANGE_PAGE, params: params}),
      updateCurrentGroup: (params: number) => dispatch({type: CurrentStateActions.CHANGE_GROUP, params: params}),
      updateIsTabInfos: (params: boolean) => dispatch({type: CurrentStateActions.IS_TABINFOS, params: params})
    }
  };

export default connect(mapStateToProps, mapDispatchToProps)(SyntheseTab);