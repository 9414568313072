import React from 'react';
import { FicheAutocontroleTab, FicheAutoControleGroup, FicheAutocontroleActionTypes, FicheAutocontrole } from '../../store/types/FicheAutocontrole.Types';
import GroupAutocontrole from '../GroupAutocontrole/GroupAutocontrole';
import CustomGroupMenu from '../CustomMenu/CustomGroupMenu';
import { ApplicationState } from '../../store/store/store';
import { connect } from 'react-redux';
import { Backdrop, Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Slide, Theme } from '@material-ui/core';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../../utils/callAPI';
import StateService from '../../utils/state.service';
import { uploadToS3 } from '../../utils/upload.image.s3.service';
import { OldPictureTypes, PictureState, PictureTypes } from '../../store/types/pictures.Types';
import { CurrentState } from '../../store/types/CurrentState.Types';
import ProgressBarService from '../../utils/progressBar.service';
import { TransitionProps } from "@material-ui/core/transitions";
import { GoBackCurrentStateActions } from '../../store/types/GoBackState.Types';
import { ReInitPicCompActions } from '../../store/types/ReInitPicComp.Types';
import { TabSynthese, TabSyntheseStateActions } from '../../store/types/TabSynthese.Types';
import { GeneralInformationsState, GeneralInformationsTypes } from '../../store/types/generalInformations.Types';
import { tabInfosToGeneralInformations } from '../../utils/mappers/generalInformationsMapper';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsFromState {
  tab: FicheAutocontroleTab;
}

interface PropsFromDispatch {
  data: FicheAutocontrole,
  isSaved: boolean,
  setIsSaved: Function,
  updateTab: Function,
  pictures: PictureState[],
  currentState: CurrentState,
  deletePicsFromStore: Function,
  initGoBackState: Function,
  oldPictures: Array<string>,
  deleteOldPicture: Function,
  reInitPicComp: Function,
  fetchData: Function,
  updateSynthese: Function,
  setGeneralInformationsState: Function,
  generalInformations: GeneralInformationsState,
}

type AllProps = PropsFromDispatch & PropsFromState

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const TabAutocontrole: React.FC<AllProps> = ({ data, tab, isSaved, setIsSaved, updateTab, pictures, currentState, deletePicsFromStore, initGoBackState, oldPictures, deleteOldPicture, reInitPicComp, fetchData, updateSynthese, setGeneralInformationsState, generalInformations }) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setDialog] = React.useState(false);

  const isPageDesactivatedWithCriterias = () => {
    let isCriteriasFoundInAGroup = false
    tab.groups.forEach(group => {
      if (isGroupWithCriteriasFilled(group)) {
        isCriteriasFoundInAGroup = true
      }
    });
    return isCriteriasFoundInAGroup && !tab.activated;
  }

  const isGroupWithCriteriasFilled = (group: FicheAutoControleGroup) => {
    return group.criterias.some(crit => ProgressBarService.isCriteraCompleted(crit));
  }

  const updatePage = () => {
    if (isPageDesactivatedWithCriterias()) {
      setDialog(true)
    } else {
      savePage();
    }
  }

  const confirmUpdatePage = () => {
    savePage();
    setDialog(false)
  }

  const getAutocontrole = () => {
    API.getAutocontrole(generalInformations.trancheTravaux.id, generalInformations.typeAutocontrole)
    .then(function(response: any) {
        const ficheAutocontrole:FicheAutocontrole = response.data
        fetchData(ficheAutocontrole);
        updateSynthese(ficheAutocontrole.tabSynthese);
        setGeneralInformationsState(tabInfosToGeneralInformations(generalInformations, ficheAutocontrole.tabInfos));
    }).catch(function(error: any) {
        fetchData(null);
    });
}

  const savePage = () => {
    setOpen(true);
    tab = StateService.resetDisabledCriteriasState(tab) as FicheAutocontroleTab;
    updateTab(tab)
    API.updateAutocontrole(tab, ProgressBarService.getComplitionPercent(data), data.statut)
      .then(() => {
        if (pictures.length > 0) {
          const promises: any[]  =  [] ;
          pictures.forEach(img => {
            promises.push(uploadToS3(img.name, img.type, img.pic));
          });

          Promise.all(promises).then(
            () => {

              getAutocontrole();
              
              console.log("Images sauvegardées !");
              setIsSaved(true);
              setOpen(false);
              deletePicsFromStore();
              reInitPicComp();
            }, err => {
              console.error(err);

            });

          if (oldPictures.length > 0) {
            oldPictures.forEach(oldPic => {
              API.deletePicFromS3(oldPic).then(() => {
                console.log("Image supprimée : " + oldPic);
                deleteOldPicture(oldPic);
              }, err => {
                console.error(err);
              });
            });
          }
        } else  {
          getAutocontrole();
        }
        initGoBackState();
        console.log("Autocontrole sauvegardé");
        setIsSaved(true);
        setOpen(false);
      }).catch(() => {
        console.log("Problème lors de la sauvegarde");
        setIsSaved(true)
        setOpen(false);
      });
  }

  const createGroups = () => {    
    return tab.groups.map((group: FicheAutoControleGroup) =>
    {
      return <div>   
        <GroupAutocontrole key={group.id} group={group} displayMessage ={!["g_photospp", "g_photospc","g_reseau","g_photosvrd","g_concess","g_sp","g_autres","g_prepremcle","g_naturellement","g_coms","c_photospp"].includes(group.id ? group.id: "")}/>
        <div className="tab-save-button-container">
          <Button className={"tab-save-button-computer tab-save-button"} onClick={updatePage} disabled={isSaved}>Enregistrer</Button>
        </div>
      </div>
    }
    );
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span>Êtes vous sûr de vouloir désactiver la page ? Les informations saisies sur la page ne seront pas conservées.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmUpdatePage()} color="primary">
            OUI
          </Button>
          <Button onClick={() => setDialog(false)} color="primary">
            NON
          </Button>
        </DialogActions>
      </Dialog>
      <CustomGroupMenu headers={tab.groups} tabs={createGroups()} />
      {currentState.currentGroup > -1 ? <Button className={"tab-save-button-mobile tab-save-button"} onClick={updatePage} disabled={isSaved}>Enregistrer</Button> : ""}
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

};

const mapStateToProps = ({ ficheAutocontrole, pictures, currentState, oldPictures, generalInformations }: ApplicationState) => ({
  data: ficheAutocontrole.data,
  isSaved: ficheAutocontrole.isSaved,
  pictures: pictures,
  currentState: currentState,
  oldPictures: oldPictures,
  generalInformations: generalInformations
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setIsSaved: (params: boolean) => dispatch({ type: FicheAutocontroleActionTypes.SET_ISSAVED, payload: params }),
    updateTab: (data: FicheAutocontroleTab, currentPage: number) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: currentPage }),
    deletePicsFromStore: () => dispatch({ type: PictureTypes.DELETE_ALL_PIC }),
    initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE }),
    deleteOldPicture: (name: string) => dispatch({ type: OldPictureTypes.DELETE_OLD_PIC, picName: name }),
    reInitPicComp:  ()=> dispatch({ type: ReInitPicCompActions.RE_INIT }),
    fetchData: (data:FicheAutocontrole) => dispatch({type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data}),
    updateSynthese: (data:TabSynthese) => dispatch({type: TabSyntheseStateActions.UPDATE_STATE, payload: data}),
    setGeneralInformationsState: (params:GeneralInformationsState) => dispatch({type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabAutocontrole);