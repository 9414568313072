import { FicheAutocontrole, FicheAutoControleCritere, FicheAutoControleGroup, FicheAutocontroleTab } from "../store/types/FicheAutocontrole.Types";



export default class ProgressBarService {

    //Return true if the critera is completed
    static isCriteraCompleted (critere: FicheAutoControleCritere) {
        if((critere.value !== null && critere.value !== undefined)){
            switch(critere.typeCriteria){
                case 'IT':
                    return (critere.value !== '');
                case 'RB':
                    return (critere.value !== '');
                case 'SE':
                    return (critere.value !== '');
                case 'SL':
                    return (critere.value !== -1);
                case 'CB': 
                    return ((critere.value as string[]).length > 0);
                case 'PI': 
                    return (critere.photoId !== null && critere.photoId !== undefined && critere.photoId !== ''
                     &&  critere.photoName !== null && critere.photoName !== undefined && critere.photoName !== '' );
                case 'DA':
                    return (critere.value !== 0);
                case 'PE':
                    return ((critere.value as number[]).length > 1 && (!!(critere.value as number[])[0] && !!(critere.value as number[])[1]));
                default:
                    return false;
            }
        }else{
            return false
        }
    }

    static getCriteriasFromConditionCriteria (critere: FicheAutoControleCritere) {
        if(critere?.criteresConditionnes){
            let indexChoiceForValueSelected = (critere.choices as string[]).findIndex(choice => choice === critere.value)
            if(indexChoiceForValueSelected !== -1 && critere.criteresConditionnes[indexChoiceForValueSelected]){
                return critere.criteresConditionnes[indexChoiceForValueSelected] as FicheAutoControleCritere[]
            }
        }
        return []
    }

    //Return the amount of criteria in a FicheAutocontrole and the amount of completed critera in a FicheAutocontroleGroup
    static getComplitionNumbersInGroup = (group: FicheAutoControleGroup, data: FicheAutocontrole) => {
        let nbOfCriteriasTotal =0, nbOfCriteriasCompleted = 0;
        (group.criterias as FicheAutoControleCritere[]).forEach( critere => {
            if(critere.activated){ 
                if(critere.attachedPageIndex === undefined || (critere.attachedPageIndex !== undefined && critere.attachedPageIndex > -1 
                    && data.tabs[critere.attachedPageIndex].activated)){
                    if(!critere.isCritereInformatif){
                    nbOfCriteriasTotal++;
                        if(ProgressBarService.isCriteraCompleted(critere)){
                            nbOfCriteriasCompleted ++;
                        }
                    }
                    if(!!critere.criteresConditionnes){
                        ProgressBarService.getCriteriasFromConditionCriteria(critere).forEach(critCond => {
                            if(!critCond.isCritereInformatif && critCond.activated){
                                nbOfCriteriasTotal++;
                                if(ProgressBarService.isCriteraCompleted(critCond)){
                                    nbOfCriteriasCompleted ++;
                                }
                            } 
                        });
                    }
                }
            }
        })

        return {nbOfCriteriasTotal, nbOfCriteriasCompleted} ;
    }

    //Return the amount of criteria in a FicheAutocontrole and the amount of completed critera in a FicheAutocontroleTab
    static getComplitionNumbersInPage = (page: FicheAutocontroleTab, data: FicheAutocontrole) => {
        let nbOfCriteriasTotal =0, nbOfCriteriasCompleted = 0;

        page.groups.forEach( group => {
            if(group.activated){ 
                let groupNumbers = ProgressBarService.getComplitionNumbersInGroup(group, data)
                nbOfCriteriasTotal += groupNumbers.nbOfCriteriasTotal;
                nbOfCriteriasCompleted+= groupNumbers.nbOfCriteriasCompleted;
            }
        })

        return {nbOfCriteriasTotal, nbOfCriteriasCompleted} ;
    }

    //Return the amount of criteria in a FicheAutocontrole and the amount of completed critera in a FicheAutocontrole
    static getComplitionNumbersInFicheAutocontrole =  (data: FicheAutocontrole) => {
        let nbOfCriteriasTotal =0, nbOfCriteriasCompleted = 0;
        if(data){
            data.tabInfos.groups.forEach( group => { 
                if(group.required && group.activated) {
                    let groupNumbers = ProgressBarService.getComplitionNumbersInGroup(group, data)
                    nbOfCriteriasTotal += groupNumbers.nbOfCriteriasTotal;
                    nbOfCriteriasCompleted+= groupNumbers.nbOfCriteriasCompleted;
                }
            })
            data.tabs.forEach(page => {
                page.groups.forEach( group => { 
                    if(group.required && group.activated) {
                        let groupNumbers = ProgressBarService.getComplitionNumbersInGroup(group, data)
                        nbOfCriteriasTotal += groupNumbers.nbOfCriteriasTotal;
                        nbOfCriteriasCompleted+= groupNumbers.nbOfCriteriasCompleted;
                    }
                })
            });
        }

        return {nbOfCriteriasTotal, nbOfCriteriasCompleted}  ;
    }

    //Return the pourcent of complition of a FicheAutocontrole
    static getComplitionPercent = (data:FicheAutocontrole) => {

        let {nbOfCriteriasTotal, nbOfCriteriasCompleted} = ProgressBarService.getComplitionNumbersInFicheAutocontrole(data);
        
        return Math.trunc(nbOfCriteriasTotal !== 0 ? (nbOfCriteriasCompleted / nbOfCriteriasTotal) * 100 :  0)
    }

}