import { GoBackCurrentState, GoBackCurrentStateActions } from "../types/GoBackState.Types";

const initialState:GoBackCurrentState = {
    isCurrentChange: false
}

const reducer  = (state = initialState, action: { type: string } = {type:''}) =>{
    
    switch(action.type){
        case GoBackCurrentStateActions.INIT_STATE:{
            let copy = {...state};
            copy.isCurrentChange = false;
            return copy;
        }
        case GoBackCurrentStateActions.CHANGING_STATE:{
            let copy = {...state};
            copy.isCurrentChange = true;
            return copy;
        }
        default:{
            return state;
        }
    }
};

export {reducer as GoBackStateReducer};