//Redirections :
export const REDIRECT_HOME_PAGE: string = "/";
export const REDIRECT_PARAMETRES: string = "/parametres";
export const REDIRECT_FICHE: string = "/fiche";
export const REDIRECT_MAIL: string = '/mail';
export const REDIRECT_RGPD: string = '/rgpd';
export const REDIRECT_HOME_ACQ: string = '/home';
export const REDIRECT_HOME_VQ: string = '/home-vq';
export const REDIRECT_VQ_TABLEAU: string = '/vq-result';
export const REDIRECT_VQ_ADMIN: string = '/vq-admin';


//Redirection pages d'erreur
export const REDIRECT_AUTOCONTROLE_TERMINE: string = '/erreur?type=0';
export const REDIRECT_AUTOCONTROLE_NON_ACCESSIBLE: string = '/erreur?type=1';
export const REDIRECT_OUTILS_NON_DISPONIBLE: string = '/erreur?type=2';
export const REDIRECT_INTERDIT: string = '/erreur?type=403';
export const REDIRECT_DELAI: string = '/erreur?type=504';
export const REDIRECT_ERROR: string = '/erreur?type=404';
export const REDIRECT_UNAUTHORIZED: string = '/erreur?type=401';

//Type autocontrole
export const TYPE_AUTOCONTROLE_L4_API = 'LM4'
export const TYPE_AUTOCONTROLE_L15_API = 'LM1P5'
export const TYPE_AUTOCONTROLE_L4 = 'L-4'
export const TYPE_AUTOCONTROLE_L15 = 'L-1,5'
export const TYPE_AUTOCONTROLE_L = 'L'

//Severity
export const SEVERITY = {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error'
}

export enum ModeAffichage {
    SYNTHESE = 'synthese',
    FICHE = 'fiche',
}

export const NO_ACTION = 'Aucune action n’a été réalisée pour le moment'
export const NO_SOLUTION = 'Aucune solution n\'a été choisie'

