import { Filiale, Programme, ReferentielFront, TrancheTravaux } from "../store/types/generalInformations.Types";
import { emptyFiliale, emptyProgramme, emptyReferentielFront, emptyTrancheTravaux, emptyRegroupementResponsabilite, emptyAgence } from "./constantes/emptyObjects";
import { RegroupementResponsabilite, Agence } from "../store/types/homePageVQ.Types";

export class ReferentielUtils {

    static getFilialeLibelle = (filiale: Filiale) => {
        return filiale.codeSociete + ' - ' + filiale.libelleSociete;
    }

    static getProgrammeLibelle = (programme: Programme) => {
        return programme.codeProgramme + ' - ' + programme.libelleProgramme;
    }

    static getTrancheTravauxLibelle = (trancheTravaux: TrancheTravaux) => {
        return trancheTravaux.codeTranche + ' - ' + trancheTravaux.libelleTranche
    }

    static getRegroupementResponsabiliteLibelle = (regroupement: RegroupementResponsabilite) => {
        return regroupement.codeRegroupementResponsabilite + ' - ' + regroupement.libelleRegroupementResponsabilite;
    }

    static getAgenceLibelle = (agence: Agence) => {
        return agence.codeAgence === '' ? agence.libelleAgence : agence.codeAgence + ' - ' + agence.libelleAgence;
    }

    static filialesToReferentielsFront = (filiales: Filiale[]) => {
        if (filiales.length > 0 && filiales[0] !== emptyFiliale) {
            return filiales.map((filiale: Filiale) => {
                let referentielFront: ReferentielFront = {
                    id: filiale.id_referentiel,
                    value: ReferentielUtils.getFilialeLibelle(filiale)
                }
                return referentielFront;
            })
        } else {
            return [emptyReferentielFront]
        }
    }

    static regroupementsResponsabilitesToReferentielsFront = (regroupementsResponsabilites: RegroupementResponsabilite[]) => {
        if (regroupementsResponsabilites.length > 0 && regroupementsResponsabilites[0] !== emptyRegroupementResponsabilite) {
            return regroupementsResponsabilites.map((regroupement: RegroupementResponsabilite) => {
                let referentielFront: ReferentielFront = {
                    id: regroupement.id_referentiel,
                    value: ReferentielUtils.getRegroupementResponsabiliteLibelle(regroupement)
                }
                return referentielFront;
            })
        } else {
            return [emptyReferentielFront]
        }
    }

    static programmesToReferentielsFront = (programmes: Programme[]) => {
        if (programmes.length > 0 && programmes[0] !== emptyProgramme) {
            return programmes.map((programme: Programme) => {
                let referentielFront: ReferentielFront = {
                    id: programme.id_referentiel,
                    value: ReferentielUtils.getProgrammeLibelle(programme)
                }
                return referentielFront;
            })
        } else {
            return [emptyReferentielFront]
        }
    }

    static agencesToReferentielsFront = (agences: Agence[]) => {
        if (agences.length > 0 && agences[0] !== emptyAgence) {
            return agences.map((agence: Agence) => {
                let referentielFront: ReferentielFront = {
                    id: agence.id_referentiel,
                    value: ReferentielUtils.getAgenceLibelle(agence)
                }
                return referentielFront;
            })
        } else {
            return [emptyReferentielFront]
        }
    }

    static tranchesTravauxsToReferentielsFront = (tranchesTravauxs: TrancheTravaux[]) => {
        if (tranchesTravauxs.length > 0 && tranchesTravauxs[0] !== emptyTrancheTravaux) {
            return tranchesTravauxs.map((trancheTravaux: TrancheTravaux) => {
                let referentielFront: ReferentielFront = {
                    id: trancheTravaux.id_referentiel,
                    value: ReferentielUtils.getTrancheTravauxLibelle(trancheTravaux)
                }
                return referentielFront;
            })
        } else {
            return [emptyReferentielFront]
        }
    }
}