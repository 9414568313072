import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { FicheAutocontroleReducer } from "../reducers/FicheAutocontrole.Reducer";
import { FicheAutocontroleState } from "../types/FicheAutocontrole.Types";
import { currentStateReducer } from "../reducers/currentPage.Reducer";
import { CurrentState } from "../types/CurrentState.Types";
import { UserState } from "../types/user.Types";
import { UserReducer } from "../reducers/user.Reducer";
import { GeneralInformationsReducer } from "../reducers/generalInformations.Reducer";
import { GeneralInformationsState } from "../types/generalInformations.Types";
import { IntervenantsReducer } from "../reducers/intervenants.Reducer";
import { HomePageState } from "../types/homePage.Types";
import { HomePageReducer } from "../reducers/homePage.Reducer";
import { PicturesReducer } from "../reducers/pictures.Reducer";
import { PictureDownloadedState, PictureState } from "../types/pictures.Types";
import { TabSyntheseReducer } from "../reducers/tabSynthese.Reducer";
import { TabSyntheseState } from "../types/TabSynthese.Types";
import { GoBackStateReducer } from "../reducers/GoBackState.Reducer";
import { GoBackCurrentState } from "../types/GoBackState.Types";
import { IntervenantsState } from "../types/intervenants.Types";
import { MessagesAlertReducer } from "../reducers/messagesAlert.Reducer";
import { MessagesAlertState } from "../types/MessagesAlert.Types";
import { OldPicturesReducer } from "../reducers/old.pictures.Reducer";
import { DateStateReducer } from '../reducers/dateState.Reducer';
import { DownloadedPicturesReducer } from '../reducers/downloaded.pictures.reducer';
import { DateState } from '../types/date.Types';
import { ReInitPicCompState } from "../reducers/reInitPicCompState.Reducer";
import { HomePageVQState } from "../types/homePageVQ.Types";
import { HomePageVQReducer } from "../reducers/homePageVQ.Reducer";
import { AdminVisiteQualiteState, VisiteQualiteState } from "../types/adminVisiteQualite.Types";
import { AdminVisiteQualiteReducer } from "../reducers/adminVisiteQualite.Reducer";
import { VisiteQualiteReducer } from "../reducers/visiteQualite.Reducer";


export interface ApplicationState {
  router: RouterState;
  ficheAutocontrole: FicheAutocontroleState;
  currentState: CurrentState;
  user: UserState;
  messagesAlertState: MessagesAlertState;
  generalInformations: GeneralInformationsState;
  intervenantsState: IntervenantsState;
  visiteQualiteState: VisiteQualiteState;
  homePageState: HomePageState;
  homePageVQState: HomePageVQState;
  adminVisiteQualiteState: AdminVisiteQualiteState;
  tabSyntheseState: TabSyntheseState;
  pictures: PictureState[];
  downloadedPictures: PictureDownloadedState[];
  goBackState: GoBackCurrentState;
  oldPictures: Array<string>;
  dateState: DateState;
  reInitPicComp: boolean
}

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ficheAutocontrole: FicheAutocontroleReducer,
    currentState: currentStateReducer,
    user: UserReducer,
    generalInformations: GeneralInformationsReducer,
    intervenantsState: IntervenantsReducer,
    visiteQualiteState: VisiteQualiteReducer,
    messagesAlertState: MessagesAlertReducer,
    homePageState: HomePageReducer,
    homePageVQState: HomePageVQReducer,
    adminVisiteQualiteState: AdminVisiteQualiteReducer,
    tabSyntheseState: TabSyntheseReducer,
    pictures: PicturesReducer,
    downloadedPictures: DownloadedPicturesReducer,
    goBackState: GoBackStateReducer,
    oldPictures: OldPicturesReducer,
    dateState: DateStateReducer,
    reInitPicComp: ReInitPicCompState
  });
