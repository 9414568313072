import { FicheAutocontroleIntervenant } from "../store/types/FicheAutocontrole.Types";
import { Filiale, GeneralInformationsState, Programme } from "../store/types/generalInformations.Types";
import { IntervenantsToSave } from "../store/types/intervenants.Types";
import DateUtils from "./DateUtils";

export default class IntervenantUtils{

    static findDuplicates = (arr: FicheAutocontroleIntervenant[]) => {
    
        let results = [];
        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr.length - 1; j++) {
                if (arr[i].nom === arr[j].nom && i !== j) {
                    if(arr[i].isInterne === false) {
                        results.push(arr[i]);
                    } else if(arr[j].isInterne === false) {
                        results.push(arr[j]);
                    }
                }
            }
        }
        return results;
      }
    
    static removeDuplicate = (refList:FicheAutocontroleIntervenant[]) => {

        // Remove duplicate and keep only interns if duplicate
        const externesWithDouble = IntervenantUtils.findDuplicates(refList);

        const idsToRemove =  externesWithDouble.map((inter) => inter.id_gpi); 

        return refList.filter((ref) => { 
            return !idsToRemove.includes(ref.id_gpi); 
         }); 

    }

    /**
     * Vérifie si l' intervenant a besoin d'être update suite au changement de filiale/programme
     * @param filiale la filiale selectionnée
     * @param programme le programme selectionnées
     * @param infosGenerales les infos générales contenues dans le store
     * @param intervenant intervenant dans le store
     * @returns true si les intervenants sont à jour, false sinon 
     */
    static checkIfIntervenantIsUpdated = (filiale: Filiale, programme: Programme, infosGenerales: GeneralInformationsState, intervenant: FicheAutocontroleIntervenant) => {
        return filiale && programme && intervenant && (filiale?.codeSociete === infosGenerales?.filiale?.codeSociete) && (programme?.codeProgramme === infosGenerales?.programme?.codeProgramme)
    }

    /**
     * Détermine si les parametres intervenant sont corrects
     * @param values les intervenants à vérifier (+ date)
     * @param checkDate flag indiquant si on effectue le test sur la date
     * @returns true si OK , false sinon
     */
    static isParametresIntervenantsOk = (values: IntervenantsToSave, checkDate: boolean) => {
        if(!!values.date && checkDate && DateUtils.isBeforeNow(values.date)){
            return false;
        }
        if(!values.chargeMissionQualite || values.chargeMissionQualite.nom === ''){
            return false;
        }
        if(values?.responsableProgramme?.nom === '' && !values?.responsableTechnique?.isInterne 
            && !values?.conducteurTravaux?.isInterne){
                return false;
        }
        return true;
    }

    /**
     * retourne une liste de messages d'erreur
     * @param values les intervenants à vérifier (+ date)
     * @param checkDate flag indiquant si on effectue le test sur la date
     * @returns les messages d'erreur
     */
    static getMessagesParametresIntervenants = (values: IntervenantsToSave, checkDate: boolean) => {
        let messages = [] as string[];
        if(!!values.date && checkDate && DateUtils.isBeforeNow(values.date)){
            messages.push("Veuillez sélectionner une date limite de saisie supérieure ou égale à la date du jour.")
        }
        if(!values.chargeMissionQualite || values.chargeMissionQualite.nom === ''){
            messages.push("Veuillez sélectionner un chargé de mission Qualité.")
        }
        if(values?.responsableProgramme?.nom === '' && !values.responsableTechnique?.isInterne 
            && !values?.conducteurTravaux?.isInterne){
                messages.push("Veuillez sélectionner au moins un intervenant terrain interne.")
        }
        return messages;
    }

    static getLabel = (value:any, intervenants?:FicheAutocontroleIntervenant[]) =>{
        let result:string = '';
        if(!!intervenants && !!value){
            intervenants.forEach((item) => {
                if(item.nom === value){
                    result = !item.isInterne ? ' (Externe)' : ''
                }
            })
        }else{
            result = '';
        } 
        return result
    }

    static getExterneValue = (intervenants?:FicheAutocontroleIntervenant) => {
        return !!intervenants && !intervenants.isInterne ? ' (Externe)' : ''
    }
}