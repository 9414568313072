import { Card, CardActionArea, CardActions, CardMedia, TextField, Button, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { FicheAutocontrole, FicheAutoControleCritere } from "../../../store/types/FicheAutocontrole.Types";
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled } from "../../../styles/CritereAutocontrole.style";
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import defaultPicture from "./../../../assets/picture-icon-default.svg";
import disabledPicture from "./../../../assets/picture-icon-disable.svg"
import inaccessiblePicture from "./../../../assets/picture-icon-inaccessible.png"
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/store/store";
import { ThunkDispatch } from "redux-thunk";
import { DownloadedPictureTypes, OldPictureTypes, PictureState, PictureTypes } from "../../../store/types/pictures.Types";
import { AnyAction } from "redux";
import { AUTOCONTROLE_STATUT, GeneralInformationsState } from "../../../store/types/generalInformations.Types";
import { getPictureFromS3 } from "../../../utils/upload.image.s3.service";
import { ModeAffichage, SEVERITY, TYPE_AUTOCONTROLE_L15_API, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L4_API } from '../../../utils/constantes/contantes';
import { TabSynthese } from "../../../store/types/TabSynthese.Types";
import { MessagesAlertState, MessagesAlertTypes } from "../../../store/types/MessagesAlert.Types";
import { TailSpin } from 'react-loader-spinner'
import InfoPhoto from '../../../assets/info_photo.svg'
import { ReInitPicCompActions } from "../../../store/types/ReInitPicComp.Types";
import { GoBackCurrentStateActions } from "../../../store/types/GoBackState.Types";
import { CritereUtils } from "../CritereUtils";


interface PropsFromState {
    critere: FicheAutoControleCritere,
    updateCritere: Function,
    modeAffichage?: ModeAffichage
}

interface PropsFromDispatch {
    pictures: PictureState[],
    addPicture: Function,
    deletePicture: Function,
    ficheAutocontrole: FicheAutocontrole,
    generalInformations: GeneralInformationsState,
    oldPictures: Array<string>,
    addOldPicture: Function,
    deleteOldPicture: Function,
    tabSynthese: TabSynthese,
    updateMessageSnackBar: Function,
    reInitPicComp: boolean
    addDownloadedPicture: Function,
    deleteDownloadedPicture: Function,
    initPicComp: Function,
    goBackStateHasChanged: Function,

}

type AllProps = PropsFromState & PropsFromDispatch;

const PictureAutocontrole: React.FC<AllProps> = ({ modeAffichage, addDownloadedPicture, deleteDownloadedPicture, critere, updateCritere, pictures, addPicture, deletePicture, ficheAutocontrole, generalInformations, oldPictures, addOldPicture, deleteOldPicture, tabSynthese, updateMessageSnackBar, reInitPicComp, initPicComp, goBackStateHasChanged }) => {

    const inputFile = React.useRef(null);
    const [selectedFile, setSelectedFile] = React.useState<File | undefined | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [commentaire, setCommentaire] = React.useState(critere.value ? critere.value : "");
    const [picture, setPicture] = React.useState(critere.activated ? defaultPicture : disabledPicture);
    const [, setIsFormatPictureValid] = React.useState(true);
    const [oldPicName, setOldPicName] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const history = useHistory();

    const loadPic = () => {
        const pics = pictures.filter(p => p.name === critere.photoName);

        if(pics && pics.length > 0 && critere.photoName !== undefined) {
            var file = new File([pics[0].pic], critere.photoName, { type: critere.photoType });
            setSelectedFile(file);
            addDownloadedPicture(critere.photoName, critere.id);
            setOldPicName(critere.photoName ? critere.photoName : "");
            setLoading(false);
        }

        if (critere.photoName && pics.length === 0) {

            setLoading(true);
            getPictureFromS3(critere.photoName ? critere.photoName : '').then(async (img: any) => {

                if (!!img && !!img.data && !!img.data.uploadUrl) {
                    let blob = await fetch(img.data.uploadUrl)
                        .then(r => r.blob());
                    if (!!blob && blob.size !== 0 && blob.type.includes("image/")) {
                        var file = new File([blob], img.data.photoFilename, { type: critere.photoType });
                        setSelectedFile(file);
                        addDownloadedPicture(critere.photoName, critere.id);
                        setOldPicName(critere.photoName ? critere.photoName : "")
                        setLoading(false);
                    }
                    else {
                        console.error("Accès à la photo" + critere.photoName + "impossible.")
                        setSelectedFile(null);
                        deleteDownloadedPicture(critere.id);
                        setLoading(false);
                    }
                } else {
                    setSelectedFile(null);
                    deleteDownloadedPicture(critere.id);
                }
            })
                .catch(error => {
                    console.log(error);
                    setSelectedFile(null);
                    deleteDownloadedPicture(critere.id);
                    setLoading(false);
                });
        }
    }




    useEffect(() => {
        if (!!critere.photoName && !selectedFile && !isUploading) {
            loadPic();
        } else {
            setPicture(getImage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, critere.photoName]);

    useEffect(() => {

        if (!!critere.photoName && !oldPicName) {
            loadPic();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reInitPicComp]);

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.value = "";
        critere.photoName = "";
        critere.photoId = "";
        critere.activated = event.target.checked;
        if (inputFile && inputFile.current) {
            // @ts-ignore: Object is possibly 'null'.
            inputFile.current.value = "";
        }
        setSelectedFile(null);
        setPicture(getImage());
        updateCritere(critere);
    };

    const handleChangeInputText = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        goBackStateHasChanged();
        if (((event.target as HTMLInputElement).value as unknown as string).length <= 250) {
            setCommentaire((event.target as HTMLInputElement).value);
            critere.value = (event.target as HTMLInputElement).value as unknown as string;
            updateCritere(critere);
        } else {
            setCommentaire((event.target as HTMLInputElement).value.slice(0, 250));
            critere.value = ((event.target as HTMLInputElement).value as unknown as string).slice(0, 250);
            updateCritere(critere);
        }
    };

    // Renvoie un blob depuis un FormData
    const getBlobFromFormData = (formData: FormData) => {

        for (var pair of formData.entries()) {
            if (pair[0] !== 'Content-Type') {
                return pair[1];
            }
        }
    }

    const getAcType = () => {
        let actype = (generalInformations.typeAutocontrole === TYPE_AUTOCONTROLE_L4)
            ? TYPE_AUTOCONTROLE_L4_API
            : TYPE_AUTOCONTROLE_L15_API;
        return actype.toLowerCase();
    }

    const uploadImage = (file: any) => {

        if (file.size < 10000000) {
            const today = new Date();
            const fileExtension = file.name.split(".").pop();
            const codeFiliale = Number(generalInformations.filiale.codeSociete).toString();
            const numprog = Number(generalInformations.programme.codeProgramme).toString();
            const numtranche = Number(generalInformations.trancheTravaux.codeTranche).toString();
            const actype = getAcType();

            const name = codeFiliale + "_" + numprog + "_" + numtranche + "_" + actype + "_" + critere.id + "_" + today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString() + today.getHours().toString() + today.getMinutes().toString() + "." + fileExtension;

            console.log("is Pic changed : " + !!selectedFile && !!critere.photoName);

            if (!!selectedFile && !!critere.photoName && !!oldPicName && !oldPictures.includes(oldPicName)) {
                addOldPicture(oldPicName);
            }

            setSelectedFile(file);
            critere.photoName = name;
            critere.photoId = name;
            critere.value = commentaire;
            critere.photoType = file.type;
            let formData = new FormData();
            formData.append(
                name,
                file,
                name
            );
            formData.append('Content-Type', file.type);
            const blob = getBlobFromFormData(formData);
            deletePicture(critere.id);
            console.log(" add pic was executed !! ");
            goBackStateHasChanged();
            addPicture(blob, name, file.type);
            updateCritere(critere);
            setPicture(getImage());
        }
        setIsUploading(false);
    }

    const handleFileUpload = (e: any) => {
        const { files } = e.target;
        if (files.length === 1 && isFormatFileValid(e.target.value)) {
            setIsUploading(true);
            uploadImage(files[0]);
        }
    };

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        if (inputFile && inputFile.current) {
            // @ts-ignore: Object is possibly 'null'.
            inputFile.current.click();
        }

    };

    const getTitlePicture = () => {
        if (!selectedFile && !!critere.photoName) {
            return "La récupération enregistrée n'a pas pu être effectuée. Veuillez déposer à nouveau la photo"
        } else if (!critere.activated) {
            return "Critère désactivé"
        } else {
            return "Ajouter une photo"
        }
    }

    const getImage = () => {

        //TODO : get the real picture
        if (selectedFile && !!critere.photoName) {
            return window.URL.createObjectURL(selectedFile);
        } else if (!selectedFile && !!critere.photoName) {
            return inaccessiblePicture;
        } else {
            return critere.activated ? defaultPicture : disabledPicture;
        }
    }

    const isFormatFileValid = (fileName: string) => {
        const idxDot = fileName.lastIndexOf(".") + 1;
        const filesAccepted = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'pjp', 'xbm', 'jxl', 'ico', 'tiff', 'jfif', 'webp', 'bmp', 'pjpeg', 'avif']
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (filesAccepted.includes(extFile)) {
            setIsFormatPictureValid(true)
            return true
        } else {
            setIsFormatPictureValid(false);
            updateMessageSnackBar({ messages: ["Le fichier que vous avez choisi n'est pas un fichier image accepté par l'application.\n Merci de sélectionner un autre fichier"], severity: SEVERITY.ERROR });
            return false
        }
    }

    const showImage = () => {
        if (selectedFile && critere.activated) {
            window.open(window.URL.createObjectURL(selectedFile), '_blank')?.focus()
        }
    }

    const downloadImage = () => {
        if (selectedFile && critere.activated) {
            return window.URL.createObjectURL(selectedFile);
        }
    }

    const getCardBody = () => {
        if (selectedFile && critere.photoName && critere.activated) {
            return (
                <CardActionArea className=" critera-picture-img-container">
                    {isUploading ?
                        <CircularProgress className=" critera-picture-loading" />
                        :
                        <>
                            <a href={downloadImage()} className=" critera-picture-img" download={critere.photoName} onClick={showImage}>
                                <CardMedia
                                    className=" critera-picture-img"
                                    image={critere.photoName ? picture : ''}
                                    title={critere.photoName}
                                />
                            </a>
                        </>
                    }
                </CardActionArea>
            )
        } else {
            return (
                <div className={getClassNameImg()} onClick={() => onButtonClick()}>
                    <CardMedia
                        className="critera-picture-img-default"
                        component="img"
                        alt="Zone d'upload de photo"
                        image={getImage()}
                        title={getTitlePicture()}
                    />
                </div>
            )
        }
    }

    const getClassNameImg = () => {
        if (isButtonDisp()) {
            return 'critera-picture-img-container-rapport-envoye'
        } else if (critere.activated) {
            return 'critera-picture-img-container'
        } else {
            return 'critera-picture-img-container-disabled'
        }
    }

    const isButtonDisp = () => {
        return !!tabSynthese && !!tabSynthese.statut && tabSynthese.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE;
    }


    return (<div>
        <div className="libelle-picture">
            <img className="icon-libelle" src={InfoPhoto} alt="Icone d'accées sur la page RGPD" onClick={() => history.push('/rgpd?mode=info')} />
            <LabelCritereAutocontroleStyled>{critere.title}
                {CritereUtils.getActiveStateFromCriteria(critere, modeAffichage) ?
                    <SwitchAutocontroleStyled className="elt-float-right" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt="" />} />
                    : ""
                }
            </LabelCritereAutocontroleStyled>
        </div>
        <Card className={" critera-picture-card".concat(critere.activated ? "" : " critera-picture-disabled")}>
            {!loading ? getCardBody() : ""}
            <CardActions>
                <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    onChange={handleFileUpload}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    disabled={!critere.activated}
                />
                {!isButtonDisp() && !loading ?
                    <Button className=" critera-picture-button" onClick={() => onButtonClick()} disabled={!critere.activated}>
                        {selectedFile && !!critere.photoName ? "Modifier la photo" : "Ajouter une photo"}
                    </Button>
                    : ''}
            </CardActions>

            {loading ? <div>
                <div id="loading">
                    <TailSpin color="#d6d1d6" height={50} width={50} />
                </div>
            </div> : ''}

        </Card>
        <div className={"critera-input-text-box critera-picture-inputText".concat(critere.activated ? "" : " critera-picture-disabled")}>
            <TextField
                className=' critera-input-text'
                value={critere.value ? commentaire : ""}
                onChange={handleChangeInputText}
                placeholder="Ecrivez votre commentaire ici..."
                helperText={(commentaire as string)?.length === 250 ? "Vous avez atteint la limite du nombre de caractères." : ""}
                error={(commentaire as string)?.length === 250}
                disabled={!critere.activated}
                multiline />
        </div>
    </div>);
};

const mapStateToProps = ({ pictures, ficheAutocontrole, generalInformations, oldPictures, tabSyntheseState, downloadedPictures, reInitPicComp }: ApplicationState) => ({
    pictures: pictures,
    ficheAutocontrole: ficheAutocontrole.data,
    generalInformations: generalInformations,
    oldPictures: oldPictures,
    tabSynthese: tabSyntheseState.data,
    reInitPicComp: reInitPicComp,
    downloadedPictures: downloadedPictures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addPicture: (pic: any, name: string, imgType: string) => dispatch({ type: PictureTypes.ADD_PIC, payload: pic, name: name, imgType: imgType }),
        deletePicture: (id: string) => dispatch({ type: PictureTypes.DELETE_PIC, id: id }),
        addOldPicture: (name: string) => dispatch({ type: OldPictureTypes.ADD_OLD_PIC, picName: name }),
        deleteOldPicture: (name: string) => dispatch({ type: OldPictureTypes.DELETE_OLD_PIC, picName: name }),
        addDownloadedPicture: (name: string, id: string) => dispatch({ type: DownloadedPictureTypes.ADD_PIC, name: name, id: id }),
        deleteDownloadedPicture: (name: string) => dispatch({ type: DownloadedPictureTypes.DELETE_PIC, picName: name }),
        updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params }),
        initPicComp: () => dispatch({ type: ReInitPicCompActions.INIT }),
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PictureAutocontrole);