import { Reducer } from "redux";
import { IntervenantsAutocontroleTypes, IntervenantsState } from "../types/intervenants.Types";

export const initialState:  IntervenantsState={
    responsablesProgramme: null,
    conducteursTravaux: null,
    responsablesTechnique: null,
    chargesMissionQualite: null,
    directeursProgrammes: null,
    directeursOperationnel: null,
}

const reducer: Reducer<IntervenantsState> = (state = initialState, action = {type:'', payload:''}) => {
    switch(action.type){
        case IntervenantsAutocontroleTypes.UPDATE_STATE:{
            let copy = {...state};
            copy = action.payload;
            return {...copy}
        }
        case IntervenantsAutocontroleTypes.RESET_STATE:{
            return {
                responsablesProgramme: null,
                conducteursTravaux: null,
                responsablesTechnique: null,
                chargesMissionQualite: null,
                directeursProgrammes: null,
                directeursOperationnel: null,
            } as IntervenantsState
        }
        default:{
            return state;
        }
    }
}

export {reducer as IntervenantsReducer};