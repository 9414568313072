import { Reducer } from "redux";
import { MessagesAlertState, MessagesAlertTypes } from "../types/MessagesAlert.Types";

export const initialState: MessagesAlertState = {
    messages: [] as string[]
}

const reducer: Reducer<MessagesAlertState> = (state = initialState, action = {type:'', payload:''}) => {
    switch(action.type){
        case MessagesAlertTypes.UPDATE_STATE:{
            let copy = {...state};
            copy = action.payload;
            return {...copy}
        }
        case MessagesAlertTypes.RESET_STATE:{
            return initialState;
        }
        default:{
            return state;
        }
    }
}

export {reducer as MessagesAlertReducer};