import { ApplicationState } from '../store/store/store';

/**
 * Permet de changer l'état de l'application  depuis le localStorage
 */
export const loadState = () => {

    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null || isStateReset()) {
            localStorage.removeItem('state');
            sessionStorage.removeItem('statereset');
            return undefined;
        } else {
            // test date state
            let state: ApplicationState = JSON.parse(serializedState);
            if (state && state.dateState && state.dateState.date) {
                let dateStore = Number(state.dateState.date);
                let timestamp = new Date();
                timestamp.setHours(timestamp.getHours() - 12);
                var dateM12H = Number(timestamp);
                if (dateStore < dateM12H) {
                    localStorage.removeItem('state');
                    return undefined;
                }
            }

        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

/**
 * Permet de sauvegarder l'état de l'application  dans le localStorage
 */
export const saveState = (state: ApplicationState) => {
    try {
        const now = Number(new Date());
        state.dateState.date = now;
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors;
    }
};


/**
 * Permet de vérifier que le state n'est pas périmé de plus de 12h
 */
export const verifState = () => {
    const serializedState = localStorage.getItem('state');
    if (serializedState !== null) {
        let state: ApplicationState = JSON.parse(serializedState);
        if (state && state.dateState && state.dateState.date) {
            let dateStore = Number(state.dateState.date);
            let timestamp = new Date();
            timestamp.setHours(timestamp.getHours() - 12);
            var dateM12H = Number(timestamp);
            if (dateStore < dateM12H) {
                localStorage.removeItem('state');
                sessionStorage.setItem('statereset', JSON.stringify({ date: Number(new Date()) }));
                return false;
            }
        }
    }
    return true;
}

/**
 * Permet de vérifier que le state vient d'être reseté 5s de latence)
 */
export const isStateReset = () => {
    let json_statereset = sessionStorage.getItem('statereset');
    if (json_statereset) {
        let statereset = JSON.parse(json_statereset);
        let now = Number(new Date());
        let state_date = Number(statereset.date);
        let diff = (now - state_date);
        if (diff < 5000) {
            return true;
        }
    }
    return false;
}