import { FicheAutocontroleTab, FicheAutocontroleTabInfos, ParamsSynthese } from "../store/types/FicheAutocontrole.Types";
import { AUTOCONTROLE_STATUT } from "../store/types/generalInformations.Types";
import { TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L15_API, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L4_API } from "./constantes/contantes";
import Env from './Env';
import httpInstance from "./http-common";
const jwtBuilder = require('jwt-builder');

export class API {

    static getFilialesAPI = () => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/filiales");
    }

    static getProgrammesAPI = (idFiliale: string | undefined) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/filiales/" + idFiliale + "/programmes")
    }

    static getTranchesTravauxAPI = (idFiliale: string | undefined, idProgramme: string | undefined) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/filiales/" + idFiliale + "/programmes/" + idProgramme + "/tranchetravaux");
    }

    static getListesFilialesProgrammesTranches = (id_referentiel: string) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/filialesProgrammesTranches/" + id_referentiel);
    }

    static getInfosCotationAPI = (idInfosCotations: string) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/intervenants/infoscotation/" + idInfosCotations);
    }

    static getRegroupementsResponsabilitesAPI = () => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/regroupementsresponsabilites");
    }

    static getAgencesAPI = (idRegroupementResponsabilite: string | undefined) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/regroupementsresponsabilites/" + idRegroupementResponsabilite + "/agences")
    }

    static getTranchesTravauxRegroupementsAPI = (idRegroupementResponsabilite: string | undefined, idAgence: string | undefined, dateDebut: number, dateFin: number) => {
        let url = Env.REACT_APP_STAGE + "/referentiels/tranchestravaux?idRegroupementResponsabilite=" + idRegroupementResponsabilite;
        if (idAgence) {
            url = url + "&idAgence=" + idAgence;
        }
        return httpInstance.get(url + "&dateDebut=" + dateDebut + "&dateFin=" + dateFin);
    }

    static getAutocontrole = (idTrancheTravaux: string | undefined, typeAutocontrole: string) => {
        let typeAutocontroleAPI = typeAutocontrole;

        if (typeAutocontrole === TYPE_AUTOCONTROLE_L4) {
            typeAutocontroleAPI = TYPE_AUTOCONTROLE_L4_API;
        }
        if (typeAutocontrole === TYPE_AUTOCONTROLE_L15) {
            typeAutocontroleAPI = TYPE_AUTOCONTROLE_L15_API;
        }
        return httpInstance.get(Env.REACT_APP_STAGE + "/autocontroles/" + idTrancheTravaux + "/type/" + typeAutocontroleAPI);
    }

    static getPeriodeLivraisonAutocontrole = (idTrancheTravaux: string | undefined, typeAutocontrole: string) => {
        let typeAutocontroleAPI = typeAutocontrole;

        if (typeAutocontrole === TYPE_AUTOCONTROLE_L4) {
            typeAutocontroleAPI = TYPE_AUTOCONTROLE_L4_API;
        }
        if (typeAutocontrole === TYPE_AUTOCONTROLE_L15) {
            typeAutocontroleAPI = TYPE_AUTOCONTROLE_L15_API;
        }

        return httpInstance.get(Env.REACT_APP_STAGE + "/autocontroles/" + idTrancheTravaux + "/type/" + typeAutocontroleAPI + "/periodeLivraison");
    }

    static updateAutocontrole = (tabToSave: FicheAutocontroleTabInfos | FicheAutocontroleTab | ParamsSynthese | { id_pk: string, id_sk: string, statut: AUTOCONTROLE_STATUT }, percentCompletion: number, statut?: string) => {
        let params = {
            tabToSave: tabToSave,
            statut: statut,
            percentCompletion: percentCompletion
        }
        return httpInstance.post(Env.REACT_APP_STAGE + "/autocontroles/update", params);
    }

    static createAutocontrole = (params: any) => {
        return httpInstance.post(Env.REACT_APP_STAGE + "/autocontroles/create", params);
    }

    static createOrUpdateVisiteQualite = (params: any) => {
        return httpInstance.post(Env.REACT_APP_STAGE + "/visiteQualite/createOrUpdate", params);
    }

    static getReferentiels = (refType: string, codeFiliale: string) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/intervenants?type=" + refType + "&codeFiliale=" + codeFiliale);
    }

    static getUserRole = () => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/user/roles");
    }

    static getRgpd = (mail: string) => {
        return httpInstance.get(Env.REACT_APP_STAGE + "/referentiels/rgpd?mail=" + mail);
    }

    static updateRgpd = (params: any) => {
        return httpInstance.post(Env.REACT_APP_STAGE + "/rgpd", params);
    }


    static getSignedtUrlS3 = (name: string, httpType: string) => {
        const params = {
            imgName: name,
            methodName: httpType
        }

        return httpInstance.get(Env.REACT_APP_STAGE + "/url-s3", {
            params: params
        });
    }


    static deletePicFromS3 = (name: string) => {
        const params = {
            imgName: name,
            methodName: 'DELETE'
        }

        return httpInstance.get(Env.REACT_APP_STAGE + "/url-s3", {
            params: params
        });
    }

    static sendMailReport = (idAutocontrole: string, destinataire: string, destinataireCC: string, objet: string, body: any) => {
        let parameters = {
            idAutocontrole: idAutocontrole,
            destinataire: destinataire,
            destinataireCc: destinataireCC,
            sujet: objet,
            message: body,
        }

        return httpInstance.post(Env.REACT_APP_STAGE + '/report/sendMail', parameters);
    }

    static getTranchesTravauxValidForFilialeAndProgramme = (params: any) => {
        let builder = jwtBuilder()
            .nbf()             // can't be used before current time
            .algorithm('HS256')
            .secret('super-secret');

        let token: string = builder.claims({
            params: params
        })
            .build();
        return httpInstance.get(Env.REACT_APP_STAGE + '/autocontroles/tranchestravaux?token=' + token);
    }

    static getSyntheseAutocontrole = (id_sk: string) => {
        let builder = jwtBuilder()
            .nbf()             // can't be used before current time
            .algorithm('HS256')
            .secret('super-secret');

        let token: string = builder.claims({
            id_sk: id_sk
        })
            .build();
        return httpInstance.get(Env.REACT_APP_STAGE + '/autocontroles/synthese?token=' + token);
    }


    static getProgrammePicture = (id_prog: string) => {
        return httpInstance.post(Env.REACT_APP_STAGE + '/programmes/picture/' + id_prog);
    }

    static getOpenidConfiguration = () => {
        return httpInstance.get(Env.REACT_APP_AZURE_AUTHORITY + '/v2.0/.well-known/openid-configuration');
    }

    static getRefreshToken = (url: string, clientId: string, refreshToken: any) => {
        // add form encoded 
        const params = "grant_type=refresh_token&client_id=" + clientId + "&refresh_token=" + refreshToken;

        // config header
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        return httpInstance.post(url, params, config);
    }
}