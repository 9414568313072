import React from 'react';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { CircularProgress } from '@material-ui/core';

const Authentification = (props: { children: any; }) => {

  

  return (
    <div>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          {props.children}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <CircularProgress />
      </UnauthenticatedTemplate>
    </div>
  )
}

export default Authentification;