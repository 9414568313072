import React from 'react';
import '../../SyntheseTabs/SyntheseTab.scss';
import { connect } from 'react-redux';
import { TabSynthese, TabSyntheseState, TabSyntheseStateActions } from '../../../../store/types/TabSynthese.Types';
import { ApplicationState } from '../../../../store/store/store';
import SelectAutocontrole from '../../../Criteres/TypesCriteres/SelectAutocontrole';
import { emptyTabInfosIntervenant, getEmptyDateCritere, getEmptySelectCritere } from '../../../../utils/constantes/emptyObjects';
import { FicheAutocontrole, FicheAutoControleCritere, FicheAutocontroleIntervenant } from '../../../../store/types/FicheAutocontrole.Types';
import './SyntheseInfoCmq.scss'
import DateAutocontrole from '../../../Criteres/TypesCriteres/DateAutocontrole';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { API } from "../../../../utils/callAPI";
import { AUTOCONTROLE_STATUT } from "../../../../store/types/generalInformations.Types";
import IntervenantUtils from '../../../../utils/IntervenantUtils';
import { AutocontroleIntervenants, IntervenantsAutocontroleTypes, IntervenantsState } from '../../../../store/types/intervenants.Types';
import DateUtils from '../../../../utils/DateUtils';
import MessageDeadline from '../../../../utils/InformationMessage';
import { REDIRECT_HOME_ACQ } from '../../../../utils/constantes/contantes';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { GoBackCurrentStateActions } from '../../../../store/types/GoBackState.Types';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsFromDispatch {
    tabSynthese: TabSynthese,
    intervenantsState: IntervenantsState
    updateTabSyntheseState: Function,
    updateIntervenantsState: Function,
    isSaved: boolean,
    setIsSaved: Function,
    setIsDateSaved: Function,
    setIsIntervenantsSaved: Function,
    data: FicheAutocontrole,
    initGoBackState: Function

}

const SyntheseTab: React.FC<PropsFromDispatch> = ({ setIsIntervenantsSaved, tabSynthese, intervenantsState, updateIntervenantsState, updateTabSyntheseState, setIsSaved, setIsDateSaved, data, initGoBackState }) => {


    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    const sortIntervenants = (referentiels: string[]) => {
        return referentiels ? referentiels.sort((a: string, b: string) => a.localeCompare(b)) : [] as string[]
    }

    const buildInfo = (title: string, value: string | number) => {
        return <div className="synthese-info">
            <div className="info-title">{title}</div>
            <div className="info-value">{(value || (value !== "")) ? value : ''}</div>
        </div>
    }

    const getSelectCritere = (intervenant: FicheAutocontroleIntervenant, refType: string) => {
        let critere = getEmptySelectCritere();
        critere.options = refType;

        switch (refType) {
            case AutocontroleIntervenants.chargesMissionQualite: {
                critere.value = intervenant.nom;
                critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
                critere.choices = sortIntervenants((intervenantsState?.chargesMissionQualite as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Chargé de mission qualité';
                break;
            }
            case AutocontroleIntervenants.responsablesProgramme: {
                critere.value = intervenant.nom;
                critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
                critere.choices = sortIntervenants((intervenantsState?.responsablesProgramme as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Responsable programme';
                break;
            }
            case AutocontroleIntervenants.responsablesTechnique: {
                critere.value = intervenant.nom;
                critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
                critere.choices = sortIntervenants((intervenantsState?.responsablesTechnique as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = intervenantsState.responsablesTechnique !== null ? intervenantsState.responsablesTechnique : undefined
                critere.title = 'Responsable technique';
                break;
            }
            case AutocontroleIntervenants.conducteursTravaux: {
                critere.value = intervenant.nom;
                critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
                critere.choices = sortIntervenants((intervenantsState?.conducteursTravaux as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = intervenantsState.conducteursTravaux !== null ? intervenantsState.conducteursTravaux : undefined
                critere.title = 'Conducteur de travaux';
                break;
            }
        }
        return critere;
    }

    const getDateValid = () => {
        return DateUtils.getDateMajForStatut(data, AUTOCONTROLE_STATUT.FICHE_COMPLETEE);
    }

    const getDateCritere = (date: number) => {
        let critere = getEmptyDateCritere();
        critere.value = date;
        critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
        critere.dateMin = new Date().toISOString().slice(0, 10);
        critere.title = 'Date limite de saisie';
        return critere;
    }

    const isDateValide = (date: number) => {
        return (date && date !== 0)
    }

    const getUserValidated = (data: FicheAutocontrole) => {
        if (data.statutHisto) {
            const statusFiltered = data.statutHisto.filter((st) => st.libelle.toLowerCase() === AUTOCONTROLE_STATUT.FICHE_COMPLETEE.toLowerCase());
            const lastStatus = statusFiltered[statusFiltered.length - 1];
            return lastStatus && lastStatus.user ? lastStatus.user.name.toUpperCase() : '';
        }
        return '';
    }

    const hasUserTerrainValidated = (data: FicheAutocontrole) => {
        if (data.statutHisto) {
            const statusFiltered = data.statutHisto.filter((st) => st.libelle.toLowerCase() === AUTOCONTROLE_STATUT.FICHE_COMPLETEE.toLowerCase());
            const lastStatus = statusFiltered[statusFiltered.length - 1];
            if (lastStatus && lastStatus.user) {
                // verification si le mail correspond à celui d'un des intervenants terrain
                let found = false;
                if (tabSynthese?.infosGenerales?.responsableProgramme) {
                    found = found || tabSynthese?.infosGenerales?.responsableProgramme.mail.toLowerCase() === lastStatus.user.mail.toLowerCase();
                }
                if (tabSynthese?.infosGenerales?.conducteurTravaux) {
                    found = found || tabSynthese?.infosGenerales?.conducteurTravaux.mail.toLowerCase() === lastStatus.user.mail.toLowerCase();
                }
                if (tabSynthese?.infosGenerales?.directeurProgrammes) {
                    found = found || tabSynthese?.infosGenerales?.directeurProgrammes.mail.toLowerCase() === lastStatus.user.mail.toLowerCase();
                }
                if (tabSynthese?.infosGenerales?.directeurOperationnel) {
                    found = found || tabSynthese?.infosGenerales?.directeurOperationnel.mail.toLowerCase() === lastStatus.user.mail.toLowerCase();
                }
                if (tabSynthese?.infosGenerales?.responsableTechnique) {
                    found = found || tabSynthese?.infosGenerales?.responsableTechnique.mail.toLowerCase() === lastStatus.user.mail.toLowerCase();
                }
                return found;

            }
            return false;
        }
        return false;
    }

    /**
    * Metier
    **/

    const getReferentiels = (refType: string) => {
        API.getReferentiels(refType, refType === AutocontroleIntervenants.responsablesProgramme ?
            tabSynthese.infosGenerales.regroupementResp : tabSynthese.infosGenerales.filiale.split(" ")[0].replace(/^0+/, ''))
            .then(function (response: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType, response.data);
                let refList: FicheAutocontroleIntervenant[] = response.data.map((item: any) => {
                    let tabInfosIntervant: FicheAutocontroleIntervenant = {
                        nom: item.prenom.toUpperCase() + " " + item.nom.toUpperCase(),
                        mail: item.mail,
                        isInterne: (item.typeInterneExterne === 'I' || item.typeInterneExterne === ''),
                        id_gpi: item.id_gpi
                    }
                    return tabInfosIntervant;
                });

                refList = IntervenantUtils.removeDuplicate(refList);

                switch (refType) {
                    case AutocontroleIntervenants.chargesMissionQualite:
                        intervenantsState.chargesMissionQualite = refList;
                        break;
                    case AutocontroleIntervenants.responsablesProgramme:
                        intervenantsState.responsablesProgramme = refList;
                        break;
                    case AutocontroleIntervenants.responsablesTechnique:
                        intervenantsState.responsablesTechnique = refList;
                        break;
                    case AutocontroleIntervenants.conducteursTravaux:
                        intervenantsState.conducteursTravaux = refList;
                        break;
                }
                updateIntervenantsState(intervenantsState)
            }).catch(function (error: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType, error);
            });
    }

    const getIntervenants = () => {
        if (!intervenantsState.responsablesTechnique) {
            getReferentiels(AutocontroleIntervenants.responsablesTechnique);
        }

        if (!intervenantsState.responsablesProgramme || intervenantsState.responsablesProgramme.length === 0) {
            getReferentiels(AutocontroleIntervenants.responsablesProgramme);
        }

        if (!intervenantsState.conducteursTravaux || intervenantsState.conducteursTravaux.length === 0) {
            getReferentiels(AutocontroleIntervenants.conducteursTravaux);
        }

        if (!intervenantsState.chargesMissionQualite || (intervenantsState.chargesMissionQualite as FicheAutocontroleIntervenant[]).length === 0) {
            getReferentiels(AutocontroleIntervenants.chargesMissionQualite);
        }
    }

    const handlePopEvent = (e: any) => {
        localStorage.setItem('popListenerAdded', "true");
        e.preventDefault();
        window.history.pushState(null, document.title, window.location.href);

        if (localStorage.getItem('showPopIn') === 'true') {
            setOpen(true);
        } else {
            history.push(REDIRECT_HOME_ACQ);
        }

    }


    const handleBackNavigatorEvent = () => {
        if (!(localStorage.getItem('popListenerAdded') === 'true')) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handlePopEvent);
        }

    }

    React.useEffect(() => {
        getIntervenants();
        localStorage.setItem('showPopIn', "false");
        localStorage.setItem('popListenerAdded', "false");
        handleBackNavigatorEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveDateLimite = (critere: FicheAutoControleCritere) => {
        const dateUnformated = critere.value as number
        tabSynthese.infosGenerales.dateLimite = new Date(dateUnformated).getTime();
        setIsDateSaved(false);
        setIsSaved(false)
        updateTabSyntheseState(tabSynthese);
        localStorage.setItem('showPopIn', "true");
    }

    const saveReferentiel = (critere: FicheAutoControleCritere) => {
        switch (critere.options) {
            case AutocontroleIntervenants.responsablesProgramme:
                const rprFound = (intervenantsState.responsablesProgramme as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabSynthese.infosGenerales.responsableProgramme = rprFound ? rprFound : emptyTabInfosIntervenant;
                break;
            case AutocontroleIntervenants.chargesMissionQualite:
                const cmqFound = (intervenantsState.chargesMissionQualite as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabSynthese.infosGenerales.CMQ = cmqFound ? cmqFound : emptyTabInfosIntervenant;
                break;
            case AutocontroleIntervenants.responsablesTechnique:
                const rteFound = (intervenantsState.responsablesTechnique as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabSynthese.infosGenerales.responsableTechnique = rteFound ? rteFound : emptyTabInfosIntervenant;
                break;
            case AutocontroleIntervenants.conducteursTravaux:
                const ctrFound = (intervenantsState.conducteursTravaux as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabSynthese.infosGenerales.conducteurTravaux = ctrFound ? ctrFound : emptyTabInfosIntervenant;
                break;
        }
        setIsIntervenantsSaved(false);
        setIsSaved(false);
        updateTabSyntheseState(tabSynthese);
        localStorage.setItem('showPopIn', "true");
    }

    const quitParameters = () => {
        history.push(REDIRECT_HOME_ACQ);
        initGoBackState();
    }



    return (
        <>
            <div className="synthese-cmq">
                <div>
                    <div className="intervenants">
                        <SelectAutocontrole critere={getSelectCritere(tabSynthese?.infosGenerales?.CMQ, AutocontroleIntervenants.chargesMissionQualite)} updateCritere={saveReferentiel} />
                        <SelectAutocontrole critere={getSelectCritere(tabSynthese?.infosGenerales?.responsableProgramme, AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentiel} />
                        <SelectAutocontrole critere={getSelectCritere(tabSynthese?.infosGenerales?.conducteurTravaux, AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentiel} />
                        <SelectAutocontrole critere={getSelectCritere(tabSynthese?.infosGenerales?.responsableTechnique, AutocontroleIntervenants.responsablesTechnique)} updateCritere={saveReferentiel} />
                    </div>
                    {!hasUserTerrainValidated(data) && isDateValide(getDateValid()) ? buildInfo("Utilisateur ayant validé l'autocontrôle :", getUserValidated(data)) : ''}
                    {isDateValide(DateUtils.getDateMajForStatut(data, AUTOCONTROLE_STATUT.OUVERT)) ?
                        buildInfo("Date d'ouverture de l'autocontrôle :", DateUtils.dateBackToDateFront(DateUtils.getDateMajForStatut(data, AUTOCONTROLE_STATUT.OUVERT))) : ''
                    }
                    <div className="date-saisie">
                        <DateAutocontrole critere={getDateCritere(tabSynthese?.infosGenerales?.dateLimite)} updateCritere={saveDateLimite} disablePast={true} />
                        {
                            MessageDeadline(tabSynthese?.statut, tabSynthese?.infosGenerales?.dateLimite)
                        }
                    </div>
                    {isDateValide(getDateValid()) ?
                        buildInfo("Date de validation de l'autocontrôle :", DateUtils.dateBackToDateFront(DateUtils.getDateMajForStatut(data, AUTOCONTROLE_STATUT.FICHE_COMPLETEE))) : ''
                    }
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <span>Êtes vous sûr de vouloir abandonner votre saisie ? <br /> Les données saisies seront perdues.</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => quitParameters()} color="primary">
                            OUI
            </Button>
                        <Button onClick={() => setOpen(false)} color="primary">
                            NON
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
const mapStateToProps = ({ tabSyntheseState, intervenantsState, ficheAutocontrole }: ApplicationState) => ({
    tabSynthese: tabSyntheseState.data,
    intervenantsState: intervenantsState,
    isSaved: tabSyntheseState.isSaved,
    setIsIntervenantsSaved: tabSyntheseState.isIntervenantsSaved,
    setIsDateSaved: tabSyntheseState.isDateSaved,
    data: ficheAutocontrole.data
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISSAVED, payload: params }),
        setIsDateSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISDATESAVED, payload: params }),
        setIsIntervenantsSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISINTERVENANTSSAVED, payload: params }),
        updateTabSyntheseState: (params: TabSyntheseState) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: params }),
        updateIntervenantsState: (params: IntervenantsState) => dispatch({ type: IntervenantsAutocontroleTypes.UPDATE_STATE, payload: params }),
        initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE })
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SyntheseTab);