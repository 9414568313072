import { Reducer } from "redux";
import { VisiteQualiteState, VisiteQualiteTypes } from "../types/adminVisiteQualite.Types";
import { emptyVisiteQualite } from "../../utils/constantes/emptyObjects";
import { TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L } from "../../utils/constantes/contantes";

export const initialState: VisiteQualiteState = {
    responsablesProgramme: [],
    conducteursTravaux: [],
    chargesMissionQualite: [],
    visiteQualiteL4: emptyVisiteQualite(TYPE_AUTOCONTROLE_L4),
    visiteQualiteL15: emptyVisiteQualite(TYPE_AUTOCONTROLE_L15),
    visiteQualiteL: emptyVisiteQualite(TYPE_AUTOCONTROLE_L)
}

const reducer: Reducer<VisiteQualiteState> = (state = initialState, action = { type: '', payload: '' }) => {
    switch (action.type) {
        case VisiteQualiteTypes.UPDATE_STATE: {
            let copy = JSON.parse(JSON.stringify(state));
            copy = action.payload;
            return { ...copy }
        }
        case VisiteQualiteTypes.RESET_STATE: {
            let copy = JSON.parse(JSON.stringify(initialState));
            return { ...copy };
        }
        default: {
            return state;
        }
    }
}

export { reducer as VisiteQualiteReducer };