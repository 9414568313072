import React from "react";
import HomePageSearch from "../../components/homePage/HomePageSearch";
import "./HomePage.css";

const HomePage: React.FC<{}> = () => {
  
  /**
   * Display
   */
  return (
      <div>
        <div className='card-content'>
          <HomePageSearch/>
        </div>
      </div>
  );
};

export default HomePage;
