import React, { useEffect, useState } from "react";
import { TabsStyled, TabVerticalStyled } from "../../styles/CustomTabPanel.style";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { CustomPanelProps } from "../../components/CustomMenu/CustomPanel";
import { Backdrop, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputBase, makeStyles, Slide, Theme } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ImageSearch from '../../assets/search.svg';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { ApplicationState } from "../../store/store/store";
import { AUTOCONTROLE_STATUT, Filiale, GeneralInformationsState, GeneralInformationsTypes, Programme, ReferentielFront, TrancheTravaux, TrancheTravauxLiee } from "../../store/types/generalInformations.Types";
import { connect } from "react-redux";
import DateUtils from "../../utils/DateUtils";
import { FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutocontroleTab } from "../../store/types/FicheAutocontrole.Types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { HomePageState, HomePageTypes, infosCotation } from "../../store/types/homePage.Types";
import { API } from "../../utils/callAPI";
import { emptyFiliale, emptyProgramme, emptyReferentielFront, emptyTrancheTravaux, getEmptyCheckboxCritere } from "../../utils/constantes/emptyObjects";
import { ReferentielUtils } from "../../utils/ReferentielUtils";
import HomePageResult from "./HomePageResult";
import { generalInformationsToCreateAutocontroleParams, homePageResultToGeneralInformations, infosCotationToGeneralInformations, tabInfosToGeneralInformations } from "../../utils/mappers/generalInformationsMapper";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useHistory } from "react-router-dom";
import { REDIRECT_INTERDIT, REDIRECT_PARAMETRES, SEVERITY, TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L15_API, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L4_API } from "../../utils/constantes/contantes";
import { getUserRole, getUserFiliales } from "../../utils/AccessToken";
import { userRoles } from "../../store/types/user.Types";
import { REDIRECT_FICHE } from "../../utils/constantes/contantes";
import { TransitionProps } from "@material-ui/core/transitions";
import { TranchesTravauxToTranchesTravauxLiées } from "../../utils/mappers/trancheTravauxMapper";
import CheckBoxAutocontrole from "../../components/Criteres/TypesCriteres/CheckboxAutocontrole"
import ProgressBarService from "../../utils/progressBar.service";
import { IntervenantsAutocontroleTypes, IntervenantsState } from "../../store/types/intervenants.Types";
import { CurrentStateActions } from "../../store/types/CurrentState.Types";
import { HelperTypeEnum } from "../Helper/HelperTypeEnum";
import Helper from "../Helper/Helper";
import { MessagesAlertState, MessagesAlertTypes } from "../../store/types/MessagesAlert.Types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);

interface PropsFromDispatch {
  data: FicheAutocontrole,
  generalInformations: GeneralInformationsState,
  intervenantsState: IntervenantsState;
  resetIntervenantsState: Function;
  fetchData: Function,
  setGeneralInformations: Function,
  homePageState: HomePageState,
  updateHomePageState: Function,
  updateStatus: Function,
  updateCurrentPage: Function,
  updateCurrentGroup: Function,
  updateIsTabInfos: Function,
  updateMessageSnackBar: Function
}

const HomePageSearch: React.FC<PropsFromDispatch> = ({ updateIsTabInfos, updateCurrentPage, resetIntervenantsState, intervenantsState, data, generalInformations, fetchData, setGeneralInformations, homePageState, updateHomePageState, updateStatus, updateMessageSnackBar, updateCurrentGroup }) => {

  /**
   * Constantes et state :
   */
  const history = useHistory();

  const [currentTab, setcurrentTab] = useState(-1);
  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [, setOpenError] = React.useState(false);
  const [, setOpenAlert] = React.useState(false);
  const [disableFiliale, setDisableFiliale] = React.useState(false);
  const [openDialogMultiTT, setOpenDialogMultiTT] = React.useState(false);
  const [openDialogMonoTT, setOpenDialogMonoTT] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState([] as string[]);
  const [tranchesALier, setTranchesALier] = React.useState([] as TrancheTravauxLiee[]);
  const [existingOtherAcOpen, setExistingOtherAcOpen] = React.useState(false);

  const choicesLabels = ['Choix de la filiale', 'Choix du programme', 'Choix de la tranche travaux'];

  if (data && !data.statut) {
    updateStatus(AUTOCONTROLE_STATUT.FERME);
  }

  const classes = useStyles();

  /**
   * Apis:
   */
  const getAutocontroles = () => {
    setLoading(true);
    if (!generalInformations?.trancheTravaux?.id_referentiel || !generalInformations.typeAutocontrole) {
      setLoading(false);
      return;
    }
    const type = getTypeAutocontroleHomePageCall(generalInformations.typeAutocontrole)
    API.getAutocontrole(generalInformations.trancheTravaux?.id, type as string)
      .then((response) => {
        console.log("Réponse de /tranchetravaux/" + generalInformations.trancheTravaux?.id + "/type/" + type, response.data);
        let autocontrole: FicheAutocontrole = response.data?.autocontrole;
        setExistingOtherAcOpen(response.data?.otherAutocontroleOpened)
        fetchData(autocontrole)
        if (!!autocontrole) {
          generalInformations = tabInfosToGeneralInformations(generalInformations, autocontrole.tabInfos);
          setLoading(false);
        } else {
          API.getInfosCotationAPI(generalInformations.trancheTravaux.id_referentiel + '$' + generalInformations.typeAutocontrole)
            .then((response) => {
              generalInformations.dateLivraison = generalInformations.trancheTravaux.dateLivraisonPrevue;
              generalInformations.dateLimite = DateUtils.getDateLimiteFromDateLivraison(generalInformations.dateLivraison, generalInformations.typeAutocontrole);
              let infosCotation: infosCotation = response.data;
              generalInformations = infosCotationToGeneralInformations(infosCotation, generalInformations);
              setGeneralInformations(generalInformations);
              setLoading(false);
            })
            .catch(() => {
              setGeneralInformations(generalInformations);
              setLoading(false);
            })
        }
        generalInformations.isDisplayed = true;
        setGeneralInformations(generalInformations)
      }).catch(function(error: any) {
        console.log("Réponse de /tranchetravaux/" + generalInformations.trancheTravaux?.id + "/type/" + type, error);
        fetchData(null);
        setExistingOtherAcOpen(false)
        setLoading(false);
      });
}

  const setFiliale = (id: string) => {
    const filiale = homePageState.filiales.find((filiale: Filiale) => filiale.id_referentiel === id) as Filiale;
    generalInformations.filiale = filiale
    generalInformations.programme = emptyProgramme;
    generalInformations.trancheTravaux = emptyTrancheTravaux;
    homePageState.programmes = []
    homePageState.tranchesTravaux = []
  }

  /**
   * Récupère le type formatté pour l'appel de récupération d'un AC
   */
  const getTypeAutocontroleHomePageCall = (type: string) => {
    if (type === TYPE_AUTOCONTROLE_L4) {
      return TYPE_AUTOCONTROLE_L4_API + "&" + TYPE_AUTOCONTROLE_L15_API
    } else if (type === TYPE_AUTOCONTROLE_L15) {
      return TYPE_AUTOCONTROLE_L15_API + "&" + TYPE_AUTOCONTROLE_L4_API
    } else {
      return null
    }
  }


  /**
   * Récupère les tranches travaux qui sont à +/- X jours de la date de 
   * livraison GPI de la tranche travaux passée en paramètre
   * @param nbJours Le nombre de jours 
   * @param tTSelected La tranche travaux 
   */
  const getTtsLessThanXDaysFromTt = (nbJours: number, tTSelected: TrancheTravaux) => {
    const dateLivraisonGPI = tTSelected.dateLivraisonPrevue;
    return homePageState.tranchesTravaux.filter(tt => (tt.id_referentiel !== tTSelected.id_referentiel) &&
      DateUtils.isBetween(tt.dateLivraisonPrevue, DateUtils.removeDays(dateLivraisonGPI, nbJours),
        DateUtils.addDays(dateLivraisonGPI, nbJours)))
  }

  const getFilliales = () => {
    setLoading(true)
    API.getFilialesAPI()
      .then(function(response: any) {

        const role = getUserRole();

        console.log("Réponse de /referentiels/filiales", response.data);
        if (role.toLowerCase() === userRoles.terrain.toLowerCase()) {
          const userFiliales = getUserFiliales().map((i) => Number(i));
          homePageState.filiales = response.data.filter((item: { codeSociete: string; }) => userFiliales.includes(parseInt(item.codeSociete, 10)));

          if (userFiliales.length === 1) {
            setFiliale(homePageState.filiales[0].id_referentiel);
            setDisableFiliale(true);
          } else {
            setDisableFiliale(false);
          }
        } else {
          setDisableFiliale(false);
          homePageState.filiales = response.data;
        }

        if (!homePageState.filiales || homePageState.filiales.length === 0) {
          setDisableFiliale(false);
          homePageState.filiales = [emptyFiliale]
        }
        updateHomePageState(homePageState)
        setLoading(false);
      })
      .catch(function(error: any) {
        console.log("Réponse de /referentiels/filiales", error);
        homePageState.filiales = [emptyFiliale];
        updateHomePageState(homePageState);
        setLoading(false)
      });
  }

  useEffect(() => {
    if (!loading && (!homePageState.filiales || (homePageState.filiales && homePageState.filiales.length === 0)
      || (homePageState.filiales && homePageState.filiales[0] && JSON.stringify(homePageState.filiales[0]) === JSON.stringify(emptyFiliale)))) {
      getFilliales();
    }

    if(!!homePageState.filiales && homePageState.filiales.length === 1){
      setDisableFiliale(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgrammes = () => {
    setLoading(true)
    API.getProgrammesAPI(generalInformations.filiale?.id)
      .then(function(response: any) {
        console.log("Réponse de /referentiels/filiales/" + generalInformations.filiale?.id + "/programmes", response.data);
        homePageState.programmes = response.data;
        if (homePageState.programmes.length === 0) {
          homePageState.programmes = [emptyProgramme]
        }
        updateHomePageState(homePageState)
        setLoading(false);
      })
      .catch(function(error: any) {
        console.log("Réponse de /referentiels/filiales/" + generalInformations.filiale?.id + "/programmes", error);
        homePageState.programmes = [emptyProgramme];
        updateHomePageState(homePageState);
        setLoading(false);
      });
  }

  const getTranchesTravaux = () => {
    setLoading(true)
    API.getTranchesTravauxAPI(generalInformations.filiale?.id, generalInformations.programme?.id)
      .then(function(response: any) {
        console.log("Réponse de /referentiels/filiales/" + generalInformations.filiale?.id + "/programmes/" + generalInformations.programme?.id + "/tranchetravaux", response.data);
        homePageState.tranchesTravaux = response.data;
        if (homePageState.tranchesTravaux.length === 0) {
          homePageState.tranchesTravaux = [emptyTrancheTravaux]
        }
        updateHomePageState(homePageState)
        setLoading(false);
      }).catch(function(error: any) {
        console.log("Réponse de /referentiels/filiales/" + generalInformations.filiale?.id + "/programmes/" + generalInformations.programme?.id + "/tranchetravaux", error);
        homePageState.tranchesTravaux = [emptyTrancheTravaux];
        updateHomePageState(homePageState);
        setLoading(false);
      });
  }

  /**
   * Metier :
   */

  const getStatusMAJDate = () => {
    return data?.statutHisto && data?.statutHisto?.length > -1 ? data?.statutHisto[data.statutHisto?.length - 1].dateMAJ : 0;
  }

  const handleChangeTypeAutocontrole = (value: string) => {
    generalInformations = homePageResultToGeneralInformations(generalInformations, value)
    getAutocontroles();
  }

  const isSelected = (index: number) => {
    return currentTab === (index);
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSearchTxt('');
    if (currentTab > -1 && currentTab === newValue) {
      setcurrentTab(-1);
    } else {
      setcurrentTab(newValue);
    }

  };

  const disableTab = (index: number) => {
    switch (index) {
      case 0:
        return disableFiliale;
      case 1:
        return generalInformations.filiale.id_referentiel === '';
      case 2:
        return generalInformations.filiale.id_referentiel === '' || generalInformations.programme.id_referentiel === '';
    }
  }

  const updateValue = (value: ReferentielFront) => {

    generalInformations.typeAutocontrole = "";
    generalInformations.isDisplayed = false;

    switch (currentTab) {
      case 0:
        resetIntervenantsState()
        const filiale = homePageState.filiales.find((filiale: Filiale) => filiale.id_referentiel === value.id) as Filiale;
        generalInformations.filiale = filiale
        generalInformations.programme = emptyProgramme;
        generalInformations.trancheTravaux = emptyTrancheTravaux;
        homePageState.programmes = []
        homePageState.tranchesTravaux = []
        break;
      case 1:
        resetIntervenantsState()
        const programme = homePageState.programmes.find((programme: Programme) => programme.id_referentiel === value.id) as Programme;
        generalInformations.programme = programme;
        generalInformations.trancheTravaux = emptyTrancheTravaux;
        homePageState.tranchesTravaux = []
        break;
      case 2:
        generalInformations.trancheTravaux = homePageState.tranchesTravaux.find((trancheTravaux: TrancheTravaux) => trancheTravaux.id_referentiel === value.id) as TrancheTravaux;
        generalInformations.dateLivraison = generalInformations.trancheTravaux.dateLivraisonPrevue;
        generalInformations.nbLots = generalInformations.trancheTravaux.nbLots;
        break;
    }

    setcurrentTab(-1);
    updateHomePageState(homePageState)
    setGeneralInformations(generalInformations);
  }

  const getTabLabel = (index: number) => {
    switch (index) {
      case 0:
        return generalInformations.filiale.id_referentiel !== '' ? ReferentielUtils.getFilialeLibelle(generalInformations.filiale) : null;
      case 1:
        return generalInformations.programme.id_referentiel !== '' ? ReferentielUtils.getProgrammeLibelle(generalInformations.programme) : null;
      case 2:
        return generalInformations.trancheTravaux.id_referentiel !== '' ? ReferentielUtils.getTrancheTravauxLibelle(generalInformations.trancheTravaux) : null;
    }
  }

  const updateSearchTxt = (e: any) => {
    setSearchTxt(e.target.value);
  }

  const sortReferentiels = (referentiels: ReferentielFront[]) => {
    return referentiels.sort((a: ReferentielFront, b: ReferentielFront) => a.value.localeCompare(b.value))
  }

  const getListItems = () => {
    let referentielsFront: ReferentielFront[] = [];

    switch (currentTab) {
      case 0:
        if (homePageState.filiales.length === 0 && !loading) {
          getFilliales();
        }
        referentielsFront = ReferentielUtils.filialesToReferentielsFront(homePageState.filiales);
        break;
      case 1:
        if (homePageState.programmes.length === 0 && !loading) {
          getProgrammes();
        }
        referentielsFront = ReferentielUtils.programmesToReferentielsFront(homePageState.programmes);
        break;
      case 2:
        if (homePageState.tranchesTravaux.length === 0 && !loading) {
          getTranchesTravaux();
        }
        referentielsFront = ReferentielUtils.tranchesTravauxsToReferentielsFront(homePageState.tranchesTravaux);
        break;
    }

    let listItemsFiltered = referentielsFront.filter((item: ReferentielFront) => item.value.toLowerCase().includes(searchTxt.toLowerCase()));

    if (listItemsFiltered.length === 0 || listItemsFiltered[0] === emptyReferentielFront) {
      return (<div>{loading ? "Recherche en cours..." : "Aucun résultat trouvé"}</div>)
    } else {
      listItemsFiltered = sortReferentiels(listItemsFiltered)
      return listItemsFiltered.map((item: any, index: any) => (
        <Button key={index} variant="outlined" className="choice-elt" onClick={() => updateValue(item)}>{item.value} </Button>
      ))
    }

  }

  const backToMenu = () => {
    setcurrentTab(-1);
  }

  /**
   * Display :
   */
  const getTypeAutocontroleButtonClassName = (typeAutocontrole: string) => {
    let className = "btn-radio btn-type-autocontrole";
    if (typeAutocontrolButtonIsDisabled()) {
      className += " desactivate";
    }
    else {
      className += (generalInformations.typeAutocontrole === typeAutocontrole ? " active" : "");
    }
    return className
  }

  const getVerticalTabClassName = (index: number) => {
    let className = 'vertical-tab';

    if (isSelected(index)) {
      className += ' vertical-tab-selected'
    } else {
      className += ' vertical-tab-default';
    }

    return className
  }

  const getArrowClassName = (index: number) => {
    let className = ' vertical-tab-arrow'

    if (isSelected(index)) {
      className += ' vertical-tab-arrow-selected'
    }

    return className;
  }

  const typeAutocontrolButtonIsDisabled = () => {
    return generalInformations.trancheTravaux.id === '';
  }

  const goToParametres = () => {
    history.push(REDIRECT_PARAMETRES);
  }

  /**
   * Redirections
   */
  const goToControle = () => {
    updateCurrentPage(0)
    updateCurrentGroup(-1)
    updateIsTabInfos(true);
    history.push(REDIRECT_FICHE);
  }

  /**
   * Metier
   */

  const autoriserAutocontrole = () => {
    setLoading(true)
    console.log(getTtsLessThanXDaysFromTt(30, generalInformations?.trancheTravaux))
    if (getTtsLessThanXDaysFromTt(30, generalInformations?.trancheTravaux).length !== 0) {
      const params = {
        codeProgramme: generalInformations.programme.codeProgramme,
        codeFiliale: generalInformations.filiale.codeSociete,
        tranchesTravaux: TranchesTravauxToTranchesTravauxLiées(getTtsLessThanXDaysFromTt(30, generalInformations?.trancheTravaux)),
        typeAutocontrole: generalInformations.typeAutocontrole
      }
      API.getTranchesTravauxValidForFilialeAndProgramme(params)
        .then((response) => {
          setLoading(false)
          if (response.data && response.data.length > 0) {
            setTranchesALier(response.data)
            setOpenDialogMultiTT(true)
            setOpenDialogMonoTT(false)
          } else {
            setOpenDialogMultiTT(false)
            setOpenDialogMonoTT(true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      setLoading(false)
      setOpenDialogMultiTT(false)
      setOpenDialogMonoTT(true)
    }
  }

  const handleSwitchAutocontrole = () => {
    setOpenDialogMultiTT(false)
    setOpenDialogMonoTT(false)
    if (!data || (!!data && checkboxValue.length !== 0)) {
      setLoading(true);
      let params = generalInformationsToCreateAutocontroleParams(generalInformations);
      API.createAutocontrole(params)
        .then((response) => {
          const autocontroleCreated = response.data as FicheAutocontrole;
          console.log("Autocontrole créé");
          fetchData(autocontroleCreated);

          setExistingOtherAcOpen(false);
          updateStatut(response?.data);

        }).catch(function(error: any) {
          console.log("Problème lors de la création de l'autocontrole", error)
        })
    } else {
      updateStatut(data);
    }
  }

  const canBeOpen = () => {
    if (!data) {
      return (!DateUtils.isBeforeNow(generalInformations.dateLimite) && generalInformations.chargeMissionQualite.nom !== '')
        && ((generalInformations.responsableProgramme.nom !== '')
          || (generalInformations.conducteurTravaux.nom !== '' && generalInformations.conducteurTravaux.isInterne)
          || (generalInformations.responsableTechnique.nom !== '' && generalInformations.responsableTechnique.isInterne))
    } else {
      return (!DateUtils.isBeforeNow(data.tabInfos.dateLimite) && data.tabInfos.CMQ.nom !== '')
        && ((data.tabInfos.responsableProgramme.nom !== '')
          || (data.tabInfos.responsableTechnique.nom !== '' && data.tabInfos.responsableTechnique.isInterne)
          || (data.tabInfos.conducteurTravaux.nom !== '' && data.tabInfos.conducteurTravaux.isInterne))
    }
  }

  const canGoToAutocontrole = () => {

    if (!!data && (data.statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE || data.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE)) {
      return true;
    } else {
      if ((!!data && !DateUtils.isBeforeNow(data.tabInfos.dateLimite))) {
        if (data.statut === AUTOCONTROLE_STATUT.INACCESSIBLE) {
          return false;
        }
        return data.statut !== AUTOCONTROLE_STATUT.FERME;
      } else {
        return false;
      }
    }
  }

  const getCMQButton = () => {
    if (!data || (!!data && data.statut === AUTOCONTROLE_STATUT.FERME)) {
      return (
        <Button className={"btn-radio btn-go-autocontrole " + (canBeOpen() ? "active" : "desactivate")} key="btn-autocontrole" variant="outlined"
          onClick={() => autoriserAutocontrole()} disabled={!canBeOpen()}>
          Autoriser l'autocontrôle
        </Button>
      )
    }
  }

  const getCMQWarningOuverture = () => {
    const otherAC = generalInformations.typeAutocontrole === TYPE_AUTOCONTROLE_L4 ? TYPE_AUTOCONTROLE_L15 : TYPE_AUTOCONTROLE_L4
    const title = "Cet autocontrôle est indisponible car un autocontrôle " + otherAC + " est déjà en cours de réalisation.";
    return (<div className="mx-16"><Helper type={HelperTypeEnum.INFO} title={title} /></div>)

  }

  const getTerrainWarningOuverture = () => {
    const otherAC = generalInformations.typeAutocontrole === TYPE_AUTOCONTROLE_L4 ? TYPE_AUTOCONTROLE_L15 : TYPE_AUTOCONTROLE_L4
    const title = "Cet autocontrôle est indisponible car un autocontrôle " + otherAC + " est déjà en cours de réalisation.";
    return (<div className="mx-16"><Helper type={HelperTypeEnum.INFO} title={title} /></div>)

  }

  const getCMQCotationButton = () => {
    if (!!data
      && (data.statut !== AUTOCONTROLE_STATUT.FERME)
      && (data.statut !== AUTOCONTROLE_STATUT.INACCESSIBLE)
      && !(data.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE && DateUtils.hasMoreThanAnYear(getStatusMAJDate()))
      && (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase())) {
      return (
        <Button className="btn-radio btn-go-autocontrole active" key="btn-autocontrole" variant="outlined"
          onClick={() => goToControle()}>
          Accéder à la cotation
        </Button>
      )
    }
  }

  const getTerrainButton = () => {
    if (!data || data?.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE || data?.statut === AUTOCONTROLE_STATUT.INACCESSIBLE) {
      return '';
    }
    return (
      <Button className={"btn-radio btn-go-autocontrole " + (canGoToAutocontrole() ? "active" : "desactivate")} key="btn-autocontrole" variant="outlined"
        onClick={() => goToControle()} disabled={!canGoToAutocontrole()}>
        {((data && data.statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE) || (data && data.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE)) ?
          <>Consulter l'autocontrôle</> : <>Effectuer l'autocontrôle</>
        }
      </Button>)
  }

  const getAutocontroleButton = () => {
    if (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) {
      if (existingOtherAcOpen && data?.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE) {
        return getCMQWarningOuverture()
      } else {
        return getCMQButton()
      }
    } else if (getUserRole().toLowerCase() === userRoles.terrain.toLowerCase()) {
      if (existingOtherAcOpen && data?.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE) {
        return getTerrainWarningOuverture()
      } else {
        return getTerrainButton();
      }
    } else {
      history.push(REDIRECT_INTERDIT);
    }
  }

  /**
   * API
   */
  const makeUpdate = (myData: FicheAutocontrole) => {
    let request = {
      id_pk: myData.id_pk as string,
      id_sk: myData.id_sk as string,
      statut: (myData.statut === AUTOCONTROLE_STATUT.FERME) ? AUTOCONTROLE_STATUT.OUVERT : AUTOCONTROLE_STATUT.FERME
    }

    API.updateAutocontrole(request, ProgressBarService.getComplitionPercent(myData), request.statut)
      .then((resp:any) => {
        const severity:string = resp?.data?.severity;
        const type = getTypeAutocontroleHomePageCall(generalInformations.typeAutocontrole)
        API.getAutocontrole(generalInformations.trancheTravaux.id, type as string)
          .then((response) => {
            let autocontrole: FicheAutocontrole = response.data?.autocontrole;
            fetchData(autocontrole)
            setExistingOtherAcOpen(response.data?.otherAutocontroleOpened)
            generalInformations = tabInfosToGeneralInformations(generalInformations, autocontrole.tabInfos)
            setGeneralInformations(generalInformations)
            setLoading(false);
            setOpenAlert(true);
            if(!severity){  
              updateMessageSnackBar(getMessageAlertSnackbar());
            }
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }


  const getChoicesForCheckboxTranches = (tranches: TrancheTravauxLiee[]) => {
    let choices: string[] = []
    tranches.forEach(tranche => {
      choices.push(tranche.codeTranche + ' - ' + tranche.libelleTranche)
    });
    return choices.sort((a: string, b: string) => a.localeCompare(b))
  }

  const getCheckboxCritere = () => {
    let critere = getEmptyCheckboxCritere();
    critere.value = checkboxValue;
    critere.choices = getChoicesForCheckboxTranches(tranchesALier);
    return critere;
  }
  const saveChoice = (critere: FicheAutoControleCritere) => {
    setCheckboxValue(critere.value as string[])
    generalInformations.tranchesTravauxLiees = tranchesALier.filter(tranche => (critere.value as string[]).includes(tranche.codeTranche + ' - ' + tranche.libelleTranche))
  }



  const updateStatut = (myData: FicheAutocontrole) => {
    setLoading(true)
    if (!existingOtherAcOpen) {
      makeUpdate(myData);
    } else {
      // ce cas ne devrait jamais se produire mais sécurité
      setOpenError(true)
      setLoading(false)
    }
  }


  /**
   * A chaque redirection vers la HomePageSearch, on va recharger l'autocontrole
   */
  if ((history.action === 'PUSH' || history.action === 'POP') && generalInformations && !!getUserRole()) {
    handleChangeTypeAutocontrole(generalInformations.typeAutocontrole)
    history.replace({ state: {} })
  }

  const getMessageAlertSnackbar = () => {
    const messageState: MessagesAlertState = {
      messages: ["La fiche autocontrôle est prête à être renseignée !\nL'équipe chantier a été informée automatiquement par mail."],
      severity: SEVERITY.SUCCESS
    }
    return messageState;
  }

  return (
    <div className='card-content-group'>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={currentTab > -1 ? 'tab-bloc-group' : 'tab-bloc-page'} style={currentTab > -1 ? {} : { backgroundColor: '#F8F7F8' }}>
        <div className="tab-title home-bg" key='page-acceuil'>
          <span className='page-title' >Informations générales </span>
        </div>
        <TabsStyled variant="fullWidth" value={currentTab > -1 ? currentTab : 0} onChange={handleChange} orientation="vertical" >
          {choicesLabels.map((choix, index) =>
            <TabVerticalStyled disabled={disableTab(index)} style={disableTab(index) ? { backgroundColor: '#F1EEF1' } : { backgroundColor: 'white', textAlign: 'left' }}
              className={getVerticalTabClassName(index)} key={'CustomTabPanel-Group-' + index} label={
                <div className="customGroupMenu-tabVerticalStyled" style={disableTab(index) ? { color: "#A29AA2" } : {}}>
                  <div className='flex-row'> {getTabLabel(index) ? getTabLabel(index) : choix} </div>
                  <ArrowForwardIosIcon className={getArrowClassName(index)} />
                </div>}
              {...CustomPanelProps(index, "vertical")} />)}
        </TabsStyled>

        <div className="radio-bloc">
          <span className='page-title' >Types d'autocontrôle </span>
          <div className="radio-btn-div">
            <ButtonGroup size="large" color="primary" aria-label="outlined primary button group" className="radio-group-bloc">
              <Button className={getTypeAutocontroleButtonClassName("L-4")}
                onClick={() => handleChangeTypeAutocontrole('L-4')}
                disabled={typeAutocontrolButtonIsDisabled()}>L-4</Button>
              <Button className={getTypeAutocontroleButtonClassName("L-1,5")}
                onClick={() => handleChangeTypeAutocontrole('L-1,5')}
                disabled={typeAutocontrolButtonIsDisabled()}>L-1,5</Button>
            </ButtonGroup>
          </div>
          <HomePageResult />
        </div>
        <div>
          {generalInformations.isDisplayed ?
            <div className="bg-white">
              {(!data || (!!data && data.statut === AUTOCONTROLE_STATUT.FERME)) && !!getUserRole() ?
                <div className="margin-y-8">
                  <Button className="btn-radio btn-go-autocontrole" key="btn-autocontrole" variant="outlined" onClick={() => goToParametres()}> <SettingsOutlinedIcon />Paramétrer </Button>
                </div> : ''}
              <div className="margin-y-8">
                {getAutocontroleButton()}
              </div>
              <div className="margin-y-8">
                {getCMQCotationButton()}
              </div>
            </div>
            : <></>}
        </div>
      </div>
      <div className={currentTab > -1 ? 'tab-content-group' : 'customGroupMenu-tab-default'}>
        {currentTab > -1 ?
          <div className='display-mobile tab-mobile-menu customGroupMenu-tabMobile'>
            <div className="customGroupMenu-tabMobile-margin" >
              <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToMenu()} />
              <span className='tab-page-title customGroupMenu-tabMobile-title'>
                {getTabLabel(currentTab) ? getTabLabel(currentTab) : choicesLabels[currentTab]}
              </span>
            </div>
          </div> : ''}
        <Paper component="form" className="seach-form">
          <Button type="submit" className={"search-bar " + classes.iconButton} aria-label="search" disabled={true}>
            <img className="iconSearch" src={ImageSearch} alt="\" />
          </Button>
          <InputBase
            className={classes.input}
            placeholder="Rechercher..."
            value={searchTxt}
            onChange={updateSearchTxt}
            inputProps={{ 'aria-label': 'Rechercher...' }}
          />
        </Paper>
        <div className="fullWidth">
          {getListItems()}
        </div>
      </div>
      <Dialog
        open={openDialogMultiTT}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Voulez-vous ouvrir la fiche autocontrôle à l'équipe chantier ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span>Si oui, plusieurs tranches travaux de ce programme peuvent être livrées sur la même période.</span>
            <br/>
            <span>Veuillez sélectionner les tranches travaux à évaluer dans cette fiche autocontrôle :</span>
          </DialogContentText>
          <CheckBoxAutocontrole critere={getCheckboxCritere()} updateCritere={saveChoice} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSwitchAutocontrole()} color="primary" className='buttonDialog'>
            OUI
          </Button>
          <Button onClick={() => setOpenDialogMultiTT(false)} color="primary" className='buttonDialog'>
            NON
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogMonoTT}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Voulez-vous ouvrir la fiche autocontrôle à l'équipe chantier ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Celle-ci sera informée automatiquement par mail.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSwitchAutocontrole()} color="primary" className='buttonDialog'>
            OUI
          </Button>
          <Button onClick={() => setOpenDialogMonoTT(false)} color="primary" className='buttonDialog'>
            NON
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
};

const mapStateToProps = ({ ficheAutocontrole, generalInformations, homePageState, intervenantsState }: ApplicationState) => ({
  data: ficheAutocontrole.data,
  generalInformations: generalInformations,
  homePageState: homePageState,
  intervenantsState: intervenantsState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
    setGeneralInformations: (params: GeneralInformationsState) => dispatch({ type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params }),
    updateHomePageState: (params: HomePageState) => dispatch({ type: HomePageTypes.UPDATE_STATE, payload: params }),
    updateStatus: (data: FicheAutocontroleTab) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_STATUT, payload: data, currentPage: '' }),
    resetIntervenantsState: () => dispatch({ type: IntervenantsAutocontroleTypes.RESET_STATE }),
    updateCurrentPage: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_PAGE, params: params }),
    updateCurrentGroup: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_GROUP, params: params }),
    updateIsTabInfos: (params: boolean) => dispatch({ type: CurrentStateActions.IS_TABINFOS, params: params }),
    updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageSearch);
