import { MessagesAlertState } from "../../store/types/MessagesAlert.Types"

/**
 * Converti une erreur côté back en une erreur MessageAlert
 * @param response // reception de l'erreur côté back 
 * @returns un MessageAlertState
 */
export const messageBackToMessagesFront = (response: any) => {
    return {
        messages: response?.message ? [response.message] : ["Oups! Une erreur est survenue"],
        severity: response?.severity,
        duration: response?.duration,
        position: response?.position
    } as MessagesAlertState
}
