import { Reducer } from "redux";
import {FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutocontroleState} from "../types/FicheAutocontrole.Types";

export const initialState: FicheAutocontroleState={
    data: null as unknown as FicheAutocontrole,
    isSaved: true,
}

const reducer : Reducer<FicheAutocontroleState> = (state = initialState, action = {type:'', payload:'', currentPage: '', currentGroup: ''}) =>{
    switch(action.type){
        case FicheAutocontroleActionTypes.FETCH_DATA:{
            return {...state, data: action.payload}
        }
        case FicheAutocontroleActionTypes.UPDATE_TAB:{
            let copy = {...state};
            copy.data.tabs[action.currentPage] = action.payload;
            return {...state, data:{...copy.data}, isSaved:false};
        }
        case FicheAutocontroleActionTypes.UPDATE_TAB_INFOS:{
            let copy = {...state};
            copy.data.tabInfos = action.payload;
            return {...state, data:{...copy.data}, isSaved:false};
        }
        case FicheAutocontroleActionTypes.SET_ISSAVED:{
            let copy = {...state}
            copy.isSaved = action.payload as boolean
            return copy;
        }
        case FicheAutocontroleActionTypes.UPDATE_STATUT:{
            let copy = {...state};
            copy.data.statut = action.payload;
            return {...state, data:{...copy.data}, isSaved:false};
        }
        default:{
            return state;
        }
    }
};

export {reducer as FicheAutocontroleReducer};