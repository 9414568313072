import React from 'react';
import { LinearProgress, Tab } from "@material-ui/core";
import { TabsStyled } from '../../styles/CustomTabPanel.style';
import { CustomPanelProps, CustomPanel } from './CustomPanel';
import { ApplicationState } from '../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { FicheAutocontrole, FicheAutoControleGroup, FicheAutocontroleActionTypes, FicheAutocontroleTab, FicheAutocontroleTabInfos } from '../../store/types/FicheAutocontrole.Types';
import { CurrentState, CurrentStateActions } from '../../store/types/CurrentState.Types';
import ImageRecapitulatif from "../../assets/Recapitulatif.svg";
import ProgressBarService from '../../utils/progressBar.service';
import { API } from '../../utils/callAPI';
import StateService from '../../utils/state.service';
import { uploadToS3 } from '../../utils/upload.image.s3.service';
import { PictureState, PictureTypes, OldPictureTypes } from '../../store/types/pictures.Types';
import { TabSynthese, TabSyntheseStateActions } from '../../store/types/TabSynthese.Types';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { AUTOCONTROLE_STATUT, GeneralInformationsState, GeneralInformationsTypes } from '../../store/types/generalInformations.Types';
import { GoBackCurrentStateActions } from '../../store/types/GoBackState.Types';
import { tabInfosToGeneralInformations } from '../../utils/mappers/generalInformationsMapper';
import { REDIRECT_HOME_ACQ } from '../../utils/constantes/contantes';
import { MessagesAlertState, MessagesAlertTypes } from '../../store/types/MessagesAlert.Types';
import { useHistory } from 'react-router-dom';
import { MessageAutoControleInaccessible } from '../../utils/InformationMessage';

interface PropsFromState {
    headers: { title: string, iconeTab: string }[],
    tabs: JSX.Element[],
}

interface PropsFromDispatch {
    data: FicheAutocontrole,
    isSaved: boolean,
    currentState: CurrentState,
    updateCurrentPage: Function,
    updateCurrentGroup: Function,
    updateTab: Function,
    updateTabInfos: Function,
    updateIsTabInfos: Function,
    setIsSaved: Function,
    pictures: PictureState[],
    updateSynthese: Function,
    deletePicsFromStore: Function,
    fetchData: Function,
    generalInformations: GeneralInformationsState,
    goBackStateHasChanged: Function,
    oldPictures: Array<string>,
    deleteOldPicture: Function,
    setGeneralInformationsState: Function,
    updateMessageSnackBar: Function
}

type AllProps = PropsFromState & PropsFromDispatch;

export const getIcon = (isOngletActif: boolean, header: { title: string, iconeTab: string }) => {
    const classIcon = !isOngletActif ? 'tab-icons' : 'tab-icon-active';
    return <img
        className={classIcon}
        src={`${process.env.PUBLIC_URL}/static/media/${header.iconeTab}`}
        onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = ImageRecapitulatif; }}
        alt={header.title} />;
}

const isPageDesactivatedWithCriterias = (tabb: FicheAutocontroleTab) => {
    if (!tabb) {
        return false
    }
    let isCriteriasFoundInAGroup = false
    tabb.groups.forEach(group => {
        if (isGroupWithCriteriasFilled(group)) {
            isCriteriasFoundInAGroup = true
        }
    });
    return isCriteriasFoundInAGroup && !tabb.activated;
}

const isGroupWithCriteriasFilled = (group: FicheAutoControleGroup) => {
    return group.criterias.some(crit => ProgressBarService.isCriteraCompleted(crit));
}

export const getTabClass = (index: number, currentTab: number) => {
    return currentTab === index ? 'Mui-selected-tab' : 'Mui-not-slected-tab';
}

const CustomTabMenu: React.FC<AllProps> = ({ updateMessageSnackBar, setGeneralInformationsState, headers, tabs, updateCurrentPage, updateCurrentGroup, currentState, data, updateTab, updateTabInfos, updateIsTabInfos, isSaved, setIsSaved, pictures, updateSynthese, deletePicsFromStore, fetchData, generalInformations, goBackStateHasChanged, oldPictures, deleteOldPicture }) => {

    /**
     * Constantes, useState
     */
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [, setOpenDialogData] = React.useState({ isOpen: false, index: -1 });

    const getAutocontrole = (index: number) => {
        setLoading(true)
        API.getAutocontrole(generalInformations.trancheTravaux.id, generalInformations.typeAutocontrole)
            .then(function (response: any) {
                const ficheAutocontrole: FicheAutocontrole = response.data
                fetchData(ficheAutocontrole);
                updateSynthese(ficheAutocontrole.tabSynthese);
                setGeneralInformationsState(tabInfosToGeneralInformations(generalInformations, ficheAutocontrole.tabInfos));
                updateCurrentPage(index)
                updateCurrentGroup(-1)
                // Si le statut a été fermé entre temps par un CMQ, on redirige vers la page d'accueil. cas rare et spécial. 
                if (!ficheAutocontrole || ficheAutocontrole.statut === AUTOCONTROLE_STATUT.FERME) {
                    updateMessageSnackBar(MessageAutoControleInaccessible(''));
                    history.push(REDIRECT_HOME_ACQ);
                }
                setLoading(false);
            }).catch(function (error: any) {
                fetchData(null);
                setLoading(false);
                history.push(REDIRECT_HOME_ACQ);
            });
    }

    /**
     * API
     */
    const savePage = (newIndex: number) => {
        if (!isSaved) {
            setLoading(true);
            let tabToSave = StateService.resetDisabledCriteriasState(currentState.isTabInfos ? data.tabInfos : data.tabs[currentState.currentPage - 1])
            if (currentState.isTabInfos) {
                updateTabInfos(tabToSave)
            } else {
                updateTab(tabToSave)
            }
            API.updateAutocontrole(tabToSave, ProgressBarService.getComplitionPercent(data), data.statut)
                .then(() => {
                    getAutocontrole(newIndex);
                    if (pictures.length > 0) {
                        pictures.forEach(img => {
                            uploadToS3(img.name, img.type, img.pic).then(
                                () => {
                                    console.log("Image " + img.name + " sauvegardée !");
                                }
                            );
                        });
                        deletePicsFromStore();
                        if (oldPictures.length > 0) {
                            oldPictures.forEach(oldPic => {
                                API.deletePicFromS3(oldPic).then(() => {
                                    console.log("Image supprimée : " + oldPic);
                                    deleteOldPicture(oldPic);
                                }, err => {
                                    console.error(err);
                                });
                            });
                        }
                        updateCurrentPage(newIndex)
                    } else {
                        updateCurrentPage(newIndex)
                    }
                    console.log("Autocontrole sauvegardé");
                    setIsSaved(true);
                    setLoading(false);
                }).catch(() => {
                    console.log("Problème lors de la sauvegarde");
                    setIsSaved(true);
                    setLoading(false);
                });
        }
    }

    /**
     * Metier
     */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        //The tabInfo is in position 0 and it has only 1 group
        updateCurrentGroup((newValue === 0) ? 0 : -1)
        nextPage(newValue);
    };


    const nextPage = (newIndex: number) => {
        updateIsTabInfos(newIndex === 0);
        if (isSaved) {
            getAutocontrole(newIndex);
        } else {
            if (isPageDesactivatedWithCriterias(data.tabs[currentState.currentPage - 1])) {
                setOpenDialogData((prevState) => ({ ...prevState, isOpen: true, index: newIndex }));
            } else {
                savePage(newIndex);
                goBackStateHasChanged();
            }
        }
    };

    const up = () => {
        if (headers.length - 1 > currentState.currentPage) {
            nextPage(currentState.currentPage + 1);
        }
    }

    const down = () => {
        if (0 < currentState.currentPage) {
            nextPage(currentState.currentPage - 1);

        }
    }

    /**
     * Display
     */

    const createHeaders = () => {
        let headersTabs: JSX.Element[] = [];

        headers.forEach((header: { title: string, iconeTab: string }, index: number) => {
            headersTabs.push(<Tab className={getTabClass(index, currentState.currentPage)} key={'CustomTabPanel-page-'.concat(header.title)} icon={getIcon((index === currentState.currentPage), header)} label={header.title} {...CustomPanelProps(index, "simple")} />
            );
        });

        return headersTabs;
    }

    const createTabs = () => {
        let groups: JSX.Element[] = [];
        let index = 0;
        tabs.forEach((children: JSX.Element) => {
            groups.push(<CustomPanel key={'CustomTabPanel-TabPanel-'.concat(index.toString())} value={currentState.currentPage} index={index} tabStyle="simple">
                {children}
            </CustomPanel>)
            index++;
        });

        return groups;
    }

    const generateMobileControls = () => {

        if (currentState.currentGroup === -1) {
            return (
                <div className="bandeau-navigation-mobile">
                    {currentState.currentPage !== 0 ?
                        <div className="arrow-btn left-btn" onClick={() => down()}>
                            <span className="arrow arrow-bar is-left"></span>
                        </div>
                        : <div className="empty-arrow-btn"></div>}
                    <div className="title-tab">{headers[currentState.currentPage]?.title ? headers[currentState.currentPage].title : '?'}</div>

                    {currentState.currentPage !== (headers.length - 1) ?
                        <div className="arrow-btn right-btn" onClick={() => up()}>
                            <span className="arrow arrow-bar is-right"></span>
                        </div>
                        : <div className="empty-arrow-btn"></div>}
                </div>
            )
        }
    }

    return (
        <>
            <div className="completion-totale">
                <span className="completion-totale-libelle">Complétude totale :  {ProgressBarService.getComplitionPercent(data)} %</span>
                <LinearProgress className="completion-totale-progressbar" color="primary" variant="determinate" value={ProgressBarService.getComplitionPercent(data)} />
            </div>
            <div className='tab-bloc-page'>

                <TabsStyled className='tab-bar-tablette' variant="fullWidth" value={currentState.currentPage} onChange={handleChange} orientation="horizontal">
                    {createHeaders()}
                </TabsStyled>

                <div className='tab-bar-mobile'>
                    {generateMobileControls()}
                </div>
            </div>
            <div>
                {createTabs()}
            </div>
            <LoadingBackdrop loading={loading} />
        </>
    )

}

const mapStateToProps = ({ currentState, ficheAutocontrole, pictures, generalInformations, oldPictures }: ApplicationState) => ({
    currentState: currentState,
    data: ficheAutocontrole.data,
    isSaved: ficheAutocontrole.isSaved,
    pictures: pictures,
    generalInformations: generalInformations,
    oldPictures: oldPictures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateCurrentPage: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_PAGE, params: params }),
        updateCurrentGroup: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_GROUP, params: params }),
        updateTab: (data: FicheAutocontroleTab, currentPage: number) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: currentPage }),
        updateTabInfos: (data: FicheAutocontroleTabInfos) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB_INFOS, payload: data }),
        updateIsTabInfos: (params: boolean) => dispatch({ type: CurrentStateActions.IS_TABINFOS, params: params }),
        setIsSaved: (params: boolean) => dispatch({ type: FicheAutocontroleActionTypes.SET_ISSAVED, payload: params }),
        updateSynthese: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data }),
        deletePicsFromStore: () => dispatch({ type: PictureTypes.DELETE_ALL_PIC }),
        fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE }),
        deleteOldPicture: (name: string) => dispatch({ type: OldPictureTypes.DELETE_OLD_PIC, picName: name }),
        setGeneralInformationsState: (params: GeneralInformationsState) => dispatch({ type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params }),
        updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTabMenu);