import { datadogLogs } from "@datadog/browser-logs";
import { GetEnv } from "./Env";




export default class Logger {

   static init = () => {
      datadogLogs.init({
         clientToken: GetEnv('REACT_APP_CLIENT_TOKEN_DATADOG') ? GetEnv('REACT_APP_CLIENT_TOKEN_DATADOG') : "",
         site: GetEnv('REACT_APP_SITE_DATADOG'),
         forwardErrorsToLogs: (GetEnv('REACT_APP_ENV_STAGE') !== 'local') ? true : false
         ,sampleRate: 100,
         env: GetEnv('REACT_APP_ENV_DATADOG'),
         service: GetEnv('REACT_APP_SERVICE_DATADOG'),
         silentMultipleInit: true
      })

      datadogLogs.addLoggerGlobalContext('env', GetEnv('REACT_APP_ENV_DATADOG'));
      datadogLogs.addLoggerGlobalContext('bu', GetEnv('REACT_APP_BU_DATADOG'));
      datadogLogs.addLoggerGlobalContext('application', GetEnv('REACT_APP_DATADOG'));
      datadogLogs.addLoggerGlobalContext('ddtags', `env:${GetEnv('REACT_APP_ENV_DATADOG')},service:${GetEnv('REACT_APP_SERVICE_DATADOG')},application:${GetEnv('REACT_APP_DATADOG')},bu:${GetEnv('REACT_APP_BU_DATADOG')}`);
   }

   static info = (msg: string, obj?: any) => {
      if (obj) {
         datadogLogs.logger.info(msg, obj);
      } else {
         datadogLogs.logger.info(msg);
      }
   }

   static error = (msg: string, err?: any) => {
      if (err) {
         datadogLogs.logger.error(msg, err);
      } else {
         datadogLogs.logger.error(msg);
      }
   }

   static warn = (msg: string, obj?: any) => {
      if (obj) {
         datadogLogs.logger.warn(msg, obj);
      } else {
         datadogLogs.logger.warn(msg);
      }
   }

   static debug = (msg: string, obj?: any) => {
      if (obj) {
         datadogLogs.logger.debug(msg, obj);
      } else {
         datadogLogs.logger.debug(msg);
      }
   }
}
