import { Reducer } from "redux";
import { DownloadedPictureTypes, PictureDownloadedState } from "../types/pictures.Types";

export const initialState: PictureDownloadedState []= [];

const reducer : Reducer<PictureDownloadedState[]> = (state = initialState, action = {type:'', name:'', id:'', payload: {} }) => {
    switch(action.type){
        case DownloadedPictureTypes.ADD_PIC:{
            let copy  = [...state]
            // vérification si l'image n'existe pas déjà, sinon on supprime l'ancienne et on rajoute la nouvelle
            const mapBool = copy.map(function(e) { return e.name.includes(action.id); });
            const indexToDelete = mapBool.indexOf(true);
            if(indexToDelete > -1) {
                copy.splice(indexToDelete, 1);
            }
            let newPicc : PictureDownloadedState = {
                id: action.id,
                name: action.name,
            }
            copy.push(newPicc);
             
            return copy;
        }
        case DownloadedPictureTypes.DELETE_PIC:{
            let copy  = [...state]
            const mapBool = copy.map(function(e) { return e.name.includes(action.id); });
            const indexToDelete = mapBool.indexOf(true);
            if(indexToDelete > -1) {
                copy.splice(indexToDelete, 1);
            }
            return copy;
        }
        case DownloadedPictureTypes.DELETE_ALL_PIC:{
            return [];
        }
        default:{
            return state;
        }
    }
}

export {reducer as DownloadedPicturesReducer};