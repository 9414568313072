import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Tab, Slide } from "@material-ui/core";
import { TabsStyled, TabVerticalStyled } from '../../styles/CustomTabPanel.style';
import { CustomPanelProps, CustomPanel } from './CustomPanel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ApplicationState } from '../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { SwitchAutocontroleStyled } from '../../styles/CritereAutocontrole.style';
import StateService from '../../utils/state.service';
import { FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutoControleGroup, FicheAutocontroleTab, FicheAutocontroleTabInfos } from '../../store/types/FicheAutocontrole.Types';
import { CurrentState, CurrentStateActions } from '../../store/types/CurrentState.Types';
import SwitchIconDefault from "./../../assets/value-indicator-default.svg";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ProgressBarService from '../../utils/progressBar.service';
import infoIcon from "./../../assets/info.svg";
import Modal from '@material-ui/core/Modal';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from "@material-ui/core/transitions";
import { API } from '../../utils/callAPI';
import { PictureState, PictureTypes } from '../../store/types/pictures.Types';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { useHistory } from 'react-router-dom';
import ImageSwitch from '../../assets/switch.png';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getModalStyle() {
    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
        borderRadius: 8
    },
}));

interface PropsFromState {
    headers: FicheAutoControleGroup[],
    tabs: JSX.Element[],
}

interface PropsFromDispatch {
    data: FicheAutocontrole,
    currentState: CurrentState,
    updateCurrentPage: Function,
    updateCurrentGroup: Function,
    updateIsTabInfos: Function,
    updateTab: Function,
    pictures: PictureState[],
    deletePicsFromStore: Function,
    setIsSaved: Function
}

type AllProps = PropsFromState & PropsFromDispatch;

const CustomGroupMenu: React.FC<AllProps> = ({ headers, tabs, updateCurrentGroup, currentState, data, updateTab, updateCurrentPage, pictures, deletePicsFromStore, setIsSaved }) => {

    /**
     * Constantes & useState
     */
    //The Header of the menu has more elements than the core of the menu so we must add a gap to synchronise the groups
    //If the tab is activated we have a complition header so we have a greater gap
    const selectorHeaderGap = data.tabs[currentState.currentPage - 1].active ? 3 : 2;
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [openPageMsg, setOpenPageMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [locationKeys, setLocationKeys] = React.useState([])
    const history = useHistory()

    /**
     * Metier
     */
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenPageMsg = () => {
        setOpenPageMsg(true);
    };

    const handleClosePageMsg = () => {
        setOpenPageMsg(false);

    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        StateService.isPageEmpty(data.tabs[currentState.currentPage - 1]).then((res) => {
            if (!checked) {
                if (!res) {
                    handleOpenPageMsg();
                } else {
                    updateTab(StateService.changePageState(data.tabs[currentState.currentPage - 1], false), currentState.currentPage - 1);
                }
            } else {
                updateTab(StateService.changePageState(data.tabs[currentState.currentPage - 1], true), currentState.currentPage - 1);
            }
        });
    };

    const backToMenu = () => {
        window.scroll(0, 0)
        updateCurrentGroup(-1);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        newValue -= selectorHeaderGap;
        if (newValue > -1) {
            if (newValue === currentState.currentGroup) {
                updateCurrentGroup(-1);
            } else {
                updateCurrentGroup(newValue);
            }
        }
    };

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'REPLACE') {
                updateCurrentGroup(-1);
                updateCurrentPage(-1);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    updateCurrentGroup(0);
                } else {
                    setLocationKeys((keys) => [location.key as never, ...keys])
                    updateCurrentGroup(-1);
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationKeys])

    /**
     * Display
     */

    const getVerticalTabClassName = (index: number) => {
        let className = 'vertical-tab';

        if (currentState.currentGroup === index) {
            className += ' vertical-tab-selected'
        } else {
            if (data.tabs[currentState.currentPage - 1].groups[index].activated) {
                className += ' vertical-tab-default';
            } else {
                className += ' vertical-tab-disabled'
            }
        }

        return className
    }

    const getArrowClassName = (index: number) => {
        let className = ' vertical-tab-arrow'

        if (currentState.currentGroup === index) {
            className += ' vertical-tab-arrow-selected'
        }

        return className;
    }


    const createHeaders = () => {
        let headersTabs: JSX.Element[] = [];

        headersTabs.push(<div className="tab-complition" key='page-complition'>
            <span className='page-title'>Complétude {getPageCompletion()} </span>
        </div>)

        if (data.tabs[currentState.currentPage - 1].active) {
            headersTabs.push(<div className="tab-title tab-bg" key='page-manager' style={{ paddingLeft: "16px" }}>
                <img src={infoIcon} alt="" className="icon-info" onClick={handleOpen} />
                <span className='page-title'>La page est {data.tabs[currentState.currentPage - 1].activated ? 'activée' : 'désactivée'} </span>
                <SwitchAutocontroleStyled className="elt-float-right" checked={data.tabs[currentState.currentPage - 1].activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt="" />} />
            </div>)
        }

        headersTabs.push(<Tab className="customGroupMenu-tab-default" value={-1} key={'CustomTabPanel-Default'}></Tab>)

        headers.forEach((group: FicheAutoControleGroup, index: number) => {
            headersTabs.push(<TabVerticalStyled style={{ backgroundColor: 'white' }} className={getVerticalTabClassName(index)} key={'CustomTabPanel-Group-'.concat(group.title)} label={
                <div className="customGroupMenu-tabVerticalStyled">
                    <div className='flex-row'>{group.title} {getGroupCompletion(index)}</div>
                    <ArrowForwardIosIcon className={getArrowClassName(index)} />
                </div>}
                {...CustomPanelProps(index, "vertical")} />
            );
        });
        return headersTabs;
    }

    const createTabs = () => {
        let groups: JSX.Element[] = [];
        let index = 0;
        tabs.forEach((children: JSX.Element) => {
            groups.push(<CustomPanel key={'CustomTabPanel-TabPanel-'.concat(index.toString())} value={currentState.currentGroup} index={index} tabStyle="vertical">
                {children}
            </CustomPanel>)
            index++;
        });

        return groups;
    }

    const getPageCompletion = () => {
        if (data.tabs[currentState.currentPage - 1].activated) {
            let { nbOfCriteriasTotal, nbOfCriteriasCompleted } = ProgressBarService.getComplitionNumbersInPage(data.tabs[currentState.currentPage - 1], data)

            let pageCompletionClassName = nbOfCriteriasTotal === nbOfCriteriasCompleted ? "customGroupMenu-completion-completed" : "customGroupMenu-completion-inprogress";

            return <span className={pageCompletionClassName}> [{nbOfCriteriasCompleted.toString() + "/" + nbOfCriteriasTotal.toString()} critères] </span>
        } else {
            return <span className="customGroupMenu-completion-inprogress">[Désactivé]</span>
        }

    }

    const getGroupCompletion = (index: number) => {
        if (data.tabs[currentState.currentPage - 1].groups[index].activated) {
            let { nbOfCriteriasTotal, nbOfCriteriasCompleted } = ProgressBarService.getComplitionNumbersInGroup(data.tabs[currentState.currentPage - 1].groups[index], data)

            let pageCompletionClassName = nbOfCriteriasTotal === nbOfCriteriasCompleted ? "customGroupMenu-completion-completed" : "customGroupMenu-completion-inprogress";

            if (nbOfCriteriasTotal !== 0) {
                return <span className={pageCompletionClassName}> [{nbOfCriteriasCompleted.toString() + "/" + nbOfCriteriasTotal.toString()}] </span>
            }
        } else {
            return <span className="customGroupMenu-completion-inprogress">[Désactivé]</span>
        }
    }

    const deletePics = async (page: FicheAutocontroleTab | FicheAutocontroleTabInfos) => {
        page.groups.forEach((group: FicheAutoControleGroup) => {
            group.criterias.forEach((criteria: FicheAutoControleCritere) => {
                if (criteria.typeCriteria === 'PI' && !!criteria.photoName) {
                    API.deletePicFromS3(criteria.photoName).then(
                        () => {
                            console.log("Image " + criteria.photoName + "  est supprimée !");
                        },
                        (err) => console.error(err)
                    );
                }
            });
        });
    }

    const updatePage = () => {
        deletePics(currentState.isTabInfos ? data.tabInfos : data.tabs[currentState.currentPage - 1]);
        setOpenPageMsg(false);
        updateTab(StateService.changePageState(data.tabs[currentState.currentPage - 1], false), currentState.currentPage - 1);
        let tabToSave = StateService.resetDisabledCriteriasState(currentState.isTabInfos ? data.tabInfos : data.tabs[currentState.currentPage - 1]);
        updateTab(tabToSave);

        API.updateAutocontrole(tabToSave, ProgressBarService.getComplitionPercent(data), data.statut)
            .then(() => {
                console.log("Autocontrole sauvegardé");
                setLoading(false);
                setIsSaved(true);
            }).catch(() => {
                console.log("Problème lors de la sauvegarde");
                setLoading(false);
            });
    }

    return (
        <div className='card-content-group'>

            <div className={currentState.currentGroup > -1 ? 'tab-bloc-group' : 'tab-bloc-page'} style={currentState.currentGroup > -1 ? {} : { backgroundColor: '#F8F7F8' }}>
                <TabsStyled variant="fullWidth" value={currentState.currentGroup} onChange={handleChange} orientation="vertical" >
                    {createHeaders()}
                </TabsStyled>
            </div>
            {currentState.currentGroup > -1 ?
                <div className='display-mobile tab-mobile-menu customGroupMenu-tabMobile'>
                    <div className="customGroupMenu-tabMobile-margin" >
                        <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToMenu()} />
                        <span className='tab-page-title customGroupMenu-tabMobile-title'>
                            {data.tabs[currentState.currentPage - 1].groups[currentState.currentGroup].title}
                        </span>
                    </div>
                </div> : ''}

            <div className={currentState.currentGroup > -1 ? 'tab-content-group' : ''}>
                {createTabs()}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Alert severity='info' style={modalStyle} className={classes.paper + " info-alerte alert-text"}>
                    Vous pouvez désactiver la totalité des critères présents sur la page <span className="alerte-info-title">{data.tabs[currentState.currentPage - 1].title}</span> s'ils ne concernent pas ce chantier.                
                    <br />
                    En cliquant sur le bouton suivant :
                    <div className='centerImageInformation'><img src={ImageSwitch} alt="" /></div>
                    Cette désactivation est modifiable jusqu'à la validation de l'autocontrôle.
                    <div>
                        <Button className="btn-close-modal" onClick={() => handleClose()}>Fermer</Button>
                    </div>
                </Alert>

            </Modal>

            <Dialog
                open={openPageMsg}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span>Êtes vous sûr de vouloir désactiver la page ? <br /> Les informations saisies sur la page ne seront pas conservées.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updatePage()} color="primary" className='buttonDialog'>
                        OUI
                    </Button>
                    <Button onClick={() => handleClosePageMsg()} color="primary" className='buttonDialog'>
                        NON
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackdrop loading={loading} />
        </div>
    )

}

const mapStateToProps = ({ currentState, ficheAutocontrole, pictures }: ApplicationState) => ({
    currentState: currentState,
    data: ficheAutocontrole.data,
    pictures: pictures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateCurrentPage: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_PAGE, params: params }),
        updateCurrentGroup: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_GROUP, params: params }),
        updateIsTabInfos: (params: boolean) => dispatch({ type: CurrentStateActions.IS_TABINFOS, params: params }),
        updateTab: (data: FicheAutocontroleTab, currentPage: number) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: currentPage }),
        deletePicsFromStore: () => dispatch({ type: PictureTypes.DELETE_ALL_PIC }),
        setIsSaved: (params: boolean) => dispatch({ type: FicheAutocontroleActionTypes.SET_ISSAVED, payload: params })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomGroupMenu);