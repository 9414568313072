import { TabSynthese, TabSyntheseStateActions } from "../../../store/types/TabSynthese.Types";
import React from "react";
import { ApplicationState } from "../../../store/store/store";
import '../SyntheseTabs/SyntheseTab.scss';
import './SyntheseValidationDirectionQualite.scss'
import { AnyAction } from "redux";
import { ThunkDispatch } from 'redux-thunk';
import { connect } from "react-redux";
import { FicheAutoControleCritere } from "../../../store/types/FicheAutocontrole.Types";
import { AUTOCONTROLE_STATUT } from "../../../store/types/generalInformations.Types";
import RadioAutocontrole from "../../Criteres/TypesCriteres/RadioAutocontrole";
import { getUserRole } from "../../../utils/AccessToken";
import { userRoles } from "../../../store/types/user.Types";

interface PropsFromDispatch {
    tabSynthese: TabSynthese,
    setIsSaved: Function,
    setIsValidationDirectionQualiteSaved: Function,
    updateTabSyntheseState: Function
}

const SyntheseValidationDirectionQualite: React.FC<PropsFromDispatch> = ({ tabSynthese, setIsSaved, setIsValidationDirectionQualiteSaved, updateTabSyntheseState }) => {

    const buildCriteres = (criteres: FicheAutoControleCritere[]) => {
        if (!criteres) return;
        return criteres.map((critere: FicheAutoControleCritere) => {
            critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE && (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()));
            return (<div className="validation-direction-qualite">
                <RadioAutocontrole critere={critere} updateCritere={saveCritere} />
            </div>)
        });
    }

    const saveCritere = (critere: FicheAutoControleCritere) => {
        const indexValidation = tabSynthese.validationDirectionQualite.findIndex((p: FicheAutoControleCritere) => critere.id === p.id);
        tabSynthese.validationDirectionQualite[indexValidation] = critere;
        setIsSaved(false);
        setIsValidationDirectionQualiteSaved(false);
        updateTabSyntheseState(tabSynthese);
    }

    return (
        <div className="synthese-tab">
            <div className="synthese-tab-header">
                <div className="synthese-title-completion">
                    <div className="synthese-tab-title">
                        <span>Validation Direction Qualite</span>
                    </div>
                </div>
            </div>
            <div className="synthese-bloc-validation-direction-qualite">
                {buildCriteres(tabSynthese.validationDirectionQualite)}
            </div>
        </div>
    );
};

const mapStateToProps = ({ tabSyntheseState }: ApplicationState) => ({
    tabSynthese: tabSyntheseState.data,
    isSaved: tabSyntheseState.isSaved,
    isValidationDirectionQualiteSaved: tabSyntheseState.isValidationDirectionQualiteSaved
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISSAVED, payload: params }),
        setIsValidationDirectionQualiteSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISVALDIRQUASAVED, payload: params }),
        updateTabSyntheseState: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SyntheseValidationDirectionQualite);