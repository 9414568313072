import React, { useEffect } from "react";
import { HomePageVQState, RegroupementResponsabilite, Agence, HomePageVQTypes } from "../../store/types/homePageVQ.Types";
import { API } from "../../utils/callAPI";
import { emptyRegroupementResponsabilite, emptyAgence, emptyReferentielFront, getEmptyPeriodeCritere, defaultAgence } from "../../utils/constantes/emptyObjects";
import { Backdrop, CircularProgress, createStyles, makeStyles, Paper, Button, InputBase, Theme } from "@material-ui/core";
import { TabsStyled, TabVerticalStyled } from "../../styles/CustomTabPanel.style";
import { ReferentielUtils } from "../../utils/ReferentielUtils";
import { CustomPanelProps } from "../CustomMenu/CustomPanel";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ImageSearch from '../../assets/search.svg';
import { ReferentielFront } from "../../store/types/generalInformations.Types";
import { ApplicationState } from "../../store/store/store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { FicheAutoControleCritere } from "../../store/types/FicheAutocontrole.Types";
import { useHistory } from "react-router-dom";
import { REDIRECT_VQ_TABLEAU } from "../../utils/constantes/contantes";
import PeriodeSearchCustom from "../Criteres/TypesCriteres/PeriodeSearchCustom";
import Helper from "../Helper/Helper";
import { HelperTypeEnum } from "../Helper/HelperTypeEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);

interface PropsFromDispatch {
  homePageVQState: HomePageVQState,
  updateHomePageVQState: Function,
  resetHomePageVQState: Function
}

const HomePageVQSearch: React.FC<PropsFromDispatch> = ({ homePageVQState, updateHomePageVQState, resetHomePageVQState }) => {


  /**
   * Constantes et state :
   */
  
  const history = useHistory();
  
  const [disableRegroupementResponsabilite, setDisableRegroupementResponsabilite] = React.useState(false);
  const [disableAgence, setDisableAgence] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentTab, setcurrentTab] = React.useState(-1);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [disableRechercher, setDisableRechercher] = React.useState(true);
  const [openAlertSearch, setOpenAlertSearch] = React.useState(false);
  const choicesLabels = ['Choix du regroupement de responsabilité', 'Choix de l\'agence'];

  const classes = useStyles();

  const getRegroupementsResponsabilites = () => {
    setLoading(true)
    API.getRegroupementsResponsabilitesAPI()
      .then(function (response: any) {

        console.log("Réponse de /referentiels/regroupementresponsabilites", response.data);
        homePageVQState.regroupementsResponsabilites = response.data;
        setDisableRegroupementResponsabilite(false);

        if (!homePageVQState.regroupementsResponsabilites || homePageVQState.regroupementsResponsabilites.length === 0) {
          homePageVQState.regroupementsResponsabilites = [emptyRegroupementResponsabilite];
        }
        updateHomePageVQState(homePageVQState)
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log("Réponse de /referentiels/regroupementsresponsabilites", error);
        homePageVQState.regroupementsResponsabilites = [emptyRegroupementResponsabilite];
        updateHomePageVQState(homePageVQState);
        setLoading(false)
      });
  }

  const getAgences = () => {
    setLoading(true)
    API.getAgencesAPI(homePageVQState.regroupementResponsabiliteSelected?.id)
      .then(function (response: any) {
        console.log("Réponse de /referentiels/regroupementsresponsabilites/" + homePageVQState.regroupementResponsabiliteSelected?.id + "/agences", response.data);
        homePageVQState.agences = response.data;
        if (!homePageVQState.agences || homePageVQState.agences.length === 0) {
          homePageVQState.agences = [emptyAgence];
          setDisableAgence(false);
        }
        if (homePageVQState.agences.length === 1) {
          homePageVQState.agenceSelected = homePageVQState.agences[0];
          setDisableAgence(true);
        }
        if (homePageVQState.agences.length > 1) {
          homePageVQState.agences.unshift(defaultAgence);
          setDisableAgence(false);
        }
        updateHomePageVQState(homePageVQState)
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log("Réponse de /referentiels/regroupementsresponsabilites/" + homePageVQState.regroupementResponsabiliteSelected?.id + "/agences", error);
        homePageVQState.agences = [emptyAgence];
        updateHomePageVQState(homePageVQState);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!loading && (!homePageVQState.regroupementsResponsabilites || (homePageVQState.regroupementsResponsabilites && homePageVQState.regroupementsResponsabilites.length === 0)
      || (homePageVQState.regroupementsResponsabilites && homePageVQState.regroupementsResponsabilites[0] && JSON.stringify(homePageVQState.regroupementsResponsabilites[0]) === JSON.stringify(emptyRegroupementResponsabilite)))) {
        resetHomePageVQState();
        getRegroupementsResponsabilites();
    }

    if (!!homePageVQState.regroupementsResponsabilites && homePageVQState.regroupementsResponsabilites.length === 1) {
      setDisableRegroupementResponsabilite(true)
    }

    if (!!homePageVQState.agences && homePageVQState.agences.length === 1) {
      setDisableAgence(true)
    }

    controleCriteresRecherche();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controleCriteresRecherche = () => {
    setDisableRechercher(true);

    if (homePageVQState.regroupementResponsabiliteSelected?.id_referentiel && (homePageVQState.dateLivraisonDebut <= homePageVQState.dateLivraisonFin)) {
      setDisableRechercher(false);
    }
  }

  const getListItems = () => {
    let referentielsFront: ReferentielFront[] = [];

    switch (currentTab) {
      case 0:
        if (homePageVQState.regroupementsResponsabilites.length === 0 && !loading) {
          getRegroupementsResponsabilites();
        }
        referentielsFront = ReferentielUtils.regroupementsResponsabilitesToReferentielsFront(homePageVQState.regroupementsResponsabilites);
        break;
      case 1:
        if (homePageVQState.agences.length === 0 && !loading) {
          getAgences();
        }
        referentielsFront = ReferentielUtils.agencesToReferentielsFront(homePageVQState.agences);
        break;
    }

    let listItemsFiltered = referentielsFront.filter((item: ReferentielFront) => item.value.toLowerCase().includes(searchTxt.toLowerCase()));

    if (listItemsFiltered.length === 0 || listItemsFiltered[0] === emptyReferentielFront) {
      return (<div>{loading ? "Recherche en cours..." : "Aucun résultat trouvé"}</div>)
    } else {
      listItemsFiltered = sortReferentiels(listItemsFiltered)
      return listItemsFiltered.map((item: any, index: any) => (
        <Button key={index} variant="outlined" className="choice-elt" onClick={() => updateValue(item)}>{item.value} </Button>
      ))
    }

  }

  const updateValue = (value: ReferentielFront) => {

    switch (currentTab) {
      case 0:
        const regroupementResponsabilite = homePageVQState.regroupementsResponsabilites.find((regroupement: RegroupementResponsabilite) => regroupement.id_referentiel === value.id) as RegroupementResponsabilite;
        homePageVQState.regroupementResponsabiliteSelected = regroupementResponsabilite;
        homePageVQState.agenceSelected = emptyAgence;
        homePageVQState.agences = [];
        getAgences();
        controleCriteresRecherche();
        break;
      case 1:
        const agence = homePageVQState.agences.find((agence: Agence) => agence.id_referentiel === value.id) as Agence;
        homePageVQState.agenceSelected = agence;
        break;
    }

    setcurrentTab(-1);
    updateHomePageVQState(homePageVQState)
  }

  const disableTab = (index: number) => {
    switch (index) {
      case 0:
        return disableRegroupementResponsabilite;
      case 1:
        return !homePageVQState.regroupementResponsabiliteSelected?.id_referentiel || disableAgence;
    }
  }

  const getVerticalTabClassName = (index: number) => {
    let className = 'vertical-tab';

    if (isSelected(index)) {
      className += ' vertical-tab-selected'
    } else {
      className += ' vertical-tab-default';
    }

    return className
  }

  const isSelected = (index: number) => {
    return currentTab === (index);
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSearchTxt('');
    if (currentTab > -1 && currentTab === newValue) {
      setcurrentTab(-1);
    } else {
      setcurrentTab(newValue);
    }
  }

  const getTabLabel = (index: number) => {
    switch (index) {
      case 0:
        return homePageVQState?.regroupementResponsabiliteSelected?.id_referentiel ? ReferentielUtils.getRegroupementResponsabiliteLibelle(homePageVQState.regroupementResponsabiliteSelected) : null;
      case 1:
        return homePageVQState?.agenceSelected?.id_referentiel ? ReferentielUtils.getAgenceLibelle(homePageVQState.agenceSelected) : null;
    }
  }

  const getArrowClassName = (index: number) => {
    let className = ' vertical-tab-arrow'

    if (isSelected(index)) {
      className += ' vertical-tab-arrow-selected'
    }

    return className;
  }

  const backToMenu = () => {
    setcurrentTab(-1);
  }

  const updateSearchTxt = (e: any) => {
    setSearchTxt(e.target.value);
  }

  const sortReferentiels = (referentiels: ReferentielFront[]) => {
    return referentiels.sort((a: ReferentielFront, b: ReferentielFront) => {
      if (a.value === defaultAgence.libelleAgence) return -1;
      if (b.value === defaultAgence.libelleAgence) return 1;
      if (parseInt(a.value) === parseInt(b.value)) return a.value.localeCompare(b.value)
      return parseInt(a.value) - parseInt(b.value)
    });
  }

  // Periode critère
  const getPeriodeCritere = () => {
    let critereDate = getEmptyPeriodeCritere();
    critereDate.title = 'Date de livraison comprise entre';
    critereDate.value = [homePageVQState.dateLivraisonDebut, homePageVQState.dateLivraisonFin];

    return critereDate;
  }

  const updatePeriode = (critere: FicheAutoControleCritere) => {
    let value = critere.value as number[];
    homePageVQState.dateLivraisonDebut = value[0];
    homePageVQState.dateLivraisonFin = value[1];
    updateHomePageVQState(homePageVQState);
    controleCriteresRecherche();
  }

  // gestion recherche
  const handleSearch = () => {
    setLoading(true);
    setOpenAlertSearch(false);

    API.getTranchesTravauxRegroupementsAPI(homePageVQState.regroupementResponsabiliteSelected.id, homePageVQState.agenceSelected.codeAgence, homePageVQState.dateLivraisonDebut, homePageVQState.dateLivraisonFin)
      .then(function (response: any) {
        console.log("Réponse de /referentiels/tranchestravaux?idRegroupementResponsabilite=" + homePageVQState.regroupementResponsabiliteSelected.id +
          "&idAgence=" + homePageVQState.agenceSelected.codeAgence + "&dateDebut=" + homePageVQState.dateLivraisonDebut + "&dateFin=" + homePageVQState.dateLivraisonFin, response.data);

        homePageVQState.tranchesTravauxVisites = response.data;
        updateHomePageVQState(homePageVQState);
        if (!homePageVQState.tranchesTravauxVisites || homePageVQState.tranchesTravauxVisites.length < 1) {
          setOpenAlertSearch(true);
        } else {
          history.push(REDIRECT_VQ_TABLEAU);
        }
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log("Réponse de /referentiels/tranchestravaux?idRegroupementResponsabilite=" + homePageVQState.regroupementResponsabiliteSelected.id +
          "&idAgence=" + homePageVQState.agenceSelected.codeAgence + "&dateDebut=" + homePageVQState.dateLivraisonDebut + "&dateFin=" + homePageVQState.dateLivraisonFin, error);
        homePageVQState.tranchesTravauxVisites = [];
        updateHomePageVQState(homePageVQState);
        setLoading(false);
      });
  }

  return (

    <div className='card-content-group'>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={currentTab > -1 ? 'tab-bloc-group' : 'tab-bloc-page'} style={currentTab > -1 ? {} : { backgroundColor: '#F8F7F8' }}>
        <div className="tab-title home-bg" key='page-acceuil'>
          <span className='page-title' >Informations générales </span>
        </div>
        <TabsStyled variant="fullWidth" value={currentTab > -1 ? currentTab : 0} onChange={handleChange} orientation="vertical" >
          {choicesLabels.map((choix, index) =>
            <TabVerticalStyled disabled={disableTab(index)} style={disableTab(index) ? { backgroundColor: '#F1EEF1' } : { backgroundColor: 'white', textAlign: 'left' }}
              className={getVerticalTabClassName(index)} key={'CustomTabPanel-Group-' + index} label={
                <div className="customGroupMenu-tabVerticalStyled" style={disableTab(index) ? { color: "#A29AA2" } : {}}>
                  <div className='flex-row'> {getTabLabel(index) ? getTabLabel(index) : choix} </div>
                  <ArrowForwardIosIcon className={getArrowClassName(index)} />
                </div>}
              {...CustomPanelProps(index, "vertical")} />)}
        </TabsStyled>
        <div className="bloc">
          <div>
            <PeriodeSearchCustom critere={getPeriodeCritere()} updateCritere={updatePeriode} />
          </div>
        </div>
        <div className="margin-y-8">
          <Button className={"btn-radio btn-go-autocontrole " + (disableRechercher ? "desactivate" : "active")} onClick={() => handleSearch()} disabled={disableRechercher}> Rechercher </Button>
        </div>
        {openAlertSearch ?
          <Helper type={HelperTypeEnum.WARNING} title="aucune tranche travaux trouvée" />
          : ''}
      </div>
      <div className={currentTab > -1 ? 'tab-content-group' : 'customGroupMenu-tab-default'}>
        {currentTab > -1 ?
          <div className='display-mobile tab-mobile-menu customGroupMenu-tabMobile'>
            <div className="customGroupMenu-tabMobile-margin" >
              <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToMenu()} />
              <span className='tab-page-title customGroupMenu-tabMobile-title'>
                {getTabLabel(currentTab) ? getTabLabel(currentTab) : choicesLabels[currentTab]}
              </span>
            </div>
          </div> : ''}
        <Paper component="form" className="seach-form">
          <Button type="submit" className={"search-bar " + classes.iconButton} aria-label="search" disabled={true}>
            <img className="iconSearch" src={ImageSearch} alt="\" />
          </Button>
          <InputBase
            className={classes.input}
            placeholder="Rechercher..."
            value={searchTxt}
            onChange={updateSearchTxt}
            inputProps={{ 'aria-label': 'Rechercher...' }}
          />
        </Paper>
        <div className="fullWidth">
          {getListItems()}
        </div>
      </div>
    </div>


  );
};

const mapStateToProps = ({ homePageVQState }: ApplicationState) => ({
  homePageVQState: homePageVQState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateHomePageVQState: (params: HomePageVQState) => dispatch({ type: HomePageVQTypes.UPDATE_STATE, payload: params }),
    resetHomePageVQState: () => dispatch({type: HomePageVQTypes.RESET_STATE})
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(HomePageVQSearch);
