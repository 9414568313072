import React from 'react';
import './Helper.scss';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { HelperTypeEnum } from './HelperTypeEnum'

interface PropsFromState {
    type: HelperTypeEnum,
    title: string,
    description?: string
}

const Helper : React.FC<PropsFromState> = ({type, title, description}) =>{
    
    return (
        <div className={"helper helper-" + (type ? type : HelperTypeEnum.INFO) }>
                <div className="tabInfos-criterias-helper-icon">
                    <InfoOutlinedIcon/>
                </div>
                <div className="helper-text">
                    <div className="tabInfos-criterias-helper-title">
                        {title}
                    </div>
                    { description ?
                    <div className="tabInfos-criterias-helper-infos">
                        {description}
                    </div>  : '' }
                </div>
              </div>
    );
};
export default Helper;