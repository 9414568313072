import React from 'react';
import './SyntheseCommentaires.scss'
import '../SyntheseTabs/SyntheseTab.scss';
import { TabSynthese, TabSyntheseStateActions } from '../../../store/types/TabSynthese.Types';
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { FicheAutoControleCritere } from '../../../store/types/FicheAutocontrole.Types';
import InputTextAutocontrole from '../../Criteres/TypesCriteres/InputTextAutocontrole';
import { AUTOCONTROLE_STATUT } from '../../../store/types/generalInformations.Types';
import { ModeAffichage } from '../../../utils/constantes/contantes';


interface PropsFromDispatch{
    tabSynthese: TabSynthese,
    setIsSaved: Function,
    setIsCommentairesSaved: Function,
    updateTabSyntheseState: Function

}

const SyntheseCommentaires : React.FC<PropsFromDispatch> = ({tabSynthese, setIsSaved, setIsCommentairesSaved, updateTabSyntheseState}) =>{

    const saveCriteria = (critere:FicheAutoControleCritere) => {
        const indexCommentaire = tabSynthese.commentaires.findIndex((p:FicheAutoControleCritere) => critere.id === p.id);
        tabSynthese.commentaires[indexCommentaire] = critere;
        setIsSaved(false)
        setIsCommentairesSaved(false)
        updateTabSyntheseState(tabSynthese);
    }

    const saveCommentaireCMQ = (critere:FicheAutoControleCritere) => {
        tabSynthese.commentaireCmq = critere
        setIsSaved(false)
        setIsCommentairesSaved(false)
        updateTabSyntheseState(tabSynthese);
    }


    /**
     * Affichage photo
     * @param criteres 
     * @returns 
     */    
    const buildCommentairesForSynthese = (criteres: FicheAutoControleCritere[]) => {
        if(!criteres) return ;
        return criteres.map((critere:FicheAutoControleCritere) => {
            critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
            return (<div className="commentaire">
                <InputTextAutocontrole critere={critere} updateCritere={saveCriteria} modeAffichage={ModeAffichage.SYNTHESE}/>
            </div>)
        });
    }

    /**
     * Construit l'affichage du commentaire CMQ
     */
    const buildCommentaireCMQ = (critere: FicheAutoControleCritere) => {
        critere.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
        return (<div className="commentaire-cmq">
            <InputTextAutocontrole critere={critere} updateCritere={saveCommentaireCMQ} />
        </div>);
    }
    
    
    return (
        <div className="synthese-tab">
            <div className="synthese-tab-header">
                <div className="synthese-title-completion">
                    <div className="synthese-tab-title">
                        <span>Commentaires</span>
                    </div>
                </div>
            </div>
            <div className="synthese-bloc-commentaires">
                {buildCommentairesForSynthese(tabSynthese.commentaires)}
                {tabSynthese.commentaireCmq ? 
                    buildCommentaireCMQ(tabSynthese.commentaireCmq) 
                : ' '}
            </div>
        </div>
    );
};
const mapStateToProps = ({tabSyntheseState}: ApplicationState) => ({
    tabSynthese: tabSyntheseState.data,
    isSaved: tabSyntheseState.isSaved,
    isCommentairesSaved: tabSyntheseState.isCommentairesSaved
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({type: TabSyntheseStateActions.SET_ISSAVED, payload: params}),
        setIsCommentairesSaved: (params: boolean) => dispatch({type: TabSyntheseStateActions.SET_ISCOMSSAVED, payload: params}),
        updateTabSyntheseState: (data:TabSynthese) => dispatch({type: TabSyntheseStateActions.UPDATE_STATE, payload: data})
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SyntheseCommentaires);