import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/store/store";
import { FicheAutocontrole } from "../../store/types/FicheAutocontrole.Types";
import { TabSynthese, TabSyntheseStateActions } from "../../store/types/TabSynthese.Types";
import { getDestinatairesCCMailValue, getDestinatairesMailValue, getInfosMail, getObjetMailValue, controleMail } from "./mailUtils";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Slide, TextField } from "@material-ui/core";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Helper from "../../components/Helper/Helper";
import { HelperTypeEnum } from "../../components/Helper/HelperTypeEnum";
import { userRoles } from "../../store/types/user.Types";
import { getUserRole } from "../../utils/AccessToken";
import { useHistory } from "react-router-dom";
import { REDIRECT_FICHE, SEVERITY } from "../../utils/constantes/contantes";
import LoadingBackdrop from "../../components/LoadingBackdrop/LoadingBackdrop";
import { API } from "../../utils/callAPI";
import { AUTOCONTROLE_STATUT } from '../../store/types/generalInformations.Types';

import "./mail.scss";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TransitionProps } from "@material-ui/core/transitions";
import MailBody, { getCommentaireCmqMail } from "./MailBody";
import ReactDOMServer from 'react-dom/server'
import { MessagesAlertState, MessagesAlertTypes } from "../../store/types/MessagesAlert.Types";
import { AxiosResponse } from 'axios';

interface PropsFromDispatch {
  data: FicheAutocontrole,
  tabSynthese: TabSynthese,
  updateTabSyntheseState: Function,
  updateMessageSnackBar: Function
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Mail: React.FC<PropsFromDispatch> = ({ data, tabSynthese, updateTabSyntheseState, updateMessageSnackBar }) => {

  /**
   * Constantes et state
   */
  const history = useHistory();
  const [objetMail, setObjetMail] = React.useState(getObjetMailValue(data));
  const [destinataireMail, setDestinataireMail] = React.useState(getDestinatairesMailValue(tabSynthese));
  const [destinataireCCMail, setDestinaireCCMail] = React.useState(getDestinatairesCCMailValue(tabSynthese));
  const [loading, setLoading] = React.useState(false);
  const [, setOpenAlert] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [, setOpenMailOk] = React.useState(false);
  const [controleMailOk, setControleMailOk] = React.useState(true);
  const [disableEnvoyer, setDisableEnvoyer] = React.useState(true);

  /**
   * Handlers
   */
  const handleChangeObjetMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjetMail(event.target.value);
  };

  const handleChangeDestinataireMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestinataireMail(event.target.value);
  };

  const handleChangeDestinataireCCMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestinaireCCMail(event.target.value);
  };

  const controleMailDestinataires = () => {
    setControleMailOk(false);
    setDisableEnvoyer(true);

    if (controleMail(destinataireMail) && (!destinataireCCMail.trim() || controleMail(destinataireCCMail))) {
      setControleMailOk(true);
      setDisableEnvoyer(false);
    }
  }

  const handleSendMail = () => {
    // récupération du rendu du body
    const htmlString = ReactDOMServer.renderToStaticMarkup(<MailBody ficheAutocontrole={data} tabSynthese={tabSynthese} disabled={isDisabled} />);
    // récupération du commentaire CMQ saisi
    const commentaireCmq = getCommentaireCmqMail();
    // remplacement du textarea par le commentaire saisi
    const bodyWithCommentaire = htmlString.replace(/<textarea aria-hidden\b[^>]*>(.*?)<\/textarea>/i, "").replace(/<textarea\b[^>]*>(.*?)<\/textarea>/i, commentaireCmq);
    setLoading(true);
    setOpenDialog(false);
    API.sendMailReport(data.id_pk as string, destinataireMail, destinataireCCMail, objetMail, bodyWithCommentaire)
      .then((response: AxiosResponse) => {

        if (response.status === 200) {

          tabSynthese.statut = AUTOCONTROLE_STATUT.RAPPORT_ENVOYE;
          updateTabSyntheseState(tabSynthese);
          setLoading(false);
          setOpenMailOk(true);
          updateMessageSnackBar({ messages: ["Le rapport a été envoyé avec succès !"], severity: SEVERITY.SUCCESS });
          history.push(REDIRECT_FICHE);

        } else {
          setLoading(false);
          history.push(REDIRECT_FICHE)
        }
      })
      .catch(() => {
        setLoading(false);
        setOpenAlert(true);
        updateMessageSnackBar({ messages: ["Un problème est survenu lors de l'envoi du mail."], severity: SEVERITY.ERROR });
      })
  }
  /**
   * Metier
   */

  const isDisabled = () => {
    return getUserRole().toLowerCase() !== userRoles.cmq.toLowerCase()
  }

  const backToSynthese = () => {
    history.push(REDIRECT_FICHE)
  }

  useEffect(() => {
    controleMailDestinataires()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    /**
     * Display
     */
    return (<div className='card-content'>
        <LoadingBackdrop loading={loading}/>
        <div className='mail-title-container'>
          <span className='mail-title-arrow'>
                <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToSynthese() } />
          </span>
          <span className='mail-title-text'>
            Envoi de l'autocontrôle par mail
          </span>
        </div>
        <div className='mail-infos-container'>
          <div className='mail-infos-helper'>
          <Helper type={HelperTypeEnum.INFO} title="Vous avez validé l'autocontrôle." description={getInfosMail()}/>
          </div>
          {!controleMailOk ? 
          <div className='mail-infos-helper'>
                <Helper type={HelperTypeEnum.WARNING} title="Le format des adresses mails saisies est incorrect."/>
          </div>
                 : ''}
        </div>
        <div className='mail-container'>
          <div className='mail-text-input-container'>
            <TextField
              id="objet-mail"
              multiline
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">Objet:</InputAdornment>,
              }}
              value={objetMail}
              onChange={handleChangeObjetMail}
              disabled = {isDisabled()}
            />
          </div>
          <div className='mail-text-input-container'>
            <TextField
              id="destinataire-mail"
              multiline
              fullWidth 
              InputProps={{
                startAdornment: <InputAdornment position="start">À:</InputAdornment>,
              }}
              value={destinataireMail}
              onChange={handleChangeDestinataireMail}
              onBlur={controleMailDestinataires}
              disabled={isDisabled()}
            />
          </div>
          <div className='mail-text-input-container'>
            <TextField
              id="destinaireCC-mail"
              multiline
              fullWidth 
              InputProps={{
                startAdornment: <InputAdornment position="start">CC:</InputAdornment>,
              }}
              value={destinataireCCMail}
              onChange={handleChangeDestinataireCCMail}
              onBlur={controleMailDestinataires}
            />
          </div>
          <div className='mail-text-input-container'>
            <MailBody
              ficheAutocontrole={data} 
              tabSynthese={tabSynthese}
              disabled = {isDisabled}
            />
          </div>
        </div>
        <div className='send-mail-button'>
          <Button className={"btn-radio btn-go-autocontrole " + (disableEnvoyer ? "desactivate" : "active")} key="btn-autocontrole" variant="outlined"  
            onClick={() => setOpenDialog(true)}
            disabled = {disableEnvoyer}> 
            Envoyer
          </Button>
        </div>
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <span>Cette action est définitive.<br /> Êtes-vous sûr de vouloir d'envoyer le rapport à la direction?</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSendMail()} color="primary" className="buttonDialog">
                    OUI
            </Button>
                <Button onClick={() => setOpenDialog(false)} color="primary" className="buttonDialog">
                    NON
            </Button>
            </DialogActions>
        </Dialog>
    </div>)
}

const mapStateToProps = ({ ficheAutocontrole, tabSyntheseState }: ApplicationState) => ({
  data: ficheAutocontrole.data,
  tabSynthese: tabSyntheseState.data
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateTabSyntheseState: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data }),
    updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mail);