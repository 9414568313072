import Box from "@material-ui/core/Box";
import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    tabStyle: any;
  }

  export const CustomPanelProps = (index: any, tabStyle: any) => {
    return {
      id: `${tabStyle}-tab-${index}`,
      'aria-controls': `${tabStyle}-tabpanel-${index}`,
    };
  }
  
  export const CustomPanel = (props: TabPanelProps) => {
    const { children, value, index, tabStyle, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`${tabStyle}-tabpanel-${index}`}
        aria-labelledby={`${tabStyle}-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{padding:0}} p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }