import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { API } from './callAPI';
import Env from './Env';

/**
 * Renvoie la liste des clés de la session storage pour al library msal
 */
const getKeysSessionStorage = (response: AuthenticationResult) => {

    // parameters
    const clientId = Env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
    const tenantId = response.tenantId;

    let homeAccountId = '';
    let account: AccountInfo | null = response.account;
    if (account) {
        homeAccountId = account.homeAccountId;
    }

    // keys
    let key_loginwindows = homeAccountId + '-login.windows.net-accesstoken-' + tenantId;
    let key_refreshtoken = homeAccountId + '-login.windows.net-refreshtoken-' + clientId + '--';
    let key_idtoken = homeAccountId + '-login.windows.net-refreshtoken-' + clientId + '-' + tenantId + '-';
    let key_accesstoken = homeAccountId + '-login.windows.net-accesstoken-' + clientId + '-' + tenantId + '-openid profile user.read email';

    // return keys
    return { clientId: clientId, key_loginwindows: key_loginwindows, key_refreshtoken: key_refreshtoken, key_idtoken: key_idtoken, key_accesstoken: key_accesstoken }
}


/**
 * Raffaichit un idToken à partir d'un refreshToken
 */
export const getIdTokenFromRefreshToken = async (response: AuthenticationResult) => {

    const { clientId, key_refreshtoken } = getKeysSessionStorage(response);
    const refresh_token: any = JSON.parse(sessionStorage.getItem(key_refreshtoken) as string);
    let idToken;

    try {

        await API.getOpenidConfiguration()
            .then(async conf => {
                let token_endpoint = conf.data.token_endpoint;
                if (token_endpoint && refresh_token) {
                    await API.getRefreshToken(token_endpoint, clientId, refresh_token.secret)
                        .then((response) => {
                            if (response && response.data && response.data.id_token) {
                                idToken = response.data.id_token;
                            }
                        });
                }
            });
    } catch {
        console.error('error on refresh token');
    }
    return idToken;
}