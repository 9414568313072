import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface PropsFromState {
    loading: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        }
    }),
);

const LoadingBackdrop : React.FC<PropsFromState> = ({loading}) =>{

    const classes = useStyles();
    
    return (
        <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
        </Backdrop>
    );
};
export default LoadingBackdrop;