import React from "react";
import { HomePageVQState, TrancheTravauxVisitesFront, TrancheTravauxVisites, VisiteQualite } from "../../store/types/homePageVQ.Types";
import { ApplicationState } from "../../store/store/store";
import { connect } from "react-redux";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, makeStyles, Modal, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { REDIRECT_HOME_VQ, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L, REDIRECT_VQ_ADMIN } from "../../utils/constantes/contantes";
import DateUtils from "../../utils/DateUtils";
import "../../screens/home-page-vq/HomePageVQ.css";
import { Alert } from '@material-ui/lab';
import { TranchesVisitesQualitesUtils } from "../../utils/TranchesVisitesQualitesUtils";
import { colorsTableauVisiteQualite } from "../../styles/ColorsTableauVisiteQualite.style";
import { API } from "../../utils/callAPI";
import { AdminVisiteQualiteState, AdminVisiteQualiteTypes, VisiteQualiteState, VisiteQualiteTypes } from "../../store/types/adminVisiteQualite.Types";


const useStyles = makeStyles((theme) => ({
    hover: {
        "&:hover": {
            backgroundColor: "#f2e8ed !important",
            cursor: "pointer",

        },
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
        borderRadius: 8
    }
}));

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

interface PropsFromDispatch {
    homePageVQState: HomePageVQState,
    adminVisiteQualiteState: AdminVisiteQualiteState,
    updateAdminVisiteQualiteState: Function,
    visiteQualiteState: VisiteQualiteState,
    updateVisiteQualiteState: Function,
    resetVisiteQualiteState: Function
}

const HomePageVQResult: React.FC<PropsFromDispatch> = ({ homePageVQState, adminVisiteQualiteState, updateAdminVisiteQualiteState, visiteQualiteState, updateVisiteQualiteState, resetVisiteQualiteState }) => {

    /**
     * Constantes et state :
     */

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [open, setOpen] = React.useState(false);
    const [periodeLivraisonDebut, setPeriodeLivraisonDebut] = React.useState(0);
    const [periodeLivraisonFin, setPeriodeLivraisonFin] = React.useState(0);

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    // redirect to Admin Visite Qualité
    const handleItemClick = (item: TrancheTravauxVisitesFront) => {
        let tranche = homePageVQState.tranchesTravauxVisites.find((t: TrancheTravauxVisites) => t.id_referentiel === item.id_referentiel) as TrancheTravauxVisites;
        adminVisiteQualiteState.data = tranche;
        updateAdminVisiteQualiteState(adminVisiteQualiteState);
        initVisiteQualite(tranche);
        history.push(REDIRECT_VQ_ADMIN);
    }

    const initVisiteQualite = (tranche: TrancheTravauxVisites) => {
        if (tranche.visiteQualiteLiees && tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L4)) {
            visiteQualiteState.visiteQualiteL4 = tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L4) as VisiteQualite;
        }
        if (tranche.visiteQualiteLiees && tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L15)) {
            visiteQualiteState.visiteQualiteL15 = tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L15) as VisiteQualite;
        }
        if (tranche.visiteQualiteLiees && tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L)) {
            visiteQualiteState.visiteQualiteL = tranche.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L) as VisiteQualite;
        }
        updateVisiteQualiteState(visiteQualiteState);
    }

    // TablePagination
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const backToHomePageVQSearch = () => {
        history.push(REDIRECT_HOME_VQ);
    }
    // TableCell

    const getDateCellLibelle = (idTranche: string, typeAutocontrole: string, dateVisite: number, color: string) => {
        if (color === colorsTableauVisiteQualite.orange) {
            return <span style={{ color: 'orange' }}>{DateUtils.dateBackToDateFront(dateVisite)}</span>
        }

        if (color === colorsTableauVisiteQualite.green && typeAutocontrole === TYPE_AUTOCONTROLE_L4) {
            return <span style={{ color: 'yellowgreen' }}>{DateUtils.dateBackToDateFront(dateVisite)}</span>
        }

        if (color === colorsTableauVisiteQualite.green && typeAutocontrole === TYPE_AUTOCONTROLE_L15) {
            return <span onClick={() => handleDateClick(idTranche, typeAutocontrole)} style={{ fontWeight: 'bold', color: 'yellowgreen', textDecoration: 'underline' }}>{DateUtils.dateBackToDateFront(dateVisite)}</span>
        }

        return <span>{DateUtils.dateBackToDateFront(dateVisite)}</span>
    }

    const handleDateClick = (idTranche: string, typeAutocontrole: string) => {
        setLoading(true);
        API.getPeriodeLivraisonAutocontrole(idTranche, typeAutocontrole)
            .then(function (response: any) {
                console.log("Réponse de /autocontroles/" + idTranche + "/type/" + typeAutocontrole + "/periode-livraison", response.data);
                let periodes = response.data as number[];
                setPeriodeLivraisonDebut(periodes[0]);
                setPeriodeLivraisonFin(periodes[1]);
                setLoading(false);
                handleOpen();
            })
            .catch(function (error: any) {
                console.log("Réponse de /autocontroles/" + idTranche + "/type/" + typeAutocontrole + "/periode-livraison", error);
                setLoading(false);
            });
    }

    // Modal
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getDialogText = () => {
        return "du " + DateUtils.dateBackToDateFront(periodeLivraisonDebut) + " au " + DateUtils.dateBackToDateFront(periodeLivraisonFin);
    }

    // Effect
    React.useEffect(() => {
        resetVisiteQualiteState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
       * Display
       */
    return (
        <div className='card-content'>
            <LoadingBackdrop loading={loading} />
            <div className='mail-title-container'>
                <span className='mail-title-arrow'>
                    <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToHomePageVQSearch()} />
                </span>
                <span className='mail-title-text'>
                    Retour
                </span>
            </div>
            <div className='parametre-recapitulatif-card'>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}> {homePageVQState.tranchesTravauxVisites.length} Résultats</div>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: '10%' }} align="left">Programme Tranche</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="left">Ville Programme</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '20%' }} align="left">Nom de la tranche</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '10%' }} align="center">Nbr Lots</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="center">Date Visite L-4</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="center">Date Visite L-1,5</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="center">Date Visite L</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {TranchesVisitesQualitesUtils.tranchesVisitesQualitesUtilsToReferentielsFront(homePageVQState.tranchesTravauxVisites).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover={true} classes={{ hover: classes.hover }} key={row.id_referentiel}>
                                    <TableCell onClick={() => handleItemClick(row)}> {row.codeProgramme + '-' + row.codeTranche} </TableCell>
                                    <TableCell onClick={() => handleItemClick(row)}>{row.villeProgramme}</TableCell>
                                    <TableCell onClick={() => handleItemClick(row)}>{row.libelleTranche}</TableCell>
                                    <TableCell align="center" onClick={() => handleItemClick(row)}>{row.nbLots}</TableCell>
                                    <TableCell align="center">{getDateCellLibelle(row.id_referentiel, TYPE_AUTOCONTROLE_L4, row.dateVisiteL4, row.colorVisiteL4)}</TableCell>
                                    <TableCell align="center">{getDateCellLibelle(row.id_referentiel, TYPE_AUTOCONTROLE_L15, row.dateVisiteL15, row.colorVisiteL15)}</TableCell>
                                    <TableCell align="center">{getDateCellLibelle(row.id_referentiel, TYPE_AUTOCONTROLE_L, row.dateVisiteL, row.colorVisiteL)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={homePageVQState.tranchesTravauxVisites.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={"Lignes par page"}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            // component="div"
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Alert severity='info' style={modalStyle} className={classes.paper + " info-alerte alert-text"}>
                    Périodes de livraison saisies dans l'autoévaluation:
                    <br />
                    <p>{getDialogText()}</p>
                    <div>
                        <Button className="btn-close-modal" onClick={() => handleClose()}>Fermer</Button>
                    </div>
                </Alert>
            </Modal>
        </div >
    );

};

const mapStateToProps = ({ homePageVQState, adminVisiteQualiteState, visiteQualiteState }: ApplicationState) => ({
    homePageVQState: homePageVQState,
    adminVisiteQualiteState: adminVisiteQualiteState,
    visiteQualiteState: visiteQualiteState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateAdminVisiteQualiteState: (params: AdminVisiteQualiteState) => dispatch({ type: AdminVisiteQualiteTypes.UPDATE_STATE, payload: params }),
        updateVisiteQualiteState: (params: VisiteQualiteState) => dispatch({ type: VisiteQualiteTypes.UPDATE_STATE, payload: params }),
        resetVisiteQualiteState: () => dispatch({ type: VisiteQualiteTypes.RESET_STATE }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageVQResult);