import React from 'react';
import '../Footer/Footer.css';
import Image from '../../assets/Background.svg'
import { Paper } from '@material-ui/core';
import Env from '../../utils/Env';

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`
  }
};

const versionBack = () => {
  return !!localStorage.getItem('version') ? removeRelease(localStorage.getItem('version')) : ''
}

const removeRelease = (value:String|null) => {
  if(!!value && value.includes("release-")){
    let tableValues = value.split("release-");
    return tableValues.length > 1 ? tableValues[1] : tableValues[0];
  }
  return value
}

const Footer: React.FC<{}> = () => {

  return (
    <>
      <Paper style={styles.paperContainer} className='app-img-background'></Paper>
      <span className="version">{ removeRelease(Env.REACT_APP_APP_VERSION) + "-" + removeRelease(versionBack())}</span>
    </>
  );
};

export default Footer;