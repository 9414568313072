import { TrancheTravauxVisites, VisiteQualite } from "./homePageVQ.Types";
import { FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";

export interface AdminVisiteQualiteState {
    data: TrancheTravauxVisites
}

export interface VisiteQualiteState {
    responsablesProgramme: FicheAutocontroleIntervenant[],
    conducteursTravaux: FicheAutocontroleIntervenant[],
    chargesMissionQualite: FicheAutocontroleIntervenant[],
    visiteQualiteL4: VisiteQualite,
    visiteQualiteL15: VisiteQualite,
    visiteQualiteL: VisiteQualite
}

export enum AdminVisiteQualiteTypes {
    UPDATE_STATE = "@@AdminVisiteQualite/UPDATE_STATE",
    RESET_STATE = "@@AdminVisiteQualite/RESET_STATE"
}

export enum VisiteQualiteTypes {
    UPDATE_STATE = "@@VisiteQualieState/UPDATE_STATE",
    RESET_STATE = "@@VisiteQualiteState/RESET_STATE"
}