import { FicheAutoControleCritere, FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";
import { TrancheTravauxLiee } from "./generalInformations.Types";

export interface TabSyntheseState {
    isCommentairesSaved: any;
    data: TabSynthese
    isSaved: boolean
    isDateSaved: boolean
    isPhotosSaved: boolean
    isIntervenantsSaved: boolean
    isValidationDirectionQualiteSaved: boolean
}

export interface TabSynthese {
    id_pk: string,
    id_sk: string,
    index: number,
    iconeTab: string,
    title: string,
    idUserCreated: string,
    idUserUpdated: string,
    createdAt: number,
    updatedAt: number,
    statut: string,
    infosGenerales: InfosGeneralesTabSynthese,
    photos: FicheAutoControleCritere[],
    commentaires: FicheAutoControleCritere[],
    commentaireCmq: FicheAutoControleCritere,
    tabs: tabTabSynthese[]
    destinatairesCC: string[],
    tranchesTravauxLiees: TrancheTravauxLiee[],
    validationDirectionQualite: FicheAutoControleCritere[]
}

export interface InfosGeneralesTabSynthese {
    responsableProgramme: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    CMQ: FicheAutocontroleIntervenant,
    directeurProgrammes: FicheAutocontroleIntervenant,
    directeurOperationnel: FicheAutocontroleIntervenant,
    responsableTechnique: FicheAutocontroleIntervenant,
    filiale: string,
    nbLots: number,
    programme: string,
    programmeCommercial: string,
    trancheTravaux: string,
    dateLimite: number,
    infosCriteras: InfosCriteraTabSynthese[]
    typeAutocontrole: string,
    dateOuverture: number,
    dateValidation: number,
    dateReunionInfos: number,
    datePremAG: number,
    dateLivraisonPartieCommunes: number,
    PeriodeLivraison: number[],
    dateLivraisionGPI: number,
    completionLM4: number,
    utilisateurValidateur: FicheAutocontroleIntervenant,
    advancement: number,
    regroupementResp: string
}

export interface tabTabSynthese {
    id: number,
    libelle: string,
    completion: number,
    completionTotale: number,
    percentCompletion: number,
    active: boolean,
    groups: groupTabSynthese[],
    advancement: number
}

export interface groupTabSynthese {
    id: number,
    libelle: string,
    completion: number,
    completionTotale: number,
    percentCompletion: number,
    active: boolean,
    criteresDesactived: string[],
    advancement: number
}

export interface InfosCriteraTabSynthese {
    id: string,
    title: string,
    value: number | number[] | string | string[]
}

export enum TabSyntheseStateActions {
    UPDATE_STATE = "@@tabSynthese/UPDATE_STATE",
    RESET_STATE = "@@tabSynthese/RESET_STATE",
    SET_ISSAVED = "@@tabSynthese/SET_ISSAVED",
    SET_ISDATESAVED = "@@tabSynthese/SET_ISDATESAVED",
    SET_ISPHOTOSSAVED = "@@tabSynthese/SET_ISPHOTOSSAVED",
    SET_ISCOMSSAVED = "@@tabSynthese/SET_ISCOMSSAVED",
    SET_ISINTERVENANTSSAVED = "@@tabSynthese/SET_ISINTERVENANTSSAVED",
    SET_ISVALDIRQUASAVED = "@@tabSynthese/SET_ISVALDIRQUASAVED"
}