import { AppBar, Toolbar, withStyles } from "@material-ui/core";

export const StyledToolbar = withStyles({
    root: {
      'color': '#fff',
      'background-color':'#ffffff',
      'height': '64px'
    }
  })(Toolbar);
  
  export const StyledAppBar = withStyles({
  root: {
    'color': '#fff',
    'background-color':'#ffffff'
  }
  })(AppBar);