import React from 'react';
import '../../SyntheseTabs/SyntheseTab.scss';
import { connect } from 'react-redux';
import { TabSynthese } from '../../../../store/types/TabSynthese.Types';
import { ApplicationState } from '../../../../store/store/store';
import DateUtils from "../../../../utils/DateUtils"
import IntervenantUtils from '../../../../utils/IntervenantUtils';
import { FicheAutocontroleIntervenant } from '../../../../store/types/FicheAutocontrole.Types';


interface PropsFromDispatch {
    tabSynthese: TabSynthese
}

const buildInfo = (title: string, value: string | number) => {
    if(!value || (value === "")) return;
    return <div className="synthese-info">
        <div className="info-title">{title}</div>  
        <div className="info-value">{value}</div>
    </div>
}

const buildInfoDate = (title: string, value: string | number, numberDate: number) => {
    if(!value || (value === "")) return;
    return <div className="synthese-info">
        <div className="info-title">{title}</div>  
        <div className="info-value"><span className={DateUtils.getDateLimiteColor(numberDate)}>{value}</span></div>
    </div>
}

const buildInfoInterv = (intervenant: FicheAutocontroleIntervenant) => {
    return !!intervenant && !!intervenant.nom ? intervenant.nom + IntervenantUtils.getExterneValue(intervenant) : '';
}

const SyntheseTab : React.FC<PropsFromDispatch> = ({tabSynthese}) =>{
    return (
        <div className="synthese-terrain">
            <div>
            {buildInfo("Chargé de mission qualité :", tabSynthese?.infosGenerales?.CMQ?.nom)}
            {buildInfo("Responsable de programme :", tabSynthese?.infosGenerales?.responsableProgramme?.nom)}
            {buildInfo("Conducteur de travaux :", buildInfoInterv(tabSynthese?.infosGenerales?.conducteurTravaux))}
            {buildInfo("Responsable technique :", buildInfoInterv(tabSynthese?.infosGenerales?.responsableTechnique))}
            {buildInfo("Directeur programme :", tabSynthese?.infosGenerales?.directeurProgrammes?.nom)}
            {buildInfo("Directeur opérationnel :", tabSynthese?.infosGenerales?.directeurOperationnel?.nom)}
            {buildInfoDate("Date limite de saisie :", DateUtils.dateBackToDateFront(tabSynthese?.infosGenerales?.dateLimite), tabSynthese?.infosGenerales?.dateLimite)}
            </div>
        </div>
    );
}
const mapStateToProps = ({ ficheAutocontrole, tabSyntheseState }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    tabSynthese: tabSyntheseState.data
});
export default connect(mapStateToProps)(SyntheseTab);