export interface PictureState {
    pic: Blob;
    name: string;
    type: string;
}

export interface PictureDownloadedState {
    id: string,
    name: string
}

export enum PictureTypes{
    ADD_PIC = "@@user/ADD_PIC",
    DELETE_PIC = "@@user/DELETE_PIC",
    DELETE_ALL_PIC = "@@user/DELETE_ALL_PIC"
}

export enum DownloadedPictureTypes{
    ADD_PIC = "@@user/ADD_PIC_DOWNLOAD",
    DELETE_PIC = "@@user/DELETE_PIC_DOWNLOAD",
    DELETE_ALL_PIC = "@@user/DELETE_ALL_PIC_DOWNLOAD"
}

export enum OldPictureTypes{
    ADD_OLD_PIC = "@@user/ADD_OLD_PIC",
    DELETE_OLD_PIC = "@@user/DELETE_OLD_PIC",
    DELETE_OLD_ALL_PIC = "@@user/DELETE_OLD_ALL_PIC"
}