import { Reducer } from "redux";
import { PictureState, PictureTypes } from "../types/pictures.Types";

export const initialState: PictureState []= [];

const reducer : Reducer<PictureState[]> = (state = initialState, action = {type:'', id:'', payload: {}, imgType: ''}) => {
    switch(action.type){
        case PictureTypes.ADD_PIC:{
            let copy  = [...state]
            let newPicc : PictureState = {
                pic: action.payload,
                name: action.name,
                type: action.imgType
            }
            copy.push(newPicc);
             
            return copy;
        }
        case PictureTypes.DELETE_PIC:{
            let copy  = [...state]
            const mapBool = copy.map(function(e) { return e.name.includes(action.id); });
            const indexToDelete = mapBool.indexOf(true);
            if(indexToDelete > -1) {
                copy.splice(indexToDelete, 1);
            }
            return copy;
        }
        case PictureTypes.DELETE_ALL_PIC:{
            return [];
        }
        default:{
            return state;
        }
    }
}

export {reducer as PicturesReducer};