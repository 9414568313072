import React from "react"
import { AUTOCONTROLE_STATUT } from "../store/types/generalInformations.Types"
import { userRoles } from "../store/types/user.Types";
import DateUtils from "./DateUtils"
import { SEVERITY } from "./constantes/contantes";
import { MessagesAlertState } from "../store/types/MessagesAlert.Types";
import { getUserRole } from "./AccessToken";
import { TypesMailEnum } from "../screens/Mail/typesMailEnum";

/**
 * Affiche un texte lorsque la date de saisie est antérieur à la date du jour
 * @param statut Statut de l'autocontrole
 * @param dateLimite la date limite de saisie de l'autocontrole
 * @returns le message si la date de saisie est antérieur sinon vide 
 */
const MessageDeadline = (statut:string, dateLimite:number) => {
    let result:any;
    
    (statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE && DateUtils.isBeforeNow(dateLimite)) ?
        result = <p className="date-expired">La date limite de saisie est dépassée</p>
    :
        result = ''

    return result
}

/**
 * Construit le message à renvoyer en fonction du role dans le cas d'un autocontrole inaccessible 
 * @returns un objet MessagesAlertState alimenté.
 */
export const MessageAutoControleInaccessible = (typeMail: string) => {
    const message: MessagesAlertState = {
        messages: [],
        severity: SEVERITY.ERROR
    }
    if(typeMail !== TypesMailEnum.RAPPEL_OUVERTURE){
        if(getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()){
            message.messages.push("La synthèse de l'autocontrôle de cette tranche travaux n'est plus accessible.");
        }
        else if(getUserRole().toLowerCase() === userRoles.terrain.toLowerCase()){
            message.messages.push("La synthèse de l'autocontrôle de cette tranche travaux n'est plus accessible.\n" +
            "Pour plus d'information, vous pouvez contacter votre chargé de mission Qualité.")
        } 
        else {
            message.messages.push("Impossible de charger la synthèse de l'autocontrôle.")
        }
    }
    
    return message;
}

export default MessageDeadline