import { FicheAutoControleCritere } from "../../store/types/FicheAutocontrole.Types"
import { ModeAffichage } from "../../utils/constantes/contantes"

/**
 * Classe utilitaire pour un @FicheAutoControleCritere
 */
export class CritereUtils {

    /**
     * Récupère l'état pour affichage du switch en fonction du critère et du mode d'affichage
     * @param critere le critère au format @FicheAutoControleCritere
     * @param mode le mode via l'enum @ModeAffichage
     * @returns true si le critere est actif, false sinon.
     */
    static getActiveStateFromCriteria = (critere: FicheAutoControleCritere, mode: ModeAffichage | undefined) => {
        if(!!mode && mode === ModeAffichage.SYNTHESE) {
            return false
        } else {
            return critere.active
        }
    }
}