import React from 'react';
import {FicheAutoControleCritere} from '../../../store/types/FicheAutocontrole.Types';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { connect } from 'react-redux';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core';


interface PropsFromState {
    critere:FicheAutoControleCritere,
    updateCritere: Function,
    disablePast: Boolean
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const DateAutocontrole: React.FC<AllProps> = ({critere, updateCritere, disablePast, goBackStateHasChanged}) => {

    const rootTheme = createMuiTheme({
        palette:{
            primary: {
                main: "#7d1e4f"
            }

        },
    });

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.activated = event.target.checked;
        updateCritere(critere);
        goBackStateHasChanged();
    };

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        (critere.value && critere.value !== 0 ) ? new Date(critere.value as number) : null
    );
    
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        critere.value = !!date ? new Date(date).getTime() : null;
        updateCritere(critere)
        goBackStateHasChanged();
    };

    return(<div>
                <div>
                    <LabelCritereAutocontroleStyled>{critere.title}
                    {critere.active ? 
                        <SwitchAutocontroleStyled className="elt-float-right"  checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt=""/>}/> 
                        : ""
                    }
                    </LabelCritereAutocontroleStyled>
                </div>

                <div>
                    <div className={"critera-date-box " + (!critere.activated ? "critera-date-box-disabled" : "") }>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <ThemeProvider theme={rootTheme}>
                                <KeyboardDatePicker
                                    disablePast= {!!disablePast}
                                    autoOk
                                    disableToolbar={false}
                                    showTodayButton={true}
                                    invalidDateMessage=''
                                    allowKeyboardControl
                                    className='widthInherit'
                                    helperText=''
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    placeholder='jj/mm/aaaa'
                                    margin="none"
                                    id="date"
                                    disabled={!critere.activated}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>);
};


const mapStateToProps = ({ goBackState }: ApplicationState) => ({
    goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DateAutocontrole);