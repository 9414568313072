import { Backdrop, CardMedia, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store/store/store';
import { FicheAutocontrole } from '../../store/types/FicheAutocontrole.Types';
import { AUTOCONTROLE_STATUT, GeneralInformationsState } from '../../store/types/generalInformations.Types';
import { OldPictureTypes } from '../../store/types/pictures.Types';
import { checkToken, isAuthApiOK, setAuthApi } from '../../utils/AccessToken';
import { API } from '../../utils/callAPI';
import DateUtils from '../../utils/DateUtils';
import IntervenantUtils from '../../utils/IntervenantUtils';
import Helper from '../Helper/Helper';
import { HelperTypeEnum } from '../Helper/HelperTypeEnum';

import PicProg from './../../assets/image_programme.png';

interface PropsFromDispatch {
    data: FicheAutocontrole,
    generalInformations: GeneralInformationsState,
    deleteOldPictures: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }),
);

const HomePageResult: React.FC<PropsFromDispatch> = ({ data, generalInformations, deleteOldPictures }) => {

    const [progMiniature, setProgMiniature] = React.useState<{ thumbnailUrl: string } | null>(null);
    const [loading, setLoading] = React.useState(false);

    const classes = useStyles();

    useEffect(() => {
        if (generalInformations.programme && generalInformations.programme.codeProgramme) {
            setLoading(true);
            deleteOldPictures();

            if (isAuthApiOK() && checkToken()) {
                setAuthApi(true);
                API.getProgrammePicture(generalInformations.programme.codeProgramme).then((resp) => {
                    setProgMiniature(resp?.data?.response);
                    setLoading(false);
                }).catch(err => {
                    console.log(err);
                    setAuthApi(false);
                    setProgMiniature(null);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalInformations.programme.codeProgramme]);

    const getTitleAcInaccessible = () => {
        return "Cette fiche autocontrôle est à renseigner par l'équipe chantier depuis la tranche " + data?.trancheTravauxPere?.codeTranche + " - " + data?.trancheTravauxPere?.libelleTranche
    }

    const getStatusMAJDate = () => {
        return data?.statutHisto && data?.statutHisto?.length > -1 ? data?.statutHisto[data.statutHisto?.length - 1].dateMAJ : 0;
    }

    const getStatus = () => {
        if (!data) {
            return AUTOCONTROLE_STATUT.FERME
        } else {
            return data?.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE ? data?.statut : data.statut + ' le ' + DateUtils.dateBackToDateFront(getStatusMAJDate())
        }
    }


    const getStatusClassDisplay = () => {
        switch (data?.statut) {
            case AUTOCONTROLE_STATUT.INACCESSIBLE: return "status-red";
            case AUTOCONTROLE_STATUT.OUVERT: return "status-bleu";
            case AUTOCONTROLE_STATUT.FERME: return "status-black";
            case AUTOCONTROLE_STATUT.RAPPORT_ENVOYE: return "status-green";
            case AUTOCONTROLE_STATUT.DELAI_DEPASSE: return "status-brown";
            case AUTOCONTROLE_STATUT.FICHE_FINALISEE: return "status-green";
            case AUTOCONTROLE_STATUT.FICHE_COMPLETEE: return "status-bleu";
            default: return "status-black";
        }
    }

    return (
        <div>
            {generalInformations.isDisplayed && !loading ?
                <div>
                    <div className='title-margin'>
                        <span className='page-title' >Récapitulatif </span>
                    </div>

                    <div className="bloc-field">
                        <span className="field-label">Nom commercial programme :</span>
                        <span className="field-value"> {generalInformations.programme.libelleCommercial}</span>
                    </div>
                    <div className="bloc-field">
                        <CardMedia
                            className="critera-picture-img-miniature"
                            component="img"
                            image={!!progMiniature && !!progMiniature.thumbnailUrl ? progMiniature.thumbnailUrl : PicProg}
                        />
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Nombre de lots :</span>
                        <span className="field-value"> {generalInformations.nbLots} lots</span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Date de livraison :</span>
                        <span className={"field-value " + DateUtils.getDateLimiteColor(generalInformations.dateLivraison)}> {DateUtils.dateBackToDateFront(generalInformations.dateLivraison)}</span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Date limite de saisie :</span>
                        <span className={"field-value " + DateUtils.getDateLimiteColor(generalInformations.dateLimite)}> {DateUtils.dateBackToDateFront(generalInformations.dateLimite)}</span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Responsable programme :</span>
                        <span className="field-value">
                            {!!generalInformations.responsableProgramme && !!generalInformations.responsableProgramme?.nom ? ' ' + generalInformations.responsableProgramme?.nom : ' A compléter'}
                        </span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Conducteur de travaux :</span>
                        <span className="field-value">
                            {!!generalInformations.conducteurTravaux && !!generalInformations.conducteurTravaux.nom
                                && generalInformations.conducteurTravaux?.nom !== '' ? ' ' + generalInformations.conducteurTravaux?.nom + IntervenantUtils.getExterneValue(generalInformations.conducteurTravaux) : ' A compléter'}
                        </span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Responsable Technique :</span>
                        <span className="field-value">
                            {!!generalInformations.responsableTechnique && !!generalInformations.responsableTechnique.nom
                                && generalInformations.responsableTechnique?.nom !== '' ? ' ' + generalInformations.responsableTechnique?.nom + IntervenantUtils.getExterneValue(generalInformations.responsableTechnique) : ' A compléter'}
                        </span>
                    </div>
                    <div className="bloc-field">
                        <span className="field-label">Chargé de mission qualité :</span>
                        <span className="field-value">
                            {!!generalInformations.chargeMissionQualite && !!generalInformations.chargeMissionQualite.nom
                                && generalInformations.chargeMissionQualite?.nom !== '' ? ' ' + generalInformations.chargeMissionQualite?.nom : ' A compléter'}
                        </span>
                    </div>

                    <div className='title-margin'>
                        <span className='page-title' >Statut de l'autocontrôle </span>
                        <div className={"status-field  " + getStatusClassDisplay()}>
                            <span className="status-text">{getStatus()}</span>
                        </div>
                    </div>
                    {getStatus() === AUTOCONTROLE_STATUT.INACCESSIBLE ?
                        <div className="mx-16"><Helper type={HelperTypeEnum.INFO} title={getTitleAcInaccessible()} /></div>
                        : ''}
                </div>
                : <></>}
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
};

const mapStateToProps = ({ ficheAutocontrole, generalInformations }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    generalInformations: generalInformations
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        deleteOldPictures: (name: string) => dispatch({ type: OldPictureTypes.DELETE_OLD_ALL_PIC })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageResult);