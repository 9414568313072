import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./screens/App";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import configureStore from "./store/store/configureStore";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './utils/AuthentificationConfig';
import { appInterceptor } from "./utils/http-common";
import { REDIRECT_HOME_PAGE } from './utils/constantes/contantes';
import { loadState, saveState, verifState } from './utils/StoreState';

export const msalInstance = new PublicClientApplication(msalConfig);
export const history = createBrowserHistory();

const peristedState = loadState();

export const store = configureStore(history, peristedState);

appInterceptor(store);

store.subscribe(() => {

  // vérification si le state n'est pas expiré depuis + de 12h
  if (verifState()) {
    // si le state n'est pas expiré on fait une sauvegarde
    saveState(store.getState());
  } else {

    // sinon on vide le state du local storage
    localStorage.removeItem('state');

    // on redirige vers la homepage
    window.location.href = REDIRECT_HOME_PAGE;
  }
});


ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App store={store} history={history} />
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
