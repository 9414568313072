import { TrancheTravauxVisites, TrancheTravauxVisitesFront, VisiteQualite } from "../store/types/homePageVQ.Types";
import DateUtils from "./DateUtils";
import { colorsTableauVisiteQualite } from "../styles/ColorsTableauVisiteQualite.style";
import { TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L } from "./constantes/contantes";
import { VisiteQualiteState } from "../store/types/adminVisiteQualite.Types";

export class TranchesVisitesQualitesUtils {

    static tranchesVisitesQualitesUtilsToReferentielsFront = (tranchesTravauxVisites: TrancheTravauxVisites[]) => {
        let resultat: TrancheTravauxVisitesFront[] = [];
        tranchesTravauxVisites.forEach(visite => {
            let ttv: TrancheTravauxVisitesFront = {
                id_referentiel: visite.id_referentiel,
                codeProgramme: visite.codeProgramme,
                codeTranche: visite.codeTranche,
                libelleTranche: visite.libelleTranche,
                nbLots: visite.nbLots,
                villeProgramme: visite.villeProgramme,
                dateLivraisonPrevue: visite.dateLivraisonPrevue,
                dateVisiteL4: DateUtils.addMonths(visite.dateLivraisonPrevue, -4),
                colorVisiteL4: colorsTableauVisiteQualite.black,
                dateVisiteL15: DateUtils.addDays(visite.dateLivraisonPrevue, -45),
                colorVisiteL15: colorsTableauVisiteQualite.black,
                dateVisiteL: visite.dateLivraisonPrevue,
                colorVisiteL: colorsTableauVisiteQualite.black
            }

            if (visite.dateRealisationAutoControleL4) {
                ttv.dateVisiteL4 = visite.dateRealisationAutoControleL4;
                ttv.colorVisiteL4 = colorsTableauVisiteQualite.green;
            }
            else if (visite.visiteQualiteLiees && visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L4)) {
                let visiteL4 = visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L4) as VisiteQualite;
                ttv.dateVisiteL4 = visiteL4.datePlanificationVisite ? visiteL4.datePlanificationVisite : ttv.dateVisiteL4;
                ttv.colorVisiteL4 = visiteL4.datePlanificationVisite ? colorsTableauVisiteQualite.orange : ttv.colorVisiteL4;
            }

            if (visite.dateRealisationAutoControleL15) {
                ttv.dateVisiteL15 = visite.dateRealisationAutoControleL15;
                ttv.colorVisiteL15 = colorsTableauVisiteQualite.green;
            }
            else if (visite.visiteQualiteLiees && visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L15)) {
                let visiteL15 = visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L15) as VisiteQualite;
                ttv.dateVisiteL15 = visiteL15.datePlanificationVisite ? visiteL15.datePlanificationVisite : ttv.dateVisiteL15;
                ttv.colorVisiteL15 = visiteL15.datePlanificationVisite ? colorsTableauVisiteQualite.orange : ttv.colorVisiteL15;
            }

            if (visite.visiteQualiteLiees && visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L)) {
                let visiteL = visite.visiteQualiteLiees.find((vq) => vq.typeVisiteQualite === TYPE_AUTOCONTROLE_L) as VisiteQualite;
                ttv.dateVisiteL = visiteL.datePlanificationVisite ? visiteL.datePlanificationVisite : ttv.dateVisiteL;
                ttv.colorVisiteL = visiteL.datePlanificationVisite ? colorsTableauVisiteQualite.orange : ttv.colorVisiteL;
            }

            resultat.push(ttv)
        })

        return resultat.sort((a, b) => a.codeProgramme.localeCompare(b.codeProgramme) || a.codeTranche.localeCompare(b.codeTranche));
    }

    static trancheVisiteQualiteToParams = (tranche: TrancheTravauxVisites, visiteState: VisiteQualiteState) => {
        let visites: VisiteQualite[] = [visiteState.visiteQualiteL4, visiteState.visiteQualiteL15, visiteState.visiteQualiteL];
        tranche.visiteQualiteLiees = visites;
        return tranche;
    }
}