import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store/store/store';
import { MessagesAlertState, MessagesAlertTypes, PositionsAlert } from '../../store/types/MessagesAlert.Types';


interface PropsFromDispatch {
    messagesAlertState: MessagesAlertState,
    resetMessagesAlertState: Function
}

const CustomSnackBar : React.FC<PropsFromDispatch> = ({messagesAlertState, resetMessagesAlertState}) =>{

const [open, setOpen] = React.useState(false);
const [positions, setPositions] = React.useState({vertical: 'bottom',horizontal: 'center',} as PositionsAlert);

useEffect(() => {
    if (messagesAlertState.messages.length > 0 && messagesAlertState.severity && !open) {
        if(messagesAlertState.position) {
            setPositions({
                horizontal: messagesAlertState?.position?.horizontal ? messagesAlertState.position.horizontal : positions.horizontal,
                vertical: messagesAlertState?.position?.vertical ? messagesAlertState.position.vertical : positions.vertical
            } as PositionsAlert)
        }
        setOpen(true);
    }
  }, [messagesAlertState, positions, open]);

const closeSnackBar = () => {
    resetMessagesAlertState()
    setOpen(false)
}
    
    return (
        <div>
            {messagesAlertState.messages.length > 0 ?
                <Snackbar 
                    open={open}
                    anchorOrigin={positions}
                    key={positions.vertical + positions.horizontal}
                    autoHideDuration={messagesAlertState.duration ? messagesAlertState.duration : 6000} 
                    onClose={closeSnackBar}>
                    <Alert onClose={closeSnackBar} severity={messagesAlertState.severity as Color}>
                        {messagesAlertState.messages.join('\n')}
                    </Alert>
                </Snackbar> : ''
            }
        </div>
    );
};
const mapStateToProps = ({ messagesAlertState }: ApplicationState) => ({
    messagesAlertState: messagesAlertState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        resetMessagesAlertState: () => dispatch({ type: MessagesAlertTypes.RESET_STATE}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackBar);