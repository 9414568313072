import { TextField } from "@material-ui/core";
import React from "react";
import { FicheAutocontrole } from "../../store/types/FicheAutocontrole.Types";
import { TabSynthese } from "../../store/types/TabSynthese.Types";
import { AUTOCONTROLE_STATUT, TrancheTravauxLiee } from "../../store/types/generalInformations.Types";
import { TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L4 } from "../../utils/constantes/contantes";
import DateUtils from "../../utils/DateUtils";
import CriteriaUtils from "../../utils/Criteria.utils";
import { getFormatString } from "./mailUtils";

interface PropsFromDispatch {
  ficheAutocontrole : FicheAutocontrole,
  tabSynthese: TabSynthese,
  disabled: any
}

let value:any;
let commentaireCmq:string;

const getCommentaireCmq = () => {
  return '';
}

export const getValueMail = () => {
  return value;
}

export const getCommentaireCmqMail = () => {
  return commentaireCmq ? commentaireCmq : '';
}

const getTrancheTravauxFormatted = (ficheAutocontrole:FicheAutocontrole,tranche: string, tranchesTravauxLiees: TrancheTravauxLiee[]) => {
  let formatted: JSX.Element[] = [];
  const filialeProg = ficheAutocontrole.tabInfos.filiale + ' - ' + ficheAutocontrole.tabInfos.programme + ' - ';
  formatted.push(<div key={tranche}><b> {filialeProg} {tranche}  - {ficheAutocontrole.tabInfos.nbLots} Lots </b></div>)
  if(tranchesTravauxLiees && tranchesTravauxLiees.length > 0){
      tranchesTravauxLiees
      .sort((a,b) => a.codeTranche.localeCompare(b.codeTranche))
      .forEach(tt => {
        formatted.push(<div><b>{filialeProg} {tt.codeTranche} - {tt.libelleTranche} - {tt.nbLots} Lots</b></div>);
      })
  }
  return formatted
}

const MailBody:React.FC<PropsFromDispatch> = ({ficheAutocontrole, tabSynthese, disabled}) => {
  
  const [commentaireCmqMail, setCommentaireCmqMail] = React.useState(getCommentaireCmq());
  let kaliti = CriteriaUtils.getValueCriteria(ficheAutocontrole, 'c_projkaliti').toString();
  let visiteL4 = !!tabSynthese && !!tabSynthese.infosGenerales ? CriteriaUtils.getValueCriteriaInfosGenerales(tabSynthese.infosGenerales.infosCriteras, 'c_vdqpllm4').toString() : '';
  let dateValid: string = DateUtils.formattedDate(DateUtils.getDateMajForStatut(ficheAutocontrole,AUTOCONTROLE_STATUT.FICHE_COMPLETEE));
  let dateLimite: string = DateUtils.formattedDate(tabSynthese?.infosGenerales?.dateLimite);
  let dateOuverture: string = DateUtils.formattedDate(DateUtils.getDateMajForStatut(ficheAutocontrole,AUTOCONTROLE_STATUT.OUVERT));
  let dateGPI: string = DateUtils.formattedDate(ficheAutocontrole?.tabInfos?.dateLivraisionGPI);

  const handleChangeCommentaireCmqMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentaireCmqMail(event.target.value);
    commentaireCmq = event.target.value;
  };

  value = (
    <div className="body-mail-rapport">
    <div>Bonjour</div>
    <br />
    {
      (ficheAutocontrole.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE) ?
      <>
        <div>Nous vous informons que la saisie de <b>l'autocontrôle {ficheAutocontrole.type}</b> n'a pas été réalisée dans les délais par les intervenants de la filiale.</div>
        <div>Lorsqu'un autocontrôle est disponible à la saisie, un mail est automatiquement envoyé aux responsables du chantier.</div>
        <div>Il est de la responsabilité de la filiale de décrire l'avancée d'un chantier sur l'outil MaCotation.</div>
        <div>La date limite de saisie ayant été dépassée, la direction Qualité considère donc que l'avancement de ce chantier ne peut être évalué.</div>
        {!!ficheAutocontrole && !!ficheAutocontrole.tabInfos ? getTrancheTravauxFormatted(ficheAutocontrole,ficheAutocontrole.tabInfos.trancheTravaux, ficheAutocontrole.tranchesTravauxLiees as TrancheTravauxLiee[]) : ''}
      </>
      :
      <>
      <div>Suite à <b>l'autocontrôle {ficheAutocontrole.type}</b> réalisé et relatif au programme visé en objet, je vous prie de bien vouloir trouver le rapport ci-joint.</div>
      {!!ficheAutocontrole && !!ficheAutocontrole.tabInfos ? getTrancheTravauxFormatted(ficheAutocontrole,ficheAutocontrole.tabInfos.trancheTravaux, ficheAutocontrole.tranchesTravauxLiees as TrancheTravauxLiee[]) : ''}
      <br />
      {
        ((tabSynthese?.infosGenerales?.advancement < 60) && (ficheAutocontrole.type === TYPE_AUTOCONTROLE_L4)) ?
        <>
          <div>La note étant inférieure aux <b>60%</b> requis pour l'autocontrôle <b>L-4</b>, merci de nous faire parvenir un plan d'action <b>sous quinze jours</b> selon la trame ci-jointe.</div>
        </> : ''
      }
      {
        ((tabSynthese?.infosGenerales?.advancement < 85) && (ficheAutocontrole.type === TYPE_AUTOCONTROLE_L15)) ?
        <>
          <div>La note étant inférieure aux <b>85%</b> requis pour l'autocontrôle <b>L-1,5</b>, merci de nous faire parvenir un plan d'action <b>sous quinze jours</b> selon la trame ci-jointe.</div>
        </> : ''
      }
      </>
    }
    <br />
    
    <TextField 
      id="body-commentaire-cmq"
      className="commentaire-cmq-mail"
      placeholder="[Utilisez cet espace pour renseigner des informations complémentaires]"
      multiline
      fullWidth
      value={commentaireCmqMail}
      onChange={handleChangeCommentaireCmqMail}
      inputProps={{ maxLength: 1000 }}
    />
    <br />
    
    {
      (ficheAutocontrole.type === TYPE_AUTOCONTROLE_L4 && ficheAutocontrole.statut !== AUTOCONTROLE_STATUT.DELAI_DEPASSE) ?
      <>
        {!!visiteL4 ? <div>Visite direction filiale effectuée avant L-4 (L-5) : <b>{getFormatString(visiteL4)}</b></div> : ''}
        {!!kaliti ? <div>Création du projet KALITI : <b>{getFormatString(kaliti)}</b></div> : ''}
      </>
      : ''
    }
    
    {
      (ficheAutocontrole.statut !== AUTOCONTROLE_STATUT.DELAI_DEPASSE) ?
        dateValid !== "-" ? <div>Date de validation de l'autocontrôle : <b>{dateValid}</b></div> : ''
      : ''
    }
    {
      (ficheAutocontrole.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE) ?
        <>
          <div>Date d'ouverture de l'autocontrôle : <b>{dateOuverture}</b></div>
          <div>Date limite de saisie de l'autocontrôle : <b>{dateLimite}</b></div>
        </>
        : ''
    }
    <div>Date de livraison GPI : <b>{dateGPI}</b></div>
    
    {
      (ficheAutocontrole.type === TYPE_AUTOCONTROLE_L15 &&
        tabSynthese?.infosGenerales?.PeriodeLivraison[0] && tabSynthese?.infosGenerales?.PeriodeLivraison[1] &&
        tabSynthese.infosGenerales.PeriodeLivraison[0] !== 0 && tabSynthese.infosGenerales.PeriodeLivraison[1] !== 0)?
        <div>Période de livraison : {DateUtils.dateBackToDateFront(tabSynthese.infosGenerales.PeriodeLivraison[0])}
        à {DateUtils.dateBackToDateFront(tabSynthese.infosGenerales.PeriodeLivraison[1])}</div>
        :''
    }
    
    <br />
    <div>Vous pouvez continuer à suivre les réserves de vos visites chantier sur <b>Kaliti</b>.</div>
    <div>Commencez dès à présent les évaluations de vos entreprises sur <b>Scortex</b>.</div>
    <div>Restez informés en continu en vous abonnant à la <b>Communauté Nexity Live DQPL</b>.</div>
    <br />
    <div>Restant à votre disposition pour tout renseignement complémentaire.</div>
    <br />
    <div>Cordialement,</div>
    <div>La Direction Qualité</div>
      
  </div>
  );

  return value;

};
    
export default MailBody;