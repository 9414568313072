import React from 'react';
import '../SyntheseTabs/SyntheseTab.scss';
import './SynthesePhotos.scss'
import { TabSynthese, TabSyntheseStateActions } from '../../../store/types/TabSynthese.Types';
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import PictureAutocontrole from '../../Criteres/TypesCriteres/PictureAutocontrole';
import { FicheAutoControleCritere } from '../../../store/types/FicheAutocontrole.Types';
import { AUTOCONTROLE_STATUT } from '../../../store/types/generalInformations.Types';
import { ModeAffichage } from '../../../utils/constantes/contantes';


interface PropsFromDispatch{
    tabSynthese: TabSynthese,
    setIsSaved: Function,
    setIsPhotosSaved: Function,
    updateTabSyntheseState: Function

}

const SynthesePhotos : React.FC<PropsFromDispatch> = ({tabSynthese, setIsSaved, setIsPhotosSaved, updateTabSyntheseState}) =>{

    const saveCriteria = (critere:FicheAutoControleCritere) => {
        const indexPhoto = tabSynthese.photos.findIndex((p:FicheAutoControleCritere) => critere.id === p.id);
        tabSynthese.photos[indexPhoto] = critere;
        setIsSaved(false)
        setIsPhotosSaved(false)
        updateTabSyntheseState(tabSynthese);
    }


    /**
     * Affichage photo
     * @param photos 
     * @returns 
     */
    const buildPhotosForSynthese = (photos: any) => {
        if(!photos) return ;
        return photos.map((photo:any) => {
            photo.activated = (tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE);
            return (<div key={photo.id} className="photo">
                <PictureAutocontrole critere={photo} updateCritere={saveCriteria} modeAffichage={ModeAffichage.SYNTHESE}/>
            </div>)
        });
    }    
    
    return (
        <div className="synthese-tab">
            <div className="synthese-tab-header">
                <div className="synthese-title-completion">
                    <div className="synthese-tab-title">
                        <span>Photos</span>
                    </div>
                </div>
            </div>
            <div className="synthese-bloc-photos">
                {buildPhotosForSynthese(tabSynthese.photos)}
            </div>
        </div>
    );
};
const mapStateToProps = ({tabSyntheseState}: ApplicationState) => ({
    tabSynthese: tabSyntheseState.data,
    isSaved: tabSyntheseState.isSaved,
    isPhotosSaved: tabSyntheseState.isPhotosSaved
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({type: TabSyntheseStateActions.SET_ISSAVED, payload: params}),
        setIsPhotosSaved: (params: boolean) => dispatch({type: TabSyntheseStateActions.SET_ISPHOTOSSAVED, payload: params}),
        updateTabSyntheseState: (data:TabSynthese) => dispatch({type: TabSyntheseStateActions.UPDATE_STATE, payload: data})
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SynthesePhotos);
