import { Reducer } from "redux";
import { DateState, DateStateTypes } from '../types/date.Types';

export const initialState: DateState = {
    date: null
}

const reducer: Reducer<DateState> = (state = initialState, action = { type: '', payload: '' }) => {

    switch (action.type) {
        case DateStateTypes.SET_DATE: {
            let copy = { ...state }
            copy = action.payload;
            return { ...copy };
        }
        default: {
            return state;
        }
    }

}

export { reducer as DateStateReducer };