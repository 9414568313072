import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import '../Navbar/NavBar.css'
import { StyledAppBar, StyledToolbar } from '../../styles/NavBar.style';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/store/store';
import { CurrentState } from '../../store/types/CurrentState.Types';
import Logo from "./../../assets/ma-cotation-logo.svg";
import { REDIRECT_HOME_PAGE } from '../../utils/constantes/contantes';
import { TabSyntheseStateActions } from '../../store/types/TabSynthese.Types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { Button } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions";
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../store/types/GoBackState.Types';
import { DownloadedPictureTypes, OldPictureTypes } from '../../store/types/pictures.Types';
import { getAccessToken } from '../../utils/AccessToken';

interface PropsFromState {
  children: React.ReactElement;

}

interface PropsFromDispatch {
  currentState: CurrentState;
  resetTabSynthese: Function;
  goBackState: GoBackCurrentState;
  initGoBackState: Function;
  deleteOldPictures: Function;
  deleteDownloadedPicsFromStore: Function
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AllProps = PropsFromState & PropsFromDispatch;

const NavBar: React.FC<AllProps> = ({ deleteDownloadedPicsFromStore, children, currentState, resetTabSynthese, goBackState, initGoBackState, deleteOldPictures }) => {

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const updateMedia = () => {
    setWidthScreen(window.innerWidth);
  };

  const goToHome = () => {
    getAccessToken();
    if (goBackState.isCurrentChange) {
      setOpen(true);
    } else {
      quitParameters()
    }
  }

  const quitParameters = () => {
    deleteDownloadedPicsFromStore();
    resetTabSynthese();
    setOpen(false);
    initGoBackState();
    deleteOldPictures();
    history.push(REDIRECT_HOME_PAGE);
    window.location.reload();
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <React.Fragment >
      <CssBaseline />
      {/* le menu doit pas s'afficher pour les screens mobile quand on choisi un groupe */}
      {widthScreen < 952 && currentState.currentGroup > -1 ? '' : (
        <StyledAppBar position="static">
          <StyledToolbar>
            <img className="img-navbar" src={Logo} onClick={goToHome} alt="" />
          </StyledToolbar>
        </StyledAppBar>
      )}
      <Container className='card'>
        {children}
      </Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span>Êtes vous sûr de vouloir abandonner votre saisie ? <br /> Les données saisies seront perdues.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => quitParameters()} color="primary">
            OUI
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            NON
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ currentState, goBackState, downloadedPictures }: ApplicationState) => ({
  currentState: currentState,
  goBackState: goBackState,
  downloadedPictures: downloadedPictures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    resetTabSynthese: () => dispatch({ type: TabSyntheseStateActions.RESET_STATE }),
    initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE }),
    deleteOldPictures: () => dispatch({ type: OldPictureTypes.DELETE_OLD_ALL_PIC }),
    deleteDownloadedPicsFromStore: () => dispatch({ type: DownloadedPictureTypes.DELETE_ALL_PIC })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);