import { FicheAutocontrole } from "../store/types/FicheAutocontrole.Types";
import { InfosCriteraTabSynthese } from "../store/types/TabSynthese.Types";


export default class CriteriaUtils{

    /**
     * Retourne la valeur du critère demandé via son id pour les tabs dynamiques
     * @param ficheAutocontrole fiche autocontrole
     * @param idCriteria criteria's id
     * @returns criteria value
     */
    static getValueCriteria = (ficheAutocontrole:FicheAutocontrole, idCriteria:string) => {
        let result:any = '-';

        ficheAutocontrole.tabs.forEach(tab => {
            tab.groups.forEach(g=>{
                g.criterias.forEach(c=>{
                    if(c.id === idCriteria){
                        result = (c.value !== null || c.value !== undefined) ? c.value : '-';
                    }
                })
            })
        });
        return result;
    }

    /**
     * Retourne la valeur du critère demandé pour le tab $1 (tabInfos)
     * @param infosCriterias partie dynamique des critères de la page "Informations générales"
     * @param idCriteria criteria's id
     * @returns criteria value
     */
    static getValueCriteriaInfosGenerales = (infosCriterias: InfosCriteraTabSynthese[], idCriteria:string) => {
        const criteriaObject = infosCriterias.find(info => info.id === idCriteria)
        return criteriaObject ? criteriaObject.value : '-'
}
}