import React from "react";
import HomePageVQSearch from "../../components/homePageVQ/HomePageVQSearch";
import "./HomePageVQ.css";

const HomePageVQ: React.FC<{}> = () => {

    /**
     * Display
     */
    return (
        <div>
            <div className='card-content'>
                <HomePageVQSearch />
            </div>
        </div>
    );
};

export default HomePageVQ;
