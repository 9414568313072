import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { msalInstance } from '..';
import { getIdTokenFromRefreshToken } from './RefreshToken';

/**
 * Try Catch si jamais le token n'est pas valide pour éviter la page blanche
 * @param token 
 * @returns 
 */
export const jwt_decode_ = (token: string) => {
    try {
        return jwt_decode(token) as any
    } catch (e) {
        console.error(e)
    }
}

/**
 * Permet d'alimenter le localstorage avec un idToken valide
 */
export async function getAccessToken() {
    const accounts = msalInstance.getAllAccounts();
    return new Promise((resolve, reject) => {
        if (accounts.length > 0) {
            const request = {
                scopes: ["User.Read"],
                account: accounts[0]
                //prompt: 'login'
            }

            msalInstance.acquireTokenSilent(request)
                .then(async (response) => {
                    if (!checkToken(response.idToken)) {
                        await getIdTokenFromRefreshToken(response)
                            .then((token) => {
                                if (token && token !== '') {
                                    setLocalStorage(response, token);
                                    resolve(token);

                                } else {
                                    console.log('error on generate idToken fron refreshToken');
                                    resolve('');
                                }
                            })
                    } else {
                        setLocalStorage(response);
                        resolve(response.idToken);
                    }
                }).catch(error => {
                    console.log(error);

                    if (error instanceof InteractionRequiredAuthError) {
                        clearLocalStorage();
                        msalInstance.acquireTokenRedirect(request);
                    }
                });
        }
    })
}

/**
 * Alimente le local storage
 */
const setLocalStorage = (response: AuthenticationResult, idToken?: string) => {

    // stockage des nouvelles données
    localStorage.setItem("accessToken", idToken ? idToken : response.idToken);
}

/**
 * Clear du localStorage 
 */
export const clearLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('role');
    localStorage.removeItem('rgpd');
    localStorage.removeItem('state');
}

/**
 * Renvoie le mail de l'utilisateur
 */

export const getUserMail = () => {
    if (!localStorage.getItem('accessToken')) {
        return "";
    } else {
        let decodedToken: { preferred_username: string } = jwt_decode_(localStorage.getItem('accessToken') as string)
        let mail: string = decodedToken?.preferred_username.toString().toLowerCase();
        return mail
    }
}

/**
 * Renvoie le role de l'utilisateur
 */

export const getUserRole = () => {
    if (!localStorage.getItem('role')) {
        return "";
    } else {
        let decodedToken: { role: string } = jwt_decode_(localStorage.getItem('role') as string)
        let role: string = !!decodedToken?.role ? decodedToken.role.toString().toUpperCase() : '';
        return role
    }
}

/**
 * Renvoie les filiales de l'utilisateur
 */

export const getUserFiliales = () => {
    if (!localStorage.getItem('role')) {
        return [];
    } else {
        let decodedToken: { role: string, companies: Array<string> } = jwt_decode_(localStorage.getItem('role') as string)
        let companies: Array<string> = decodedToken?.companies;
        return companies
    }
}

/**
 * Vérifie la validité du token et fait un refresh token si besoin
 * @param token le token d'identification JWT
 * @returns true si token conforme, false sinon 
 */
export const isAccessTokenValid = (): boolean => {
    if (!localStorage.getItem('accessToken')) {
        return false;
    } else {
        // on vérifie la validité du token
        return checkToken();;
    }
}

/**
 * Vérifie la validité du token
 * @param token le token d'identification JWT
 * @returns true si token conforme, false sinon 
 */
export const checkToken = (token?: string): boolean => {

    let local_token = token ? token : localStorage.getItem('accessToken');

    if (!local_token) {
        return false;
    }

    let decodedToken: { preferred_username: string, name: string, exp: number } = jwt_decode_(local_token as string)
    // ! la date d'expiration reçue via le token est sur 10 digits (et ma cotation 13) !
    const isTokenExpired: boolean = (new Date().getTime()) > (!!decodedToken?.exp ? decodedToken?.exp * 1000 : 0)
    const mail: string = decodedToken?.preferred_username.toString().toLowerCase();
    const name: string = decodedToken?.name.toString().toLowerCase();
    const ok = !isTokenExpired && !!mail && !!name;
    return ok;
}

/**
 * Stocke dans le local storage true ou false en fonction du retour d'appel d'une api
 * @param ok true/false
 */
export const setAuthApi = (ok: boolean) => {
    let authapi = { state: ok, date: Number(new Date()) };
    localStorage.setItem('authapi', JSON.stringify(authapi));
}

/**
 * Indique si l'appel d'api a été fait avec succès (gestion de l'appel en boucle de roles sur une erreur 401, 403, ...)
 * @returns true / false
 */
export const isAuthApiOK = () => {
    let json_authapi = localStorage.getItem('authapi');
    if (json_authapi) {
        let authapi = JSON.parse(json_authapi);
        let now = Number(new Date());
        let auth_date = Number(authapi.date);
        let diff = (now - auth_date);
        if (diff > 10000) {
            setAuthApi(true);
            return true;
        }
        return authapi.state;
    }
    return true;
}