import React from 'react';
import './SyntheseTab.scss';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { groupTabSynthese, tabTabSynthese } from '../../../store/types/TabSynthese.Types';
import { CurrentStateActions } from '../../../store/types/CurrentState.Types';
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { getUserRole } from '../../../utils/AccessToken';
import { userRoles } from '../../../store/types/user.Types';

interface PropsFromState {
    tab: tabTabSynthese,
    isClickable: boolean
}

interface PropsFromDispatch{
    updateCurrentPage: Function,
    updateCurrentGroup: Function,
}

type AllProps = PropsFromState & PropsFromDispatch;

const SyntheseTab : React.FC<AllProps> = ({tab, isClickable, updateCurrentGroup, updateCurrentPage}) =>{

    const updatePageAndGroup = (page: number, group: number) => {
        if(isClickable){
            window.scroll(0,0)
            updateCurrentPage(page)
            updateCurrentGroup(group)
        }
    }

    const buildGroup = (groups: groupTabSynthese[]) => {
        if(!groups) { return }
        return groups.map((group:groupTabSynthese, index:number) => 
        <div key={group.id.toString().concat(group.libelle)} className={isClickable ? "cursor-pointer group" : "group"} onClick={() => updatePageAndGroup(tab.id, index)}>
            <span className="groupe-title">{group.libelle}</span>
            <div className="synthese-tab-completion">
            {group.active ?
            <div>
                <span className={group.completion === group.completionTotale ? 'tab-completed' : 'tab-uncompleted'}>
                    [<span className={group.completion === group.completionTotale ? 'text-completed' : 'text-uncompleted'}>{group.completion}/{group.completionTotale}</span>]
                </span>
            </div> :
            <div className='tab-inactive'>[<span className="text-inactive">Désactivé</span>]</div>
            }
        </div>
        {group.active && group.criteresDesactived && group.criteresDesactived.length > 0 ? 
        buildInactiveCriterias(group.criteresDesactived) : ''
        }
    </div>
    );
    }

    const buildInactiveCriterias = (criterias: string[]) => {
        if(!criterias) { return }
        return criterias.map((criteria:string) => 
        <div className="synthese-criteria">{criteria}</div>
        );
    }
    
    
    return (
        <div className="synthese-tab">
            <div className={isClickable ? "synthese-tab-header cursor-pointer" : "synthese-tab-header"} onClick={() => updatePageAndGroup(tab.id, -1)}>
                <div className="synthese-title-completion">
                    <div className="synthese-tab-title">
                        <span>{tab.libelle}</span>
                    </div>
                    <div className="synthese-tab-completion">
                        {tab.active ?
                        <div>
                            <span className={tab.completion === tab.completionTotale ? 'tab-completed' : 'tab-uncompleted'}>
                                [<span className={tab.completion === tab.completionTotale ? 'text-completed' : 'text-uncompleted'}>{tab.completion}/{tab.completionTotale} critères</span>]
                            </span>
                            { (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase() && tab.advancement !== -1) ? 
                            <span className={'tab-progress ' + (tab.advancement < 100 ? 'tab-uncompleted' : 'tab-completed')}>
                                [<span className={tab.advancement < 100 ? 'text-uncompleted' : 'text-completed'}>{tab.advancement}%</span>]
                            </span> : '' } 
                        </div> :
                        <div className='tab-inactive'>[<span className="text-inactive">Désactivé</span>]</div>
                        }
                    </div>
                </div>
                <div className="synthese-right-arrow"><ArrowForwardIosIcon/></div>
            </div>
                {tab.active && tab.groups && tab.groups.length > 0 ?
                <div className="synthese-tab-groups">
                    {buildGroup(tab.groups) }
                </div>
                : '' }
        </div>
    );
};
const mapStateToProps = ({currentState}: ApplicationState) => ({
    currentState: currentState,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    updateCurrentPage: (params: number) => dispatch({type: CurrentStateActions.CHANGE_PAGE, params: params}),
    updateCurrentGroup: (params: number) => dispatch({type: CurrentStateActions.CHANGE_GROUP, params: params}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SyntheseTab);