import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../store/store";
import { CurrentStateActions } from "../types/CurrentState.Types";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const updateCurrentPage : AppThunk = (params) => {
    return (dispatch: Dispatch): Action => {
     return dispatch({
          type: CurrentStateActions.CHANGE_PAGE,
          params: params
        });
    };
  };

export const updateCurrentGroup : AppThunk = (params) => {
  return (dispatch: Dispatch): Action => {
    return dispatch({
        type: CurrentStateActions.CHANGE_GROUP,
        params: params
      });
  };
};

export const updateIsTabInfos : AppThunk = (params) => {
  return (dispatch: Dispatch): Action => {
    return dispatch({
        type: CurrentStateActions.IS_TABINFOS,
        params: params
      });
  };
};