import { TrancheTravaux, TrancheTravauxLiee } from "../../store/types/generalInformations.Types";


export const TranchesTravauxToTranchesTravauxLiées = (tranchesTravaux: TrancheTravaux[]) => {
    const tranchesLiees: TrancheTravauxLiee[] = [];
    tranchesTravaux.forEach(tt => {
        const newTrancheLiee: TrancheTravauxLiee = {
            codeTranche: tt.codeTranche,
            libelleTranche: tt.libelleTranche,
            id_referentiel: tt.id_referentiel,
            nbLots: tt.nbLots
        }
        tranchesLiees.push(newTrancheLiee);
    })
    return tranchesLiees
}