import { FicheAutocontrole, FicheAutocontroleIntervenant, FicheAutocontroleTabInfos } from "../../store/types/FicheAutocontrole.Types";
import { AUTOCONTROLE_STATUT, Filiale, GeneralInformationsState, Programme, TrancheTravaux, TrancheTravauxLiee } from "../../store/types/generalInformations.Types";
import { infosCotation } from "../../store/types/homePage.Types";
import { IntervenantsState } from "../../store/types/intervenants.Types";
import { TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L15_API, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L4_API } from "../constantes/contantes";
import { emptyFiliale, emptyProgramme, emptyTabInfosIntervenant, emptyTrancheTravaux } from "../constantes/emptyObjects";
import DateUtils from "../DateUtils";
import { ReferentielUtils } from "../ReferentielUtils";
import { intervenantToTabInfosIntervenant } from "./intervenantMapper";


export const homePageResultToGeneralInformations = (generalInformations:GeneralInformationsState, typeAutocontrole:string) => {
    generalInformations.responsableProgramme = emptyTabInfosIntervenant;
    generalInformations.conducteurTravaux = emptyTabInfosIntervenant;
    generalInformations.chargeMissionQualite = emptyTabInfosIntervenant;
    generalInformations.responsableTechnique = emptyTabInfosIntervenant;
    generalInformations.directeurOperationnel = emptyTabInfosIntervenant;
    generalInformations.directeurProgrammes = emptyTabInfosIntervenant;
    generalInformations.ficheStatut = AUTOCONTROLE_STATUT.FERME;
    generalInformations.typeAutocontrole = typeAutocontrole;
    generalInformations.tranchesTravauxLiees = [] as TrancheTravauxLiee[]

    return generalInformations;
}

export const trancheTravauxToGeneralInformations = (generalInformations:GeneralInformationsState, tranche:TrancheTravaux, typeAC:string) => {
    generalInformations.nbLots = tranche.nbLots
    generalInformations.ficheStatut = AUTOCONTROLE_STATUT.FERME;
    generalInformations.dateLivraison = tranche.dateLivraisonPrevue;
    generalInformations.dateLimite = DateUtils.getDateLimiteFromDateLivraison(tranche.dateLivraisonPrevue, typeAC);
    generalInformations.typeAutocontrole = typeAC;
    generalInformations.tranchesTravauxLiees = [] as TrancheTravauxLiee[]

    return generalInformations;
}

export const ficheAutocontroleToGeneralInformations = (generalInformations:GeneralInformationsState, ficheAutocontrole:FicheAutocontrole) => {
    generalInformations.responsableProgramme = (ficheAutocontrole?.tabInfos?.responsableProgramme) ? ficheAutocontrole.tabInfos.responsableProgramme : emptyTabInfosIntervenant;
    generalInformations.conducteurTravaux = (ficheAutocontrole?.tabInfos?.conducteurTravaux) ? ficheAutocontrole.tabInfos.conducteurTravaux : emptyTabInfosIntervenant;
    generalInformations.chargeMissionQualite = ficheAutocontrole?.tabInfos?.CMQ ? ficheAutocontrole.tabInfos.CMQ : emptyTabInfosIntervenant;
    generalInformations.responsableTechnique = ficheAutocontrole?.tabInfos?.responsableTechnique ? ficheAutocontrole.tabInfos.responsableTechnique : emptyTabInfosIntervenant;
    generalInformations.directeurOperationnel = ficheAutocontrole?.tabInfos?.directeurOperationnel ? ficheAutocontrole.tabInfos.directeurOperationnel : emptyTabInfosIntervenant;
    generalInformations.directeurProgrammes = ficheAutocontrole?.tabInfos?.directeurProgrammes ? ficheAutocontrole.tabInfos.directeurProgrammes : emptyTabInfosIntervenant;
    generalInformations.ficheStatut = ficheAutocontrole?.statut as string;
    generalInformations.dateLimite = ficheAutocontrole?.tabInfos?.dateLimite;
    generalInformations.dateLivraison = ficheAutocontrole?.tabInfos?.dateLivraisionGPI;
    generalInformations.typeAutocontrole = ficheAutocontrole?.type;
    generalInformations.tranchesTravauxLiees = ficheAutocontrole?.tranchesTravauxLiees;
    generalInformations.nbLots = ficheAutocontrole?.tabInfos?.nbLots;
    generalInformations.filiale = emptyFiliale as Filiale;
    generalInformations.programme = emptyProgramme as Programme;
    generalInformations.trancheTravaux = emptyTrancheTravaux as TrancheTravaux;
    
    return generalInformations;
}

export const tabInfosToGeneralInformations = (generalInformations:GeneralInformationsState, tabInfos:FicheAutocontroleTabInfos) => {
    generalInformations.responsableProgramme = tabInfos?.responsableProgramme? tabInfos.responsableProgramme : emptyTabInfosIntervenant;
    generalInformations.conducteurTravaux = tabInfos?.conducteurTravaux ? tabInfos.conducteurTravaux : emptyTabInfosIntervenant;
    generalInformations.chargeMissionQualite = tabInfos?.CMQ ? tabInfos.CMQ : emptyTabInfosIntervenant;
    generalInformations.responsableTechnique = tabInfos?.responsableTechnique ? tabInfos.responsableTechnique : emptyTabInfosIntervenant;
    generalInformations.dateLimite = tabInfos?.dateLimite ? tabInfos.dateLimite : 
      DateUtils.getDateLimiteFromDateLivraison(generalInformations.dateLivraison, generalInformations.typeAutocontrole);
    generalInformations.dateLivraison = tabInfos?.dateLivraisionGPI ? tabInfos?.dateLivraisionGPI : generalInformations?.dateLivraison;

    return generalInformations;
}

export const tranchesTravauxLieesToGeneralInformations = (generalInformations:GeneralInformationsState,tranchesTravaux: TrancheTravaux[]) => {
    let tranchesTravauxLiees = [] as TrancheTravauxLiee[]
    tranchesTravaux.forEach(tt => {
        tranchesTravauxLiees.push({id_referentiel: tt.id_referentiel, codeTranche: tt.codeTranche, libelleTranche: tt.libelleTranche, nbLots: tt.nbLots})
    })
    generalInformations.tranchesTravauxLiees = tranchesTravauxLiees;
}

export const GeneralInformationsTotabInfos = (generalInformations:GeneralInformationsState, tabInfos:FicheAutocontroleTabInfos) => {
    tabInfos.conducteurTravaux = generalInformations.conducteurTravaux;
    tabInfos.responsableProgramme = generalInformations.responsableProgramme;
    tabInfos.responsableTechnique = generalInformations.responsableTechnique;
    tabInfos.CMQ = generalInformations.chargeMissionQualite;
    tabInfos.dateLimite = new Date(generalInformations.dateLimite).getTime();
    return tabInfos;
}

export const parametresAutocontroleStateToGeneralInformations = (generalInformations:GeneralInformationsState, parametresAutocontroleState:IntervenantsState, values:any) => {

    if(!!parametresAutocontroleState.chargesMissionQualite && (!generalInformations.chargeMissionQualite || generalInformations.chargeMissionQualite.nom !== values.valueCMQ)){
        const cmq = parametresAutocontroleState.chargesMissionQualite
            .find((intervenant:FicheAutocontroleIntervenant) => intervenant.nom === values.valueCMQ as string) as FicheAutocontroleIntervenant;
        generalInformations.chargeMissionQualite = cmq ? cmq : emptyTabInfosIntervenant
    }
    
    if(!!parametresAutocontroleState.responsablesProgramme && (!generalInformations.responsableProgramme || generalInformations.responsableProgramme.nom !== values.valueRPR)){
        const responsableProgramme = parametresAutocontroleState.responsablesProgramme
            .find((intervenant:FicheAutocontroleIntervenant) => intervenant.nom === values.valueRPR as string) as FicheAutocontroleIntervenant;
            generalInformations.responsableProgramme = responsableProgramme ? responsableProgramme : emptyTabInfosIntervenant;    
    }
    if(!!parametresAutocontroleState.responsablesTechnique && (!generalInformations.responsableTechnique || generalInformations.responsableTechnique.nom !== values.valueRTE)){
        const responsableTechnique = parametresAutocontroleState.responsablesTechnique
            .find((intervenant:FicheAutocontroleIntervenant) => intervenant.nom === values.valueRTE as string) as FicheAutocontroleIntervenant;
        generalInformations.responsableTechnique = responsableTechnique ? responsableTechnique : emptyTabInfosIntervenant;
    } 
    
    if(!!parametresAutocontroleState.conducteursTravaux && (!generalInformations.conducteurTravaux || generalInformations.conducteurTravaux.nom !== values.valueCTR)){
        const conducteurTravaux = parametresAutocontroleState.conducteursTravaux
            .find((intervenant:FicheAutocontroleIntervenant) => intervenant.nom === values.valueCTR as string) as FicheAutocontroleIntervenant;
        generalInformations.conducteurTravaux = conducteurTravaux ? conducteurTravaux : emptyTabInfosIntervenant;
    }
    
    generalInformations.dateLimite = values.valueDate;
    generalInformations.isDisplayed = true;
    return generalInformations
}

export const generalInformationsToCreateAutocontroleParams = (generalInformations:GeneralInformationsState) => {
    let type;

    if(generalInformations.typeAutocontrole === TYPE_AUTOCONTROLE_L4){
        type = TYPE_AUTOCONTROLE_L4_API;
    }
    if(generalInformations.typeAutocontrole === TYPE_AUTOCONTROLE_L15){
        type = TYPE_AUTOCONTROLE_L15_API;
    }
    

    let params = {
        idTrancheTravaux: generalInformations.trancheTravaux?.id,
        type: type,
        responsableProgramme: generalInformations.responsableProgramme,
        conducteurTravaux: generalInformations.conducteurTravaux,
        CMQ: generalInformations.chargeMissionQualite,
        responsableTechnique:generalInformations.responsableTechnique,
        directeurOperationnel:generalInformations.directeurOperationnel,
        directeurProgrammes:generalInformations.directeurProgrammes,
        filiale: ReferentielUtils.getFilialeLibelle(generalInformations.filiale),
        nbLots: generalInformations.nbLots,
        programme: ReferentielUtils.getProgrammeLibelle(generalInformations.programme),
        programmeCommercial: generalInformations.programme.libelleCommercial,
        regroupementResp: generalInformations?.programme?.regroupementResp,
        trancheTravaux: ReferentielUtils.getTrancheTravauxLibelle(generalInformations.trancheTravaux),
        dateLimite:new Date(generalInformations.dateLimite).getTime(),
        tranchesTravauxLiees: generalInformations.tranchesTravauxLiees,
        dateLivraisionGPI: generalInformations.dateLivraison
    }

    return params;
}

export const infosCotationToGeneralInformations = (infosCotation:infosCotation, generalInformations:GeneralInformationsState) => {
    generalInformations.chargeMissionQualite = intervenantToTabInfosIntervenant(infosCotation.cmq);
    generalInformations.responsableProgramme = intervenantToTabInfosIntervenant(infosCotation.responsableProgramme);
    generalInformations.conducteurTravaux = intervenantToTabInfosIntervenant(infosCotation.conducteurTravaux);

    return generalInformations;
}