import { API } from './callAPI';

// Upload un fichier vers un S3 via une url signée
export async function uploadToS3(name: string, fileType: string, blob: Blob) {

  return new Promise((resolve, reject) => {
    API.getSignedtUrlS3(name, 'PUT').then((presignedPostUrl) => {

      uploadFileToS3(presignedPostUrl.data.uploadUrl, blob, fileType)
        .then((response) => {
          console.log("the upload of the image : " + name + " have been successfully completed");
          resolve(response);
        }, err => {
          console.log(err);
          reject(err);
        });
    })
  })
}


// Upload un fichier vers un S3 via une url signée
const uploadFileToS3 = (url: string, blob: Blob, _type: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.send(blob);
    xhr.onload = function() {
      this.status === 204 || this.status === 200 ? resolve("file uploaded !") : reject(this.responseText);
    };
  });
}

export async function getPictureFromS3(name: string) {
  return new Promise((resolve, reject) => {
    var promise = API.getSignedtUrlS3(name, 'GET');
    promise.then((url: any) => {
      console.log('get Image : ' + name);
    
      resolve(url);
    }, (err: any) => {
      console.log(err);
      reject(err);
    });
  })
}