import React from 'react';
import {FicheAutoControleCritere} from '../../../store/types/FicheAutocontrole.Types';
import {TextField} from '@material-ui/core';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfoPhoto from '../../../assets/info_photo.svg'
import { ModeAffichage } from '../../../utils/constantes/contantes';
import { CritereUtils } from '../CritereUtils';


interface PropsFromState {
    critere:FicheAutoControleCritere,
    updateCritere: Function,
    modeAffichage?: ModeAffichage
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const InputTextAutocontrole: React.FC<AllProps> = ({modeAffichage, critere, updateCritere, goBackStateHasChanged}) => {
    let maxValue:number = 1000;
    const history = useHistory();
    maxValue = (critere.id === 'c_commcmq') ? maxValue : 250;

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.activated = event.target.checked;
        updateCritere(critere);
    };

    const handleChangeInputText = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        goBackStateHasChanged();
        if(((event.target as HTMLInputElement).value as unknown as string).length <= maxValue){
            critere.value = (event.target as HTMLInputElement).value as unknown as string;
            updateCritere(critere);
        }else{
            critere.value = ((event.target as HTMLInputElement).value as unknown as string).slice(0, maxValue);
            updateCritere(critere);
        }
    };

    

    return(<div>
                <div className='commentary-flex'>                    
                    <img className="icon-libelle margin-commentary" src={InfoPhoto} alt="" onClick={() => history.push('/rgpd?mode=info')}/>
                    <LabelCritereAutocontroleStyled>{critere.title}
                    {CritereUtils.getActiveStateFromCriteria(critere, modeAffichage) ? 
                        <SwitchAutocontroleStyled className="elt-float-right"  checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt=""/>}/> 
                        : ""
                    }
                    </LabelCritereAutocontroleStyled>
                </div>

                <div>
                    <div className={"critera-input-text-box"}>
                        <TextField 
                            className = ' critera-input-text'
                            value={critere.value} 
                            onChange={handleChangeInputText} 
                            placeholder= "Ecrivez votre commentaire ici..." 
                            helperText = {(critere.value as string).length === maxValue ? "Vous avez atteint la limite du nombre de caractères." : ""}
                            error = {(critere.value as string).length === maxValue}
                            disabled={!critere.activated} 
                            multiline/>
                    </div>
                </div>
            </div>);
};
  

const mapStateToProps = ({ goBackState }: ApplicationState) => ({
    goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InputTextAutocontrole);