import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L4 } from '../../utils/constantes/contantes';

interface PropsFromState {
    typeAutocontrole: String
    value: number,
    isTypesActivated?: boolean
}


const ProgressBar : React.FC<PropsFromState> = ({value, typeAutocontrole, isTypesActivated}) =>{
    const getColorProgressBar = () => {
        return ((typeAutocontrole === TYPE_AUTOCONTROLE_L4) && (value <60)) 
        || ((typeAutocontrole === TYPE_AUTOCONTROLE_L15) && (value <85))
        || isTypesActivated ? 'primary' : 'secondary'
    }
    
    return (
        <div>
            <div className="custom-progress-bar">
                <LinearProgress 
                variant="determinate"
                color={getColorProgressBar()} 
                value={value}
                />
            </div>
        </div>
    );
};
export default ProgressBar;