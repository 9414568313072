import React from "react";
import HomePage from "../home-page/HomePage";
import HomePageCMQ from "../home-page-cmq/HomePageCMQ";
import { getUserRole } from "../../utils/AccessToken";
import { userRoles } from "../../store/types/user.Types";


const Home: React.FC = () => {
  
  return (
    <div>
      {
         (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) ?
         <HomePageCMQ/> :
         <HomePage/>
      }
    </div>

  );
};

export default Home;
