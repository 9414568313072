import { Tab, Tabs, withStyles } from "@material-ui/core";
import { colorsAutocontrole } from "./ColorsAutocontrole.style";

export const TabsStyled = withStyles({
    root: {
    },
    indicator: {
      display:'none'
    },
  })(Tabs);

  export const TabVerticalStyled = withStyles({
    root:{
      borderBottom: `2px solid ${colorsAutocontrole.tier}`,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'left'
    }
  })(Tab);