import { Reducer } from "redux";
import { TabSynthese, TabSyntheseState, TabSyntheseStateActions } from "../types/TabSynthese.Types";

export const initialState: TabSyntheseState = {
    isSaved: true,
    isDateSaved: true,
    isPhotosSaved: true,
    isIntervenantsSaved: true,
    isCommentairesSaved: true,
    isValidationDirectionQualiteSaved: true,
    data: {
        destinatairesCC: ['mfroment@nexity.fr', 'cchabaud@nexity.fr', 'atdjine@nexity.fr', 'cecilia.salmon@nexity.fr', 'directionqualite@nexity.fr']
    } as unknown as TabSynthese
}

const reducer: Reducer<TabSyntheseState> = (state = initialState, action = { type: '', payload: '' }) => {
    switch (action.type) {
        case TabSyntheseStateActions.UPDATE_STATE: {
            let copy = { ...state }
            copy.data = { ...action.payload };
            return copy;
        }
        case TabSyntheseStateActions.RESET_STATE: {
            return initialState
        }
        case TabSyntheseStateActions.SET_ISSAVED: {
            let copy = { ...state }
            copy.isSaved = action.payload
            return copy;
        }
        case TabSyntheseStateActions.SET_ISDATESAVED: {
            let copy = { ...state }
            copy.isDateSaved = action.payload
            return copy;
        }
        case TabSyntheseStateActions.SET_ISPHOTOSSAVED: {
            let copy = { ...state }
            copy.isPhotosSaved = action.payload
            return copy;
        }
        case TabSyntheseStateActions.SET_ISCOMSSAVED: {
            let copy = { ...state }
            copy.isCommentairesSaved = action.payload
            return copy;
        }

        case TabSyntheseStateActions.SET_ISINTERVENANTSSAVED: {
            let copy = { ...state }
            copy.isIntervenantsSaved = action.payload
            return copy;
        }
        case TabSyntheseStateActions.SET_ISVALDIRQUASAVED: {
            let copy = { ...state }
            copy.isValidationDirectionQualiteSaved = action.payload
            return copy;
        }
        default: {
            return state;
        }
    }
}

export { reducer as TabSyntheseReducer };