import { userRoles } from "../store/types/user.Types";
import { getUserMail, getUserRole, isAccessTokenValid, jwt_decode_ } from "./AccessToken";
import DateUtils from "./DateUtils";
/**
 * @author aoffroy
 * 
 * Classe utilitaire pour un user
 */
export class UserUtils {
    /**
     * Vérifie si la date RGPD est conformme aux conditions définies
     * @param dateValidationRGPD la date de validation RGPD par l'utilisateur
     */
    static isDateRGPDValid = (dateValidationRGPD: number) => {
        return (!!dateValidationRGPD && !DateUtils.hasMoreThanXMonth(dateValidationRGPD, 6))
    }
    /**
     * vérifie si le role passé est conforme aux roles autorisés par l'application 
     * @param roleUser le role de l'utilisateur 
     * @returns 
     */
    static isRoleUserValid = () => {
        const roleUser = getUserRole();
        const ROLES_AUTHORIZED = [userRoles.cmq.toLowerCase(), userRoles.terrain.toLowerCase()];
        return !!roleUser && (ROLES_AUTHORIZED.includes(roleUser.toLowerCase()))
    }

    /**
     * Check si les infos de l'utilisateurs sont valides et non modifiées
     * @returns true si infos valides, non sinon
     */
    static isInfosUserSessionValid = () => {
        let emailRole = null;
        if(localStorage.getItem('role')){
            let decodedToken:{preferred_username:string} = jwt_decode_(localStorage.getItem('role') as string)
            emailRole  = !!decodedToken?.preferred_username ? decodedToken?.preferred_username.toString().toUpperCase() : '';
        }
        const isAccessTokenOk = isAccessTokenValid();
        const isRoleUserOk = UserUtils.isRoleUserValid();
        return isAccessTokenOk && isRoleUserOk && (getUserMail().toUpperCase() === emailRole?.toUpperCase());
    }
}