import axios from 'axios';
import Env from './Env';
import Logger from './Logger';
import { REDIRECT_DELAI, REDIRECT_ERROR, REDIRECT_INTERDIT, REDIRECT_UNAUTHORIZED } from './constantes/contantes';
import { MessagesAlertTypes } from '../store/types/MessagesAlert.Types';
import { messageBackToMessagesFront } from './mappers/alertMessagesMapper';
import { checkToken, getAccessToken, isAccessTokenValid } from './AccessToken';


function interceptorError(error: any, store: any) {
  const isNotCriticalError = error.response?.data?.hasOwnProperty('isCritical') && !error.response?.data?.isCritical
  if (isNotCriticalError) {
    store.dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: messageBackToMessagesFront(error.response?.data) })
    const redirect = error.response?.data?.hasOwnProperty('redirect') && error.response?.data?.redirect;
    if (redirect) {
      return window.location.href = redirect;
    }
    return Promise.resolve(error.response);
  }
  if (!isAccessTokenValid()) {
    return window.location.href = REDIRECT_DELAI;
  }
  if (error.response) {
    const { status } = error.response;
    switch (status) {
      case 504:
        return window.location.href = REDIRECT_DELAI
      case 401:
        return window.location.href = REDIRECT_UNAUTHORIZED;
      case 403:
        return window.location.href = REDIRECT_INTERDIT;
      default:
        return window.location.href = REDIRECT_ERROR;
    }
  } else  {
    return window.location.href = REDIRECT_DELAI
  }
}

export const appInterceptor = (store: any) => {

  axios.interceptors.request.use(
    async function(config) {

      if (!config.url?.endsWith('/token') && !config.url?.endsWith('/openid-configuration')) {


        if (Env.REACT_APP_ENV_STAGE === 'dev' || Env.REACT_APP_ENV_STAGE === 'qua' || Env.REACT_APP_ENV_STAGE === 'prd') {
          Logger.info('' + config.url);
        }


        if (!checkToken()) {
          await getAccessToken();
        }

        config.headers.Authorization = localStorage.getItem("accessToken");
        config.headers['x-role-user'] = localStorage.getItem("role");
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';

        /*config.headers['Cache-Control'] = 'no-cache';*/
        config.baseURL = Env.REACT_APP_API_URI;
      }

      return config;
    },
    function(error) {
      // Do something with request error
      if (Env.REACT_APP_ENV_STAGE === 'dev' || Env.REACT_APP_ENV_STAGE === 'qua' || Env.REACT_APP_ENV_STAGE === 'prd') {
        Logger.error('Ma cotation front request error : ' + error);
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (!error.config.url?.endsWith('/token') && !error.config.url?.endsWith('/openid-configuration')) {
        return interceptorError(error, store);
      }
    }
  )
}

const httpIntance = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};

export default httpIntance