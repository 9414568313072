import { Reducer } from "redux";
import { HomePageState, HomePageTypes } from "../types/homePage.Types";

export const initialState: HomePageState={
    filiales:  [],
    programmes:  [],
    tranchesTravaux:  [],
}

const reducer: Reducer<HomePageState> = (state = initialState, action = {type:'', payload:''}) => {
    switch(action.type){
        case HomePageTypes.UPDATE_STATE:{
            let copy = {...state};
            copy = action.payload;
            return {...copy}
        }
        case HomePageTypes.RESET_STATE:{
            return initialState
        }
        default:{
            return state;
        }
    }
}

export {reducer as HomePageReducer};