import { Reducer } from "redux";
import { OldPictureTypes } from "../types/pictures.Types";

export const initialState:Array<string>= [];

const reducer : Reducer<Array<string>> = (state = initialState, action = {type:'', picName:''}) => {
    switch(action.type){
        case OldPictureTypes.ADD_OLD_PIC:{
            let copy  = [...state]
            copy.push(action.picName);
            
            return copy;
        }
        case OldPictureTypes.DELETE_OLD_PIC:{
            let copy  = [...state]
            const indexToDelete = copy.indexOf(action.picName);
            copy.splice(indexToDelete, 1);
            return copy;
        }
        case OldPictureTypes.DELETE_OLD_ALL_PIC:{
            return [];
        }
        default:{
            return state;
        }
    }
}

export {reducer as OldPicturesReducer};