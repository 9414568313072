import { FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";

export interface HomePageVQState {
    regroupementsResponsabilites: RegroupementResponsabilite[],
    regroupementResponsabiliteSelected: RegroupementResponsabilite,
    agences: Agence[],
    agenceSelected: Agence,
    dateLivraisonDebut: number,
    dateLivraisonFin: number,
    tranchesTravauxVisites: TrancheTravauxVisites[]
}

export interface RegroupementResponsabilite {
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    refType: string,
    libelleRegroupementResponsabilite: string,
    codeRegroupementResponsabilite: string,
    actif: boolean
}

export interface Agence {
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    refType: string,
    libelleAgence: string,
    codeAgence: string,
    codeRegroupementResponsabilite: string,
    actif: boolean
}

export interface TrancheTravauxVisites {
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    id_regroupement_agence: string,
    codeSociete: string,
    codeProgramme: string,
    codePhase: string,
    libelleTranche: string,
    codeTranche: string,
    dateLivraisonPrevue: number,
    actif: boolean,
    nbLots: number,
    refType: string,
    codeRegroupementResponsabilite: string,
    codeAgence: string,
    libelleFiliale: string,
    libelleProgramme: string,
    villeProgramme: string,
    dateRealisationAutoControleL4: number,
    dateRealisationAutoControleL15: number,
    visiteQualiteLiees: VisiteQualite[]
}

export interface VisiteQualite {
    id_referentiel: string,
    id: string,
    id_param_type: string,
    id_gpi: string,
    refType: string,
    typeVisiteQualite: string,
    datePlanificationVisite: number,
    id_tranche_visite: string,
    responsableProgramme: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    chargeMissionQualite: FicheAutocontroleIntervenant,
    commentaire: string
}

export interface TrancheTravauxVisitesFront {
    id_referentiel: string,
    codeProgramme: string,
    codeTranche: string,
    villeProgramme: string,
    libelleTranche: string,
    nbLots: number,
    dateLivraisonPrevue: number,
    dateVisiteL4: number,
    colorVisiteL4: string,
    dateVisiteL15: number,
    colorVisiteL15: string,
    dateVisiteL: number,
    colorVisiteL: string
}

export enum HomePageVQTypes {
    UPDATE_STATE = "@@HomePageVQ/UPDATE_STATE",
    RESET_STATE = "@@HomePageVQ/RESET_STATE"
}
