import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ImageInterdit from '../../assets/interdit.jpg';
import ImageErreur from '../../assets/erreur.jpg';
import ImageDelai from '../../assets/delai.jpg';
import { Button } from '@chakra-ui/core';
import { getAccessToken } from '../../utils/AccessToken';
import { REDIRECT_DELAI, REDIRECT_FICHE, REDIRECT_HOME_PAGE, REDIRECT_INTERDIT } from '../../utils/constantes/contantes';
import { ApplicationState } from '../../store/store/store';
import { connect } from 'react-redux';
import { FicheAutocontrole } from "../../store/types/FicheAutocontrole.Types";
import { CurrentState } from '../../store/types/CurrentState.Types';
import { UserUtils } from '../../utils/UserUtils';


interface PropsFromDispatch {
    data: FicheAutocontrole,
    currentState: CurrentState
}

const Erreur: React.FC<PropsFromDispatch> = ({ data, currentState }) => {

    let search = useLocation().search;
    const type = new URLSearchParams(search).get('type');

    const history = useHistory();

    useEffect(() => {
        if (type === "504" || type === "404" || type === "403") {
            refreshPage();
        }
    });

    /**
     * Retourne sur la dernière page (et eventuellement groupe) sur laquelle naviguait l'utilisateur (paramètres non pris en compte)
     */
    const goToLastPage = () => {
        getAccessToken()
            .then(() => {
                if (UserUtils.isInfosUserSessionValid()) {
                    if (!!data && currentState.currentPage >= 0) {
                        history.push(REDIRECT_FICHE, { from: REDIRECT_DELAI })
                    } else {
                        history.push(REDIRECT_HOME_PAGE)
                    }
                }
            })
            .catch(() => {
                history.push(REDIRECT_INTERDIT)
            });
    }

    /**
     * Rafraichissement de la page en cliquant sur 'actualisé la page' ou F5
     */
    const refreshPage = () => {
        if (window.performance.getEntriesByType("navigation")) {
            //Récupére l'action réalisé sur le navigateur : https://developer.mozilla.org/fr/docs/Web/API/PerformanceNavigation/type
            let p: any = performance.navigation.type
            //1 correspond au type_reload
            if (p === 1) {
                goToLastPage()
            }
        }
    }


    const getMessage = () => {
        switch (type) {
            case "0": {
                return (<div className="center-div">
                    <div>La saisie de l'autocontrôle de la tranche travaux est terminée et l'autocontrôle n'est plus accessible.</div>
                    <div>Un rapport a été envoyé à l'équipe terrain et votre direction. </div>
                    <div>Pour plus d'information, vous pouvez contacter votre chargé de mission Qualité.</div>
                </div>)
            }
            case "1": {
                return (<div className="center-div">
                    <div>La saisie de l'autocontrôle de la tranche travaux n'est plus accessible.</div>
                    <div>Pour plus d'information, vous pouvez contacter votre chargé de mission Qualité.</div>
                </div>)
            }
            case "2": {
                return (<div className="center-div">
                    Oups, vous n'êtes pas autorisé à accéder à l'outil. <br></br>
                    Veuillez vous rapprocher de votre chargé de mission Qualité ou de faire une demande d'accès depuis le kiosque SI.
                </div>)
            }
            case "504": {
                return (<div className="center-div" >
                    <img src={ImageDelai} alt="" />
                    <p>Votre session est expirée.<br />
                        Veuillez actualiser la page pour continuer vos actions.</p>
                    <Button className="btn-radio btn-go-autocontrole active cursor-pointer" onClick={() => goToLastPage()}>Actualiser ma page</Button>
                </div>)
            }
            case "401": {
                return (<div className="center-div" >
                    <img src={ImageDelai} alt="" />
                    <p>Votre session est expirée (#401).<br />
                        Veuillez actualiser la page pour continuer vos actions.</p>
                    <Button className="btn-radio btn-go-autocontrole active cursor-pointer" onClick={() => goToLastPage()}>Actualiser ma page</Button>
                </div>)
            }
            case "403": {
                return (<div className="center-div">
                    <img src={ImageInterdit} alt="" />
                    <p>Vous n'êtes pas encore autorisé à accéder à l'outil.<br />
                        Merci de vous rapprocher de votre chargé de mission Qualité ou de faire une demande d'accès depuis le kiosque SI.</p>
                </div>)
            }
            case "404": {
                return (<div className="center-div">
                    <img src={ImageErreur} alt="" />
                    <p>Une erreur est survenue.<br />
                        Merci de contacter le service informatique depuis le kiosque SI.</p>
                </div>)
            }
            default: {
                return (<div className="center-div">
                    Un problème est survenu
                </div>)
            }
        }
    }

    return (
        <div>
            {getMessage()}
        </div>
    );
}

const mapStateToProps = ({ ficheAutocontrole, currentState }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    currentState: currentState
});

export default connect(mapStateToProps)(Erreur);