import { Reducer } from "redux";
import { HomePageVQState, HomePageVQTypes } from "../types/homePageVQ.Types";
import { emptyAgence, emptyRegroupementResponsabilite } from "../../utils/constantes/emptyObjects";
import DateUtils from "../../utils/DateUtils";

export const initialState: HomePageVQState = {
    regroupementsResponsabilites: [],
    agences: [],
    agenceSelected: emptyAgence,
    regroupementResponsabiliteSelected: emptyRegroupementResponsabilite,
    dateLivraisonDebut: Date.now(),
    dateLivraisonFin: DateUtils.addMonths(Date.now(), 6),
    tranchesTravauxVisites: []
}

const reducer: Reducer<HomePageVQState> = (state = initialState, action = { type: '', payload: '' }) => {
    switch (action.type) {
        case HomePageVQTypes.UPDATE_STATE: {
            let copy = { ...state };
            copy = action.payload;
            return { ...copy }
        }
        case HomePageVQTypes.RESET_STATE: {
            return initialState
        }
        default: {
            return state;
        }
    }
}

export { reducer as HomePageVQReducer };