import React from 'react';
import { FicheAutocontrole, FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutocontroleIntervenant, FicheAutocontroleTabInfos } from '../../store/types/FicheAutocontrole.Types';
import GroupAutocontrole from '../GroupAutocontrole/GroupAutocontrole';
import { AUTOCONTROLE_STATUT, GeneralInformationsState, GeneralInformationsTypes, TrancheTravauxLiee } from '../../store/types/generalInformations.Types';
import DateUtils from '../../utils/DateUtils';
import { ApplicationState } from '../../store/store/store';
import { connect } from 'react-redux';
import { Button, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { makeStyles, Theme, createStyles, Backdrop } from '@material-ui/core';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { API } from '../../utils/callAPI';
import StateService from '../../utils/state.service';
import SelectAutocontrole from '../Criteres/TypesCriteres/SelectAutocontrole';
import { emptyTabInfosIntervenant, getEmptySelectCritere } from '../../utils/constantes/emptyObjects';
import Helper from '../Helper/Helper';
import { HelperTypeEnum } from '../Helper/HelperTypeEnum'
import { uploadToS3 } from '../../utils/upload.image.s3.service';
import { PictureState, PictureTypes } from '../../store/types/pictures.Types';
import { CurrentState } from '../../store/types/CurrentState.Types';
import { userRoles } from '../../store/types/user.Types';
import { getUserRole } from '../../utils/AccessToken';
import IntervenantUtils from '../../utils/IntervenantUtils';
import { GoBackCurrentStateActions } from '../../store/types/GoBackState.Types';
import ProgressBarService from '../../utils/progressBar.service';

import PicProg from '../../assets/image_programme.png';
import { AutocontroleIntervenants, IntervenantsAutocontroleTypes, IntervenantsState } from '../../store/types/intervenants.Types';
import { REDIRECT_HOME_ACQ, SEVERITY } from '../../utils/constantes/contantes';
import { MessagesAlertState, MessagesAlertTypes } from '../../store/types/MessagesAlert.Types';
import { TransitionProps } from '@material-ui/core/transitions';
import { useHistory } from 'react-router-dom';
import { TabSynthese, TabSyntheseStateActions } from '../../store/types/TabSynthese.Types';
import { tabInfosToGeneralInformations } from '../../utils/mappers/generalInformationsMapper';

interface PropsFromState {
    tabInfos: FicheAutocontroleTabInfos
}

interface PropsFromDispatch {
    generalInformations: GeneralInformationsState,
    isSaved: boolean,
    setIsSaved: Function,
    updateTabInfos: Function,
    intervenantsState: IntervenantsState,
    updateIntervenantsState: Function,
    pictures: PictureState[],
    currentState: CurrentState,
    data: FicheAutocontrole,
    deletePicsFromStore: Function,
    initGoBackState: Function,
    updateMessageSnackBar: Function,
    fetchData: Function,
    updateSynthese: Function,
    setGeneralInformationsState: Function

}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type AllProps = PropsFromState & PropsFromDispatch;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),

);


const TabInfosAutocontrole: React.FC<AllProps> = ({ tabInfos, generalInformations, isSaved, setIsSaved, updateTabInfos, intervenantsState, updateIntervenantsState, pictures, currentState, data, deletePicsFromStore, initGoBackState, updateMessageSnackBar, fetchData, updateSynthese, setGeneralInformationsState }) => {

    /**
     * Constantes & State :
     */
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [valueRPR, setValueRPR] = React.useState(tabInfos?.responsableProgramme?.nom ? tabInfos?.responsableProgramme?.nom : '')
    const [valueRTE, setValueRTE] = React.useState(tabInfos?.responsableTechnique?.nom ? tabInfos?.responsableTechnique?.nom : '');
    const [valueCTR, setValueCTR] = React.useState(tabInfos?.conducteurTravaux?.nom ? tabInfos?.conducteurTravaux?.nom : '')
    const [valueDOP, setValueDOP] = React.useState(tabInfos?.directeurOperationnel?.nom ? tabInfos?.directeurOperationnel?.nom : '');
    const [valueDPR, setValueDPR] = React.useState(tabInfos?.directeurProgrammes?.nom ? tabInfos?.directeurProgrammes?.nom : '');
    const [progMiniature, setProgMiniature] = React.useState<{ thumbnailUrl: string } | null>(null);
    const [, setAlertOpenIntervenants] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const getAutocontrole = () => {
        API.getAutocontrole(generalInformations.trancheTravaux.id, generalInformations.typeAutocontrole)
            .then(function (response: any) {
                const ficheAutocontrole: FicheAutocontrole = response.data
                fetchData(ficheAutocontrole);
                updateSynthese(ficheAutocontrole.tabSynthese);
                setGeneralInformationsState(tabInfosToGeneralInformations(generalInformations, ficheAutocontrole.tabInfos));
            }).catch(function (error: any) {
                fetchData(null);
            });
    }


    /**
     * API :
     */
    const savePage = () => {
        setLoading(true);
        // construction de notre objet pour check si les règles pour les intervenants et dates sont ok
        const paramsToCheck = {
            chargeMissionQualite: tabInfos.CMQ,
            responsableProgramme: tabInfos.responsableProgramme,
            responsableTechnique: tabInfos.responsableTechnique,
            conducteurTravaux: tabInfos.conducteurTravaux,
            date: tabInfos.dateLimite
        }

        if (!IntervenantUtils.isParametresIntervenantsOk(paramsToCheck, true)) {
            let message: string[] = IntervenantUtils.getMessagesParametresIntervenants(paramsToCheck, true)
            setAlertOpenIntervenants(true)
            setLoading(false);
            updateMessageSnackBar({ messages: message, severity: SEVERITY.ERROR });
            return
        }
        tabInfos = StateService.resetDisabledCriteriasState(tabInfos) as FicheAutocontroleTabInfos;
        updateTabInfos(tabInfos)
        API.updateAutocontrole(tabInfos, ProgressBarService.getComplitionPercent(data), data.statut)
            .then(function () {

                if (pictures.length > 0) {
                    pictures.forEach(img => {
                        uploadToS3(img.name, img.type, img.pic).then(
                            () => {
                                console.log("Image " + img.name + " sauvegardée !");
                            }
                        );
                    });
                    deletePicsFromStore();
                    getAutocontrole();
                } else {
                    getAutocontrole();
                }
                console.log("Autocontrole sauvegardé");
                setIsSaved(true);
                setLoading(false);
                initGoBackState();
            }).catch(function () {
                console.log("Problème lors de la sauvegarde");
                setIsSaved(true)
                setLoading(false);
                initGoBackState();
            });
    }

    const getReferentiels = (refType: string) => {

        const codeFiliale = (refType === AutocontroleIntervenants.responsablesProgramme) ? Number(generalInformations.programme.regroupementResp).toString() : Number(generalInformations.filiale.codeSociete).toString();

        API.getReferentiels(refType, codeFiliale)
            .then(function (response: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType, response.data);
                let refList: FicheAutocontroleIntervenant[] = response.data.map((item: any) => {
                    let tabInfosIntervant: FicheAutocontroleIntervenant = {
                        nom: item.prenom.toUpperCase() + " " + item.nom.toUpperCase(),
                        mail: item.mail,
                        isInterne: (item.typeInterneExterne === 'I' || item.typeInterneExterne === ''),
                        id_gpi: item.id_gpi
                    }
                    return tabInfosIntervant;
                });

                refList = IntervenantUtils.removeDuplicate(refList);

                switch (refType) {
                    case AutocontroleIntervenants.responsablesProgramme:
                        intervenantsState.responsablesProgramme = refList;
                        break;
                    case AutocontroleIntervenants.responsablesTechnique:
                        intervenantsState.responsablesTechnique = refList;
                        break;
                    case AutocontroleIntervenants.conducteursTravaux:
                        intervenantsState.conducteursTravaux = refList;
                        break;
                }
                updateIntervenantsState(intervenantsState)
            }).catch(function (error: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType, error);
            });
    }

    /**
     * UseEffect :
     */
    const getIntervenants = () => {
        if (!intervenantsState.responsablesTechnique) {
            getReferentiels(AutocontroleIntervenants.responsablesTechnique);
        }

        if (!intervenantsState.responsablesProgramme) {
            getReferentiels(AutocontroleIntervenants.responsablesProgramme);
        }

        if (!intervenantsState.conducteursTravaux) {
            getReferentiels(AutocontroleIntervenants.conducteursTravaux);
        }
    }

    const handlePopEvent = (e: any) => {
        localStorage.setItem('popListenerAdded', "true");
        e.preventDefault();
        window.history.pushState(null, document.title, window.location.href);

        if (localStorage.getItem('showPopIn') === 'true') {
            setOpen(true);
        } else {
            history.push(REDIRECT_HOME_ACQ);
        }

    }


    const handleBackNavigatorEvent = () => {
        if (!(localStorage.getItem('popListenerAdded') === 'true')) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handlePopEvent);
        }

    }


    React.useEffect(() => {
        getIntervenants();
        if (generalInformations.programme && generalInformations.programme.codeProgramme) {
            API.getProgrammePicture(generalInformations.programme.codeProgramme).then((resp) => {
                setProgMiniature(resp?.data?.response);
            }).catch(err => console.log(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalInformations.programme.codeProgramme]);


    React.useEffect(() => {
        localStorage.setItem('showPopIn', "false");
        localStorage.setItem('popListenerAdded', "false");
        handleBackNavigatorEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Metier
     */
    const saveReferentiel = (critere: FicheAutoControleCritere) => {
        localStorage.setItem('showPopIn', "true");
        switch (critere.options) {
            case AutocontroleIntervenants.responsablesProgramme:
                const rprFound = (intervenantsState.responsablesProgramme as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabInfos.responsableProgramme = rprFound ? rprFound : emptyTabInfosIntervenant;
                setValueRPR(tabInfos?.responsableProgramme?.nom ? tabInfos?.responsableProgramme?.nom : '');
                break;
            case AutocontroleIntervenants.responsablesTechnique:
                const rteFound = (intervenantsState.responsablesTechnique as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabInfos.responsableTechnique = rteFound ? rteFound : emptyTabInfosIntervenant;
                setValueRTE(tabInfos?.responsableTechnique?.nom ? tabInfos?.responsableTechnique?.nom : '')
                break;
            case AutocontroleIntervenants.conducteursTravaux:
                const ctrFound = (intervenantsState.conducteursTravaux as FicheAutocontroleIntervenant[])
                    .find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                tabInfos.conducteurTravaux = ctrFound ? ctrFound : emptyTabInfosIntervenant;
                setValueCTR(tabInfos?.conducteurTravaux?.nom ? tabInfos?.conducteurTravaux?.nom : '');
                break;
        }
        setIsSaved(false);
        updateTabInfos(tabInfos);
    }

    const sortIntervenants = (referentiels: string[]) => {
        return referentiels ? referentiels.sort((a: string, b: string) => a.localeCompare(b)) : [] as string[]
    }

    const getSelectCritere = (refType: string) => {
        let critere = getEmptySelectCritere();
        critere.options = refType;

        switch (refType) {
            case AutocontroleIntervenants.responsablesProgramme: {
                critere.value = valueRPR;
                critere.choices = sortIntervenants((intervenantsState.responsablesProgramme as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.activated = activateRPAndCT();
                critere.title = 'Responsable programme';
                break;
            }
            case AutocontroleIntervenants.responsablesTechnique: {
                critere.value = valueRTE;
                critere.choices = sortIntervenants((intervenantsState.responsablesTechnique as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = intervenantsState.responsablesTechnique !== null ? intervenantsState.responsablesTechnique : undefined
                critere.title = 'Responsable technique';
                break;
            }
            case AutocontroleIntervenants.conducteursTravaux: {
                critere.value = valueCTR;
                critere.choices = sortIntervenants((intervenantsState.conducteursTravaux as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.activated = activateRPAndCT();
                critere.intervenants = intervenantsState.conducteursTravaux !== null ? intervenantsState.conducteursTravaux : undefined
                critere.title = 'Conducteur de travaux';
                break;
            }
        }
        return critere;
    }

    const createGroups = () => {
        return tabInfos.groups.map(group => <GroupAutocontrole key={'Tabinfos'.concat(group.index.toString())} group={group} />)
    }

    const activateRPAndCT = () => {
        return (data.statut === AUTOCONTROLE_STATUT.OUVERT || data.statut === AUTOCONTROLE_STATUT.FICHE_COMPLETEE);
    }

    const buildTrancheTravaux = (tranche: string, nbLots: number, tranchesLiees: TrancheTravauxLiee[]) => {
        return <div className="tabInformationTrancheTravaux">
            <div className="tabInformation-informations-value">{tranche} - {nbLots} Lots</div>
            {tranchesLiees && tranchesLiees.length > 0 ?
                (tranchesLiees as TrancheTravauxLiee[])
                    .sort((a, b) => a.codeTranche.localeCompare(b.codeTranche))
                    .map(tt => {
                        return (<div className="tabInformation-informations-value">{tt.codeTranche} - {tt.libelleTranche} - {tt.nbLots} Lots</div>)
                    }) : ''}
        </div>
    }

    const quitParameters = () => {
        history.push(REDIRECT_HOME_ACQ);
        initGoBackState();
    }


    /**
     * Display :
     */
    return (
        <div className="tabInfos">
            <div className="tabInfos-informations">
                <div className="tabInfos-title">
                    <span className="tabInfos-title-text">Informations générales</span>
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Nom de la filiale :</div>
                    <div className="tabInformation-informations-value">{tabInfos.filiale}</div>
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Nom du programme :</div>
                    <div className="tabInformation-informations-value">{tabInfos.programme}</div>
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Tranche travaux :</div>
                    {buildTrancheTravaux(tabInfos.trancheTravaux, tabInfos.nbLots, data.tranchesTravauxLiees as TrancheTravauxLiee[])}
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Nom commercial programme :</div>
                    <div className="tabInformation-informations-value">{tabInfos.programmeCommercial}</div>
                </div>
                <div className="tabInfos-information">
                    <CardMedia
                        className="critera-picture-img-miniature"
                        component="img"
                        image={!!progMiniature ? progMiniature.thumbnailUrl : PicProg}
                    />
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Type d'autocontrole :</div>
                    <div className="tabInformation-informations-value">{generalInformations.typeAutocontrole}</div>
                </div>
                {tabInfos.completionLM4 && tabInfos.completionLM4 !== -1 ?
                    <div className="tabInfos-information">
                        <div className="tabInformation-informations-title">Pourcentage d'avancement L-4 :</div>
                        <div className="tabInformation-informations-value">{tabInfos.completionLM4} %</div>
                    </div> : ''}
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Date de livraison :</div>
                    <div className="tabInformation-informations-value"><span className={DateUtils.getDateLimiteColor(tabInfos.dateLivraisionGPI)}>{DateUtils.dateBackToDateFront(tabInfos.dateLivraisionGPI)}</span></div>
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Date limite de saisie :</div>
                    <div className="tabInformation-informations-value"><span className={DateUtils.getDateLimiteColor(tabInfos.dateLimite)}> {DateUtils.dateBackToDateFront(tabInfos.dateLimite)}</span></div>
                </div>
                <div className="tabInfos-information">
                    <div className="tabInformation-informations-title">Chargé de mission qualité :</div>
                    <div className="tabInformation-informations-value">{tabInfos.CMQ.nom}</div>
                </div>
            </div>
            <div className="tabInfos-criterias">
                <Helper type={HelperTypeEnum.INFO} title="Informations requises" description="Merci de renseigner l'ensemble des informations ci-dessous" />
                <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentiel} />
                <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentiel} />
                <SelectAutocontrole critere={getSelectCritere(AutocontroleIntervenants.responsablesTechnique)} updateCritere={saveReferentiel} />
                {createGroups()}
                <Button className="tab-save-button" onClick={savePage} disabled={isSaved}>Enregistrer</Button>
            </div>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span>Êtes vous sûr de vouloir abandonner votre saisie ? <br /> Les données saisies seront perdues.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => quitParameters()} color="primary">
                        OUI
            </Button>
                    <Button onClick={() => setOpen(false)} color="primary">
                        NON
            </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

const mapStateToProps = ({ ficheAutocontrole, generalInformations, intervenantsState, pictures, currentState }: ApplicationState) => ({
    generalInformations: generalInformations,
    isSaved: ficheAutocontrole.isSaved,
    intervenantsState: intervenantsState,
    pictures: pictures,
    currentState: currentState,
    data: ficheAutocontrole.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({ type: FicheAutocontroleActionTypes.SET_ISSAVED, payload: params }),
        updateTabInfos: (data: FicheAutocontroleTabInfos) => dispatch({ type: FicheAutocontroleActionTypes.UPDATE_TAB_INFOS, payload: data }),
        updateIntervenantsState: (params: IntervenantsState) => dispatch({ type: IntervenantsAutocontroleTypes.UPDATE_STATE, payload: params }),
        deletePicsFromStore: () => dispatch({ type: PictureTypes.DELETE_ALL_PIC }),
        initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE }),
        updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params }),
        fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
        updateSynthese: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data }),
        setGeneralInformationsState: (params: GeneralInformationsState) => dispatch({ type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabInfosAutocontrole);