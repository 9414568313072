import { FicheAutocontrole } from "../../store/types/FicheAutocontrole.Types";
import { TabSynthese } from "../../store/types/TabSynthese.Types";
import { POINT_VIRGULE, TIRET } from "./mailConstantes";

/**
* Mail values initialisations
*/
const getMailFormat = (mail:string) => {
    if(!!mail && mail !== ''){
        return mail + POINT_VIRGULE;
    }else{
        return '';
    }
}

export const getFormatString = (value: string) => {
    if(!value[0] || !value[1]) { return "-" }
    return value[0].toUpperCase() + value.substring(1).toLowerCase();
}

export const getObjetMailValue = (ficheAutocontrole:FicheAutocontrole) =>{
    let objetMail:string = '';
    
    objetMail+= '[DQPL] Autocontrôle '
    objetMail+= ficheAutocontrole.type + TIRET
    objetMail+= ficheAutocontrole.tabInfos.filiale + TIRET
    objetMail+= ficheAutocontrole.tabInfos.programme + TIRET
    objetMail+= (ficheAutocontrole.tranchesTravauxLiees && ficheAutocontrole.tranchesTravauxLiees.length > 0 ) ? 'multi-tranches' : ficheAutocontrole.tabInfos.trancheTravaux
    
    return objetMail;
}

export const controleMail = (value: string) => {
    let reg = /^\w+([\.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; //eslint-disable-line

    let mailItems = value.split(";").map(m => m.trim()).filter(f => f);  
    return value && !!mailItems.length && mailItems.every(e => reg.test(e))
}

export const getDestinatairesMailValue = (ficheAutocontrole:TabSynthese) => {
    let destinatairesMail:string = '';
    
    destinatairesMail+= 
    ficheAutocontrole.infosGenerales.conducteurTravaux.isInterne ? 
    getMailFormat(ficheAutocontrole.infosGenerales.conducteurTravaux.mail) : '';
    destinatairesMail+= getMailFormat(ficheAutocontrole.infosGenerales.responsableTechnique.mail);
    destinatairesMail+= getMailFormat(ficheAutocontrole.infosGenerales.responsableProgramme.mail);
    destinatairesMail+= getMailFormat(ficheAutocontrole.infosGenerales.directeurProgrammes.mail);
    destinatairesMail+= getMailFormat(ficheAutocontrole.infosGenerales.directeurOperationnel.mail);
    let userMail = localStorage.getItem('mail') as string;
    if(userMail !== ficheAutocontrole.infosGenerales.conducteurTravaux.mail
        && userMail !== ficheAutocontrole.infosGenerales.responsableTechnique.mail
        && userMail !== ficheAutocontrole.infosGenerales.responsableProgramme.mail
        && userMail !== ficheAutocontrole.infosGenerales.directeurProgrammes.mail
        && userMail !== ficheAutocontrole.infosGenerales.directeurOperationnel.mail){
            destinatairesMail+= getMailFormat(userMail);
        }
        
        return destinatairesMail;
    }   
    
    export const getDestinatairesCCMailValue = (tabSynthese:TabSynthese) => {
        let destinairesCCMail:string = '';
        
        tabSynthese.destinatairesCC.forEach((destinaireCC:string) =>{
            destinairesCCMail += getMailFormat(destinaireCC)
        })
        
        return destinairesCCMail;
    }  
       
    export const getInfosMail = () => {
        return 'Vous pouvez maintenant envoyer le mail d\'envoi du rapport (le rapport PDF sera automatiquement ajouté en pièce-jointe). Les informations affichées ci-dessous restent modifiables avant l\'envoi.'
    }