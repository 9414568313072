import {FicheAutoControleCritere, FicheAutoControleGroup, FicheAutocontroleTab, FicheAutocontroleTabInfos } from "../store/types/FicheAutocontrole.Types";


export default class StateService {

    private static resetCriteraValue = (typeCriteria:string) =>{
        switch(typeCriteria){
            case 'IT':
                return "";
            case 'RB':
                return "";
            case 'SE':
                return "";
            case 'SL':
                return -1;
            case 'CB': 
                return [];
            case 'PI': 
                return "";
            default:
                return 0;
        }
    }

    static resetDisabledCriteriasState = (page: FicheAutocontroleTab|FicheAutocontroleTabInfos) => {
        if(page){
            page.groups.forEach((group:FicheAutoControleGroup) => {
                group.criterias.forEach((criteria:FicheAutoControleCritere) => {
                    if(!criteria.activated){
                        criteria.value = StateService.resetCriteraValue(criteria.typeCriteria)
                        if(criteria.typeCriteria === "PI") {
                            criteria.photoName = '';
                            criteria.photoId = '';
                            criteria.photoType = '';
                        }
                    }                
                });
            })

        }
        return page;
    }

    static changePageState =  (page: FicheAutocontroleTab, state: boolean) => {
        
        page.activated = state;
        page.groups.forEach((group:FicheAutoControleGroup) => {
            group.activated = state
            group.criterias.forEach((critera:FicheAutoControleCritere) => {
                critera.activated = state;
                if(critera.typeCriteria === "PI") {
                    critera.photoName =  '';
                    critera.photoId =  '';
                    critera.value =  '';
                    critera.photoType = '';
                }
            });
        });
        
        return StateService.updateSwitchState(page);
    }

    static isPageEmpty =  (page: FicheAutocontroleTab) => {
        return new Promise((resolve) => {
            let empty = true;
            page.groups.forEach((group:FicheAutoControleGroup) => {
                group.criterias.forEach((critera:FicheAutoControleCritere) => {
                    if(critera.typeCriteria === "PI" && critera.photoName !== '' ) {
                        empty = false;
                    }
                    if(critera && !!critera.value && critera.value !== -1 && (critera.value > 0 || critera.value !== [] || (typeof critera.value === 'string' && critera.value !== ''))) {
                        empty = false;
                    }
                });
            });
            
            resolve(empty) ;
        })
    }


    static changeGroupState =  (page:FicheAutocontroleTab, currentGroup: number, state: boolean) => {
        
        page.activated = true;
        page.groups[currentGroup].activated = state
        page.groups[currentGroup].criterias.forEach((critera:FicheAutoControleCritere) => {
            critera.activated = state;
        });
        // tous les criteres ne pouvant être désactivés avec un switch, on réactive ceux présent dans la page en cours qu'importe le groupe .
        page.groups.forEach((group, indexGroup) => {
            group.criterias.forEach((crit,indexCrit) => {
                if(!crit.active){
                    page.groups[indexGroup].criterias[indexCrit].activated = true;
                }
            })
        })

        return StateService.updateSwitchState(page);
    }

    static changeCriteraState =  (page:FicheAutocontroleTab, currentGroup: number, critere: FicheAutoControleCritere) => {
        page.activated = true;
        page.groups[currentGroup].activated = true;
        //The index of the critere and the index in table of critere can be different, so we found the critere in the table at first
        let critereIndexInArray = page.groups[currentGroup].criterias.findIndex(critereInArray => critereInArray.index === critere.index);
        page.groups[currentGroup].criterias[critereIndexInArray] = critere;

        // tous les criteres ne pouvant être désactivés avec un switch, on réactive ceux présent dans la page en cours qu'importe le groupe .
        page.groups.forEach((group, indexGroup) => {
            group.criterias.forEach((crit,indexCrit) => {
                if(!crit.active){
                    page.groups[indexGroup].activated = true;
                    page.groups[indexGroup].criterias[indexCrit].activated = true;
                }
            })
        })
        return StateService.updateSwitchState(page);
    }

    static initCriteraConditionalState =  (page:FicheAutocontroleTab, currentGroup: number, critere: FicheAutoControleCritere) => {
        page.activated = true;
        page.groups[currentGroup].activated = true;
        //The index of the critere and the index in table of critere can be different, so we found the critere in the table at first
        let critereIndexInArray = page.groups[currentGroup].criterias.findIndex(critereInArray => critereInArray.index === critere.index);
        page.groups[currentGroup].criterias[critereIndexInArray].criteresConditionnes?.forEach(critereCondiArray => {
            critereCondiArray.forEach(criteria => {
                criteria.photoName = '';
                criteria.photoId = '';
                criteria.value = '';
                criteria.photoType = '';
            });
         });

        return StateService.updateSwitchState(page);
    }


    static changeCriteraTabInfo = (tabInfos: FicheAutocontroleTabInfos, critere: FicheAutoControleCritere) => {
        //The index of the critere and the index in table of critere can be different, so we found the critere in the table at first
        let critereIndexInArray = tabInfos.groups[0].criterias.findIndex(critereInArray => critereInArray.index === critere.index);
        tabInfos.groups[0].criterias[critereIndexInArray] = critere;

        return tabInfos;
    }

    static updateSwitchState = (page:FicheAutocontroleTab) => {
        page.activated = false ;
        page.groups.forEach((group:FicheAutoControleGroup) => {
            group.activated = false ;
                group.criterias.forEach((critera:FicheAutoControleCritere) => {
                    group.activated = !critera.activated ? group.activated : true;
                    page.activated = !group.activated ? page.activated : true;
                });
        });
        
        return page;
    }
}