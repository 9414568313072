import { FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";
import { Filiale, Programme, TrancheTravaux } from "./generalInformations.Types";

export interface HomePageState {
    filiales: Filiale[],
    programmes: Programme[],
    tranchesTravaux: TrancheTravaux[],
}

export interface UpdateStatutParams {
    idTrancheTravaux: string,
    type: string,
    statut: string
}

export interface infosCotation {
    id_referentiel: string,
    id_param_type: string,
    id_gpi: string,
    typeAutocontrole: string,
    responsableProgramme: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    cmq: FicheAutocontroleIntervenant,
}

export enum HomePageTypes {
    UPDATE_STATE = "@@HomePage/UPDATE_STATE",
    RESET_STATE = "@@HomePage/RESET_STATE"
}