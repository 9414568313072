/**

 * Permet de remove des segments d'une chaine de caractère

 * @param chaine chaine de caractere

 * @param times nombre de segments à retirer

 * @param delimiter le delimiter

 * @returns une chaine de caractère réduite

 */

 export const removeSegments = (chaine:string, times: number, delimiter: string) =>  {

    const segments = chaine.split(delimiter)

    return segments.slice(0, segments.length - times).join(delimiter)

}