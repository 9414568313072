import { ReInitPicCompActions } from "../types/ReInitPicComp.Types";

const initialState = false;

const reducer  = (state = initialState, action: { type: string } = {type:''}) =>{
    
    switch(action.type){
        case ReInitPicCompActions.RE_INIT:{
            return !state;
        }
        case ReInitPicCompActions.INIT:{
            return false;
        }
        default:{
            return state;
        }
    }
};

export {reducer as ReInitPicCompState};