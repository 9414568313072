declare global { interface Window { env: any; } }

// Get environement variable
export function GetEnv(key: string) {

    if (window.env?.[key] && window.env[key] !== `#{${key}}#`) {
        return window.env[key];
    }
    
    return process.env[key];
}

const reactAppExport = {
    REACT_APP_API_URI: GetEnv('REACT_APP_API_URI'),
    REACT_APP_APP_VERSION: GetEnv('REACT_APP_APP_VERSION'),
    REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID: GetEnv('REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID'),
    REACT_APP_AZURE_AUTHORITY: GetEnv('REACT_APP_AZURE_AUTHORITY'),
    REACT_APP_AZURE_REDIRECT_URI: GetEnv('REACT_APP_AZURE_REDIRECT_URI'),
    REACT_APP_BU_DATADOG: GetEnv('REACT_APP_BU_DATADOG'),
    REACT_APP_CLIENT_TOKEN_DATADOG: GetEnv('REACT_APP_CLIENT_TOKEN_DATADOG'),
    REACT_APP_DATADOG: GetEnv('REACT_APP_DATADOG'),
    REACT_APP_ENV_DATADOG: GetEnv('REACT_APP_ENV_DATADOG'),
    REACT_APP_SERVICE_DATADOG: GetEnv('REACT_APP_SERVICE_DATADOG'),
    REACT_APP_SITE_DATADOG: GetEnv('REACT_APP_SITE_DATADOG'),
    REACT_APP_STAGE: GetEnv('REACT_APP_STAGE'),
    REACT_APP_ENV_STAGE: GetEnv('REACT_APP_ENV_STAGE')
}
export default reactAppExport;