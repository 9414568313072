/**
 * stockage des liste des messages d'alerte dans le front 
 */
 export interface MessagesAlertState {
    messages: string[],
    severity?: string
    duration?: number,
    position?: {horizontal: string, vertical: string}
}

export interface PositionsAlert {
    horizontal: 'center'| 'left'| 'right'
    vertical: 'bottom' | 'top'
}

/**
 * actions possibles pour les messages
 */
export enum MessagesAlertTypes {
    UPDATE_STATE = "@@messagesAlertState/UPDATE_STATE",
    RESET_STATE = "@@messagesAlertState/RESET_STATE"
}