import { Reducer } from "redux";
import { AdminVisiteQualiteState, AdminVisiteQualiteTypes } from "../types/adminVisiteQualite.Types";
import { TrancheTravauxVisites } from "../types/homePageVQ.Types";

export const initialState: AdminVisiteQualiteState = {
    data: null as unknown as TrancheTravauxVisites
}

const reducer: Reducer<AdminVisiteQualiteState> = (state = initialState, action = { type: '', payload: '' }) => {
    switch (action.type) {
        case AdminVisiteQualiteTypes.UPDATE_STATE: {
            let copy = { ...state };
            copy = action.payload;
            return { ...copy }
        }
        case AdminVisiteQualiteTypes.RESET_STATE: {
            return initialState
        }
        default: {
            return state;
        }
    }
}

export { reducer as AdminVisiteQualiteReducer };