import React from "react";
import "../../screens/home-page-vq/HomePageVQ.css";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { useHistory } from "react-router-dom";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { REDIRECT_VQ_TABLEAU, TYPE_AUTOCONTROLE_L4, TYPE_AUTOCONTROLE_L15, TYPE_AUTOCONTROLE_L } from "../../utils/constantes/contantes";
import { AdminVisiteQualiteState, VisiteQualiteState, VisiteQualiteTypes } from "../../store/types/adminVisiteQualite.Types";
import { ApplicationState } from "../../store/store/store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { connect } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import DateAutocontrole from "../Criteres/TypesCriteres/DateAutocontrole";
import SelectAutocontrole from "../Criteres/TypesCriteres/SelectAutocontrole";
import { getEmptyDateCritere, getEmptySelectCritere, emptyTabInfosIntervenant } from "../../utils/constantes/emptyObjects";
import { FicheAutoControleCritere, FicheAutocontroleIntervenant } from "../../store/types/FicheAutocontrole.Types";
import { AutocontroleIntervenants } from "../../store/types/intervenants.Types";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { LabelCritereSelectAutocontroleStyled } from "../../styles/CritereAutocontrole.style";
import { API } from "../../utils/callAPI";
import IntervenantUtils from "../../utils/IntervenantUtils";
import { VisiteQualite } from "../../store/types/homePageVQ.Types";
import { TranchesVisitesQualitesUtils } from "../../utils/TranchesVisitesQualitesUtils";



interface PropsFromDispatch {
    adminVisiteQualiteState: AdminVisiteQualiteState,
    visiteQualiteState: VisiteQualiteState,
    updateVisiteQualiteState: Function
}

const HomePageVQAdmin: React.FC<PropsFromDispatch> = ({ adminVisiteQualiteState, visiteQualiteState, updateVisiteQualiteState }) => {

    /**
    * Constantes et state :
    */

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [visiteActive, setVisiteActive] = React.useState(TYPE_AUTOCONTROLE_L4);
    const [disableBtnSave, setDisableBtnSave] = React.useState(true);

    const backToHomePageVQResult = () => {
        history.push(REDIRECT_VQ_TABLEAU);
    }

    const getTypeAutocontroleButtonClassName = (typeVisite: string) => {
        let className = "btn-radio btn-type-autocontrole";
        if (visiteActive === typeVisite) {
            className += " active";
        }
        return className;
    }

    const handleChangeTypeAutocontrole = (typeVisite: string) => {
        setVisiteActive(typeVisite);
    }

    const saveParameters = () => {
        let params = TranchesVisitesQualitesUtils.trancheVisiteQualiteToParams(adminVisiteQualiteState.data, visiteQualiteState);
        setLoading(true);
        console.log("params", params);

        API.createOrUpdateVisiteQualite(params)
            .then(() => {
                console.log("Visite Qualité mise à jour créé", params);
                setLoading(false);
                history.push(REDIRECT_VQ_TABLEAU);
            }).catch(function (error: any) {
                setLoading(false);
                console.log("Problème lors de la mise à jour de visite qualité", error)
            })
    }

    const propagerIntervenants = () => {
        switch (visiteActive) {
            case TYPE_AUTOCONTROLE_L4: {
                visiteQualiteState.visiteQualiteL15.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL15.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.responsableProgramme));
                visiteQualiteState.visiteQualiteL15.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.conducteurTravaux));
                visiteQualiteState.visiteQualiteL.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.responsableProgramme));
                visiteQualiteState.visiteQualiteL.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL4.conducteurTravaux));
                break;
            }
            case TYPE_AUTOCONTROLE_L15: {
                visiteQualiteState.visiteQualiteL4.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL4.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.responsableProgramme));
                visiteQualiteState.visiteQualiteL4.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.conducteurTravaux));
                visiteQualiteState.visiteQualiteL.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.responsableProgramme));
                visiteQualiteState.visiteQualiteL.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL15.conducteurTravaux));
                break;
            }
            case TYPE_AUTOCONTROLE_L: {
                visiteQualiteState.visiteQualiteL15.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL15.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.responsableProgramme));
                visiteQualiteState.visiteQualiteL15.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.conducteurTravaux));
                visiteQualiteState.visiteQualiteL4.chargeMissionQualite = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.chargeMissionQualite));
                visiteQualiteState.visiteQualiteL4.responsableProgramme = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.responsableProgramme));
                visiteQualiteState.visiteQualiteL4.conducteurTravaux = JSON.parse(JSON.stringify(visiteQualiteState.visiteQualiteL.conducteurTravaux));
                break;
            }
        }
        updateVisiteQualiteState(visiteQualiteState);
    }

    // Dates 
    const getDateCritere = () => {
        let critereDate = getEmptyDateCritere();
        critereDate.title = 'Date prévisionnelle de visite';
        return critereDate;
    }

    const getDateCritereL4 = () => {
        let critereDate = getDateCritere();
        critereDate.value = visiteQualiteState.visiteQualiteL4.datePlanificationVisite;
        return critereDate;
    }

    const getDateCritereL15 = () => {
        let critereDate = getDateCritere();
        critereDate.value = visiteQualiteState.visiteQualiteL15.datePlanificationVisite;
        return critereDate;
    }

    const getDateCritereL = () => {
        let critereDate = getDateCritere();
        critereDate.value = visiteQualiteState.visiteQualiteL.datePlanificationVisite;
        return critereDate;
    }

    const updateDateL4 = (critere: FicheAutoControleCritere) => {
        let value = critere.value as number;
        visiteQualiteState.visiteQualiteL4.datePlanificationVisite = value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    }

    const updateDateL15 = (critere: FicheAutoControleCritere) => {
        let value = critere.value as number;
        visiteQualiteState.visiteQualiteL15.datePlanificationVisite = value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    }

    const updateDateL = (critere: FicheAutoControleCritere) => {
        let value = critere.value as number;
        visiteQualiteState.visiteQualiteL.datePlanificationVisite = value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    }

    // Commentaires
    const handleChangeCommentaireL4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        visiteQualiteState.visiteQualiteL4.commentaire = event.target.value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    };

    const handleChangeCommentaireL15 = (event: React.ChangeEvent<HTMLInputElement>) => {
        visiteQualiteState.visiteQualiteL15.commentaire = event.target.value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    };

    const handleChangeCommentaireL = (event: React.ChangeEvent<HTMLInputElement>) => {
        visiteQualiteState.visiteQualiteL.commentaire = event.target.value;
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    };

    // Intervenants
    const getSelectCritereL4 = (refType: string) => {
        return getSelectCritere(refType, visiteQualiteState.visiteQualiteL4.responsableProgramme.nom, getLabelCTR(visiteQualiteState.visiteQualiteL4.conducteurTravaux), visiteQualiteState.visiteQualiteL4.chargeMissionQualite.nom);
    }

    const getSelectCritereL15 = (refType: string) => {
        return getSelectCritere(refType, visiteQualiteState.visiteQualiteL15.responsableProgramme.nom, getLabelCTR(visiteQualiteState.visiteQualiteL15.conducteurTravaux), visiteQualiteState.visiteQualiteL15.chargeMissionQualite.nom);
    }

    const getSelectCritereL = (refType: string) => {
        return getSelectCritere(refType, visiteQualiteState.visiteQualiteL.responsableProgramme.nom, getLabelCTR(visiteQualiteState.visiteQualiteL.conducteurTravaux), visiteQualiteState.visiteQualiteL.chargeMissionQualite.nom);
    }

    const sortIntervenants = (referentiels: string[]) => {
        return referentiels ? referentiels.sort((a: string, b: string) => a.localeCompare(b)) : [] as string[]
    }

    const getLabelCTR = (intervenant: FicheAutocontroleIntervenant) => {
        return intervenant.nom && !intervenant.isInterne ? intervenant.nom + ' (Externe)' : intervenant.nom;
    }

    const getSelectCritere = (refType: string, valueRPR: string, valueCTR: string, valueCMQ: string) => {
        let critere = getEmptySelectCritere();
        critere.options = refType;

        switch (refType) {
            case AutocontroleIntervenants.responsablesProgramme: {
                critere.value = valueRPR;
                critere.choices = sortIntervenants((visiteQualiteState.responsablesProgramme as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Responsable programme';
                break;
            }
            case AutocontroleIntervenants.conducteursTravaux: {
                critere.value = valueCTR;
                critere.choices = sortIntervenants((visiteQualiteState.conducteursTravaux as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.intervenants = visiteQualiteState.conducteursTravaux !== null ? visiteQualiteState.conducteursTravaux : undefined
                critere.title = 'Conducteur de travaux';
                break;
            }
            case AutocontroleIntervenants.chargesMissionQualite: {
                critere.value = valueCMQ;
                critere.choices = sortIntervenants((visiteQualiteState.chargesMissionQualite as FicheAutocontroleIntervenant[])?.map((item: FicheAutocontroleIntervenant) => item.nom));
                critere.title = 'Chargé de mission qualité'
                break;
            }
        }

        return critere;
    }

    const saveReferentielL4 = (critere: FicheAutoControleCritere) => {
        saveReferentiel(critere, visiteQualiteState.visiteQualiteL4);
    }

    const saveReferentielL15 = (critere: FicheAutoControleCritere) => {
        saveReferentiel(critere, visiteQualiteState.visiteQualiteL15);
    }

    const saveReferentielL = (critere: FicheAutoControleCritere) => {
        saveReferentiel(critere, visiteQualiteState.visiteQualiteL);
    }

    const saveReferentiel = (critere: FicheAutoControleCritere, visite: VisiteQualite) => {
        switch (critere.options) {
            case AutocontroleIntervenants.responsablesProgramme:
                let rprFound = visiteQualiteState.responsablesProgramme.find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                visite.responsableProgramme = rprFound ? rprFound : emptyTabInfosIntervenant;
                break;
            case AutocontroleIntervenants.chargesMissionQualite:
                let cmqFound = visiteQualiteState.chargesMissionQualite.find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                visite.chargeMissionQualite = cmqFound ? cmqFound : emptyTabInfosIntervenant;
                break;
            case AutocontroleIntervenants.conducteursTravaux:
                let ctrFound = visiteQualiteState.conducteursTravaux.find((intervenant: FicheAutocontroleIntervenant) => intervenant.nom === critere.value as string) as FicheAutocontroleIntervenant;
                visite.conducteurTravaux = ctrFound ? ctrFound : emptyTabInfosIntervenant;
                break;
        }
        updateVisiteQualiteState(visiteQualiteState);
        setDisableBtnSave(false);
    }

    // Effect
    React.useEffect(() => {
        getReferentiels(AutocontroleIntervenants.responsablesProgramme);
        getReferentiels(AutocontroleIntervenants.conducteursTravaux);
        getReferentiels(AutocontroleIntervenants.chargesMissionQualite);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getReferentiels = (refType: string) => {
        let codeFiliale = refType !== AutocontroleIntervenants.chargesMissionQualite ?
            refType === AutocontroleIntervenants.responsablesProgramme ? Number(adminVisiteQualiteState.data.codeRegroupementResponsabilite).toString() : Number(adminVisiteQualiteState.data.codeSociete).toString() : '';

        setLoading(true);
        API.getReferentiels(refType, codeFiliale)
            .then(function (response: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType + "&codeFiliale=" + codeFiliale, response.data);
                let refList: FicheAutocontroleIntervenant[] = response.data.map((item: any) => {
                    let tabInfosIntervant: FicheAutocontroleIntervenant = {
                        nom: item.prenom.toUpperCase() + " " + item.nom.toUpperCase(),
                        mail: item.mail,
                        isInterne: (item.typeInterneExterne === 'I' || item.typeInterneExterne === ''),
                        id_gpi: item.id_gpi
                    }
                    return tabInfosIntervant;
                });

                refList = IntervenantUtils.removeDuplicate(refList);

                switch (refType) {
                    case AutocontroleIntervenants.chargesMissionQualite:
                        visiteQualiteState.chargesMissionQualite = refList;
                        break;
                    case AutocontroleIntervenants.responsablesProgramme:
                        visiteQualiteState.responsablesProgramme = refList;
                        break;
                    case AutocontroleIntervenants.conducteursTravaux:
                        visiteQualiteState.conducteursTravaux = refList;
                        break;
                }
                updateVisiteQualiteState(visiteQualiteState);
                setLoading(false);
            }).catch(function (error: any) {
                console.log("Réponse de /referentiels/intervenants?type=" + refType + "&codeFiliale=" + codeFiliale, error);
            });
    }

    /**
     * Display
     */
    return (
        <div>
            <div className='card-content'>
                <LoadingBackdrop loading={loading} />
                <div className='mail-title-container'>
                    <span className='mail-title-arrow'>
                        <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" onClick={() => backToHomePageVQResult()} />
                    </span>
                    <span className='mail-title-text'>
                        Retour à la recherche
                </span>
                </div>

                <div className='parametre-recapitulatif-card'>
                    <div className='parametre-recapitulatif-value'> Récapitulatif</div>
                    <div className='parametre-recapitulatif-title'> Nom de la filiale : </div>
                    <div className='parametre-recapitulatif-value'>{adminVisiteQualiteState.data.codeSociete + ' - ' + adminVisiteQualiteState.data.libelleFiliale}</div>
                    <div className='parametre-recapitulatif-title'> Nom interne du programme :</div>
                    <div className='parametre-recapitulatif-value'>{adminVisiteQualiteState.data.codeProgramme + ' - ' + adminVisiteQualiteState.data.libelleProgramme}</div>
                    <div className='parametre-recapitulatif-title'> Tranche travaux</div>
                    <div className='parametre-recapitulatif-value'>{adminVisiteQualiteState.data.codeTranche + ' - ' + adminVisiteQualiteState.data.libelleTranche}</div>
                </div>
                <div className="radio-bloc">
                    <div className="radio-btn-div">
                        <ButtonGroup size="large" color="primary" aria-label="outlined primary button group" className="radio-group-bloc">
                            <Button className={getTypeAutocontroleButtonClassName(TYPE_AUTOCONTROLE_L4)} onClick={() => handleChangeTypeAutocontrole(TYPE_AUTOCONTROLE_L4)}>L-4</Button>
                            <Button className={getTypeAutocontroleButtonClassName(TYPE_AUTOCONTROLE_L15)} onClick={() => handleChangeTypeAutocontrole(TYPE_AUTOCONTROLE_L15)}>L-1,5</Button>
                            <Button className={getTypeAutocontroleButtonClassName(TYPE_AUTOCONTROLE_L)} onClick={() => handleChangeTypeAutocontrole(TYPE_AUTOCONTROLE_L)}>L</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className='parametre-criteres-card'>
                    <div className='parametre-criteres-container'>
                        <div>
                            {visiteActive === TYPE_AUTOCONTROLE_L4 ?
                                <div>
                                    <DateAutocontrole critere={getDateCritereL4()} updateCritere={updateDateL4} disablePast={true} />
                                    <SelectAutocontrole critere={getSelectCritereL4(AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentielL4} />
                                    <SelectAutocontrole critere={getSelectCritereL4(AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentielL4} />
                                    <SelectAutocontrole critere={getSelectCritereL4(AutocontroleIntervenants.chargesMissionQualite)} updateCritere={saveReferentielL4} />
                                    <LabelCritereSelectAutocontroleStyled>Commentaire</LabelCritereSelectAutocontroleStyled>
                                    <div className={"critera-input-text-box"}>
                                        <TextField
                                            className=' critera-input-text'
                                            onChange={handleChangeCommentaireL4}
                                            value={visiteQualiteState.visiteQualiteL4.commentaire}
                                            placeholder="Ecrivez votre commentaire ici..."
                                            multiline />
                                    </div>
                                </div>
                                : ''}

                            {visiteActive === TYPE_AUTOCONTROLE_L15 ?
                                <div>
                                    <DateAutocontrole critere={getDateCritereL15()} updateCritere={updateDateL15} disablePast={true} />
                                    <SelectAutocontrole critere={getSelectCritereL15(AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentielL15} />
                                    <SelectAutocontrole critere={getSelectCritereL15(AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentielL15} />
                                    <SelectAutocontrole critere={getSelectCritereL15(AutocontroleIntervenants.chargesMissionQualite)} updateCritere={saveReferentielL15} />
                                    <LabelCritereSelectAutocontroleStyled>Commentaire</LabelCritereSelectAutocontroleStyled>
                                    <div className={"critera-input-text-box"}>
                                        <TextField
                                            className=' critera-input-text'
                                            onChange={handleChangeCommentaireL15}
                                            value={visiteQualiteState.visiteQualiteL15.commentaire}
                                            placeholder="Ecrivez votre commentaire ici..."
                                            multiline />
                                    </div>
                                </div>
                                : ''}

                            {visiteActive === TYPE_AUTOCONTROLE_L ?
                                <div>
                                    <DateAutocontrole critere={getDateCritereL()} updateCritere={updateDateL} disablePast={true} />
                                    <SelectAutocontrole critere={getSelectCritereL(AutocontroleIntervenants.responsablesProgramme)} updateCritere={saveReferentielL} />
                                    <SelectAutocontrole critere={getSelectCritereL(AutocontroleIntervenants.conducteursTravaux)} updateCritere={saveReferentielL} />
                                    <SelectAutocontrole critere={getSelectCritereL(AutocontroleIntervenants.chargesMissionQualite)} updateCritere={saveReferentielL} />
                                    <LabelCritereSelectAutocontroleStyled>Commentaire</LabelCritereSelectAutocontroleStyled>
                                    <div className={"critera-input-text-box"}>
                                        <TextField
                                            className=' critera-input-text'
                                            onChange={handleChangeCommentaireL}
                                            value={visiteQualiteState.visiteQualiteL.commentaire}
                                            placeholder="Ecrivez votre commentaire ici..."
                                            multiline />
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="propager-button">
                    <Button className="btn-radio btn-go-autocontrole" key="btn-autocontrole" variant="outlined" onClick={() => propagerIntervenants()}> <SettingsOutlinedIcon />Propager les intervenants à toutes les périodes</Button>
                </div>
                <div className="parametre-button">
                    <Button disabled={disableBtnSave} className={disableBtnSave ? "btn-radio btn-go-autocontrole desactivate" : "btn-radio btn-go-autocontrole active"} key="btn-autocontrole" variant="outlined" onClick={() => saveParameters()}> Enregistrer les parametres </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ adminVisiteQualiteState, visiteQualiteState }: ApplicationState) => ({
    adminVisiteQualiteState: adminVisiteQualiteState,
    visiteQualiteState: visiteQualiteState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateVisiteQualiteState: (params: VisiteQualiteState) => dispatch({ type: VisiteQualiteTypes.UPDATE_STATE, payload: params })
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(HomePageVQAdmin);