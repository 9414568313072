import React from "react";
import "./HomePageCMQ.css";
import Button from "@material-ui/core/Button";
import { REDIRECT_HOME_VQ, REDIRECT_HOME_ACQ } from "../../utils/constantes/contantes";
import { useHistory } from "react-router-dom";

const HomePageCMQ: React.FC<{}> = () => {

    const history = useHistory();

    const goToVisitesQualites = () => {
        history.push(REDIRECT_HOME_VQ);
    }

    const goToAutosEvaluations = () => {
        history.push(REDIRECT_HOME_ACQ);
    }

  /**
   * Display
   */
  return (
      <div>
        <div className='card-content'>
            <div className="margin-y-8">
		        <Button className="btn-radio btn-go-autocontrole active" onClick={() => goToVisitesQualites()}> Administrer les Visites Qualités </Button>
		    </div>
            <div className="margin-y-8">
		        <Button className="btn-radio btn-go-autocontrole active" onClick={() => goToAutosEvaluations()}> Gérer les auto-évaluations </Button>
		    </div>
        </div>
      </div>
  );
};

export default HomePageCMQ;
