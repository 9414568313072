import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Erreur from "./components/Erreur/Erreur";
import Rgpd from "./components/Rgpd/rgpd"
import FicheAutocontrole from "./components/FicheAutocontrole/FicheAutocontrole";
import Home from "./screens/home/Home";
import ParametresAutocontrole from "./screens/parametres-autocontrole/ParametresAutocontrole";
import { checkToken, clearLocalStorage, getAccessToken, getUserMail, isAccessTokenValid, isAuthApiOK, setAuthApi, getUserRole } from "./utils/AccessToken";
import { API } from "./utils/callAPI";
import { REDIRECT_FICHE, REDIRECT_HOME_PAGE, REDIRECT_PARAMETRES, REDIRECT_INTERDIT, REDIRECT_ERROR, REDIRECT_RGPD, REDIRECT_DELAI, REDIRECT_MAIL, REDIRECT_HOME_ACQ, REDIRECT_HOME_VQ, REDIRECT_VQ_TABLEAU, REDIRECT_VQ_ADMIN } from "./utils/constantes/contantes";
import Mail from "./screens/Mail/mail";
import LoadingBackdrop from "./components/LoadingBackdrop/LoadingBackdrop";
import { UserUtils } from "./utils/UserUtils";
import { getVersionBack } from "./utils/backUtils";
import HomePage from "./screens/home-page/HomePage";
import { userRoles } from "./store/types/user.Types";
import HomePageVQ from "./screens/home-page-vq/HomePageVQ";
import HomePageVQResult from "./components/homePageVQ/HomePageVQResult";
import HomePageVQAdmin from "./components/homePageVQ/HomePageVQAdmin";

const Routes: React.FC = () => {

  /**
   * Constantes
   */
  const history = useHistory();
  const [loading, setLoading] = React.useState(true)

  let search = useLocation().search;
  const token = new URLSearchParams(search).get('token');


  /**
   * API
   */
  const getRoles = () => {

    if (isAuthApiOK()) {
      console.log('getRoles - isAuthApiOK - true');
      API.getUserRole()
        .then((response) => {
          localStorage.setItem('version', '');
          getVersionBack(response.data.version);
          localStorage.setItem('role', response.data.role);
          setAuthApi(true);
          if (UserUtils.isRoleUserValid()) {
            getInfosRgpd()
          } else {
            console.error("Le role n'est pas valide.")
            setLoading(false);
            history.push(REDIRECT_INTERDIT)
          }
        })
        .catch((error) => {
          console.log('Appel getUserRole KO');
          console.log(error);
          setAuthApi(false);
          setLoading(false);
          history.push(REDIRECT_INTERDIT)
        })
    } else {
      console.log('getRoles - isAuthApiOK - false');
      history.push(REDIRECT_INTERDIT);
      setLoading(false);
    }
  }

  const getInfosRgpd = () => {
    let mail = getUserMail();
    API.getRgpd(mail)
      .then((response) => {
        setLoading(false);
        UserUtils.isDateRGPDValid(response.data?.dateMaj) ? localStorage.setItem('rgpd', response.data.dateMaj) : history.push('/rgpd');
      })
      .catch(() => {
        setLoading(false);
        history.push(REDIRECT_ERROR)
      })
  }

  const initAccessToken = () => {
    getAccessToken()
      .then(() => {
        if (checkToken()) {//!!localStorage.getItem('accessToken')) 
          getRoles()
        } else {
          console.log('initAccessToken checkToken ko');
          setLoading(false)
          history.push(REDIRECT_INTERDIT);
        }
      })
      .catch(() => {
        setLoading(false)
        history.push(REDIRECT_INTERDIT);
      });
  }

  /**
   * Init
   */
  useEffect(() => {
    // si on vient d'une page erreur : on ne fait rien 
    const pathname = window.location.pathname;
    const pathsAutorized = [REDIRECT_ERROR, REDIRECT_INTERDIT, REDIRECT_RGPD, REDIRECT_DELAI]
    if (pathsAutorized.includes(pathname)) {
      setLoading(false);
    }

    // 1er cas : Vérification si on a pas usurpé le role de l'utilisateur. 
    else if (!UserUtils.isInfosUserSessionValid() && UserUtils.isRoleUserValid()
      && isAccessTokenValid()) {
      console.error("usurpation de token")
      setLoading(false);
      clearLocalStorage();
      history.push(REDIRECT_INTERDIT)
    }
    // 2eme cas : Si pas d'usurpation et que les infos ne sont pas valides : on créait un token 
    else if (!UserUtils.isInfosUserSessionValid()) {
      console.log("Initialisation du token...")
      clearLocalStorage();
      initAccessToken();
    }
    // sinon tout est ok
    else {
      console.log("Le token et le role ont été vérifiés.")
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  /**
   * Routes
   */
  return (<div>
    {loading ?
      <LoadingBackdrop loading={loading} />
      :
      <Switch>
        <Route exact path="/erreur" render={() => (
          <Erreur />
        )} />
        <Route exact path={REDIRECT_HOME_PAGE} render={() => (
          !!token ? <FicheAutocontrole /> : <Home />
        )} /><Route exact path={REDIRECT_FICHE} render={() => (
          <FicheAutocontrole />
        )} /><Route exact path={REDIRECT_PARAMETRES} render={() => (
          <ParametresAutocontrole />
        )} /><Route exact path={REDIRECT_RGPD} render={() => (
          <Rgpd />
        )} /><Route exact path={REDIRECT_MAIL} render={() => (
          <Mail />
        )} /><Route exact path={REDIRECT_HOME_ACQ} render={() => (
          <HomePage />
        )} /><Route exact path={REDIRECT_HOME_VQ} render={() => (
          (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) ?
            <HomePageVQ /> : <HomePage />
        )} /><Route exact path={REDIRECT_VQ_TABLEAU} render={() => (
          (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) ?
            <HomePageVQResult /> : <HomePage />
        )} /><Route exact path={REDIRECT_VQ_ADMIN} render={() => (
          (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) ?
            <HomePageVQAdmin /> : <HomePage />
        )} /><Route exact path={"*"}>
          <Home />
        </Route>
      </Switch>
    }
  </div>);
};

export default Routes;
