import { Reducer } from "redux";
import { UserState, UserTypes } from "../types/user.Types";

export const initialState: UserState={
    settingRGPD:false
}

const reducer : Reducer<UserState> = (state = initialState, action = {type:'', payload:''}) => {
    switch(action.type){
        case UserTypes.GET_USER:{
            return state;
        }
        case UserTypes.SET_USER:{
            let copy  = {...state}
            copy = action.payload;
            return {...copy};
        }
        default:{
            return state;
        }
    }
}

export {reducer as UserReducer};