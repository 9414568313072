import Env from './Env';

export const msalConfig = {
  auth: {
    clientId: Env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID as string,
    authority: Env.REACT_APP_AZURE_AUTHORITY as string,
    redirectUri: Env.REACT_APP_AZURE_REDIRECT_URI as string
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};