import React from 'react';
import Helper from '../Helper/Helper'
import { HelperTypeEnum } from '../Helper/HelperTypeEnum'
import Button from '@material-ui/core/Button';
import './rgpd.scss';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { useLocation, useHistory } from 'react-router-dom';
import { getEmptyCheckboxCritere } from "../../utils/constantes/emptyObjects";
import CheckBoxAutocontrole from "../../components/Criteres/TypesCriteres/CheckboxAutocontrole"
import { FicheAutoControleCritere } from "../../store/types/FicheAutocontrole.Types";
import { REDIRECT_FICHE, REDIRECT_HOME_PAGE, REDIRECT_RGPD } from "../../utils/constantes/contantes";
import { API } from "../../utils/callAPI";
import { getUserMail } from '../../utils/AccessToken';
import jwt_decode from "jwt-decode";

const message = "Dans l’autocontrôle, toutes les zones de commentaires vous permettent de vous exprimer librement et de rendre compte de votre activité. Afin de respecter la vie privée des personnes que vous pourriez citer, vous devez impérativement rédiger des commentaires objectifs et jamais excessifs ou insultants, à l’exclusion de toute donnée considérée comme sensible (origine raciale ou ethnique, opinions politiques, philosophiques ou religieuses, appartenance syndicale, données relatives à la santé ou à la vie sexuelle, infractions, condamnations, mesure de sûreté)."

const Rgpd: React.FC = () => {

    const history = useHistory();
    const search = useLocation().search;
    const mode = new URLSearchParams(search).get('mode');
    const [valueLecture, setValueLecture] = React.useState([] as string[]);

    const getNextButton = () => {
        return (
            <Button className={"btn-rgpd-next"} key="btn-autocontrole" variant="outlined"
                disabled={valueLecture && valueLecture.length === 0} onClick={() => updateRgpdStatut()}>
                Suivant
            </Button>)
    }

    const getReturnButton = () => {
        return (
            <Button className={"btn-rgpd-next"} key="btn-autocontrole" variant="outlined"
                onClick={() => history.push(REDIRECT_FICHE, { from: REDIRECT_RGPD })}>
                Retour
            </Button>)
    }

    const getCheckboxCritere = () => {
        let critere = getEmptyCheckboxCritere();
        critere.value = valueLecture;
        critere.choices = [' J’ai lu et pris connaissance des règles.'];
        return critere;
    }


    const saveChoice = (critere: FicheAutoControleCritere) => {
        setValueLecture(critere.value as string[])
    }

    const updateRgpdStatut = () => {
        let mail = getUserMail(); //localStorage.getItem("mail")
        if (valueLecture && valueLecture.length !== 0 && mail) {
            const params = {
                mail: mail as string
            }
            API.updateRgpd(params)
                .then(function() {
                    history.push(REDIRECT_HOME_PAGE)
                }).catch((error) => {
                    console.log(error)
                })
        }
    }

    const cssRgpdTerrain = () => {
        let roles : {role:string} = {role: ''};
        roles = jwt_decode(localStorage.getItem("role") as string) as {role:string};
        return !!roles && roles.role.toLowerCase() === 'terrain' ? ' rgpd-terrain' : '';
    }

    return (
        <div className="rgpd-bloc">
            <div className={"rgpd-text" + cssRgpdTerrain()}>
                {mode === 'info' ?
                    <div className='display-mobile tab-mobile-menu customGroupMenu-tabMobile'>
                        <div className="customGroupMenu-tabMobile-margin" onClick={() => history.push(REDIRECT_FICHE, { from: REDIRECT_RGPD })} >
                            <ArrowBackIosOutlinedIcon className="customGroupMenu-tabMobile-arrow" />
                            <span className='tab-page-title customGroupMenu-tabMobile-title'>
                                Retour
                            </span>
                        </div>
                    </div> : ''}
                <Helper type={HelperTypeEnum.WARNING} title="Attention" description={message} />

                {mode !== 'info' ?
                    <div>
                        <div className="rgpd-acceptation">
                            <CheckBoxAutocontrole critere={getCheckboxCritere()} updateCritere={saveChoice} />
                        </div>
                    </div> :
                    <div className="rgpd-button-retour-tablette">
                        {getReturnButton()}
                    </div>
                }
            </div>
            {mode !== 'info' ?
                <div className="rgpd-button">
                    {getNextButton()}
                </div> : ''}
        </div>
    );
};


export default Rgpd;