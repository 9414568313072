import { FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";

/**
 * stockage des liste des intervenants dans le front 
 */
export interface IntervenantsState {
    responsablesProgramme: FicheAutocontroleIntervenant[] | null,
    responsablesTechnique: FicheAutocontroleIntervenant[] | null,
    conducteursTravaux: FicheAutocontroleIntervenant[] | null,
    directeursProgrammes?: FicheAutocontroleIntervenant[] | null,
    directeursOperationnel?: FicheAutocontroleIntervenant[] | null,
    chargesMissionQualite?: FicheAutocontroleIntervenant[] | null
}

export interface IntervenantsToSave {
    chargeMissionQualite?: FicheAutocontroleIntervenant,
    responsableProgramme: FicheAutocontroleIntervenant,
    responsableTechnique: FicheAutocontroleIntervenant,
    conducteurTravaux: FicheAutocontroleIntervenant,
    date: number | null

}

export enum IntervenantsAutocontroleTypes {
    UPDATE_STATE = "@@intervenantsState/UPDATE_STATE",
    RESET_STATE = "@@intervenantsState/RESET_STATE"
}

export enum AutocontroleIntervenants {
    chargesMissionQualite = 'CMQ',
    responsablesProgramme = 'RPR',
    responsablesTechnique = 'RTE',
    conducteursTravaux = 'CTR',
    directeursOperationnel = 'DOP',
    directeursProgrammes = 'DPR'
}