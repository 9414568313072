import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../../store/store/store';
import { FicheAutoControleCritere } from '../../../store/types/FicheAutocontrole.Types';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { colorsAutocontrole } from '../../../styles/ColorsAutocontrole.style';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled, CheckboxAutocontroleStyled } from '../../../styles/CritereAutocontrole.style';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import { NO_ACTION, NO_SOLUTION } from '../../../utils/constantes/contantes';


interface PropsFromState {
    critere: FicheAutoControleCritere,
    updateCritere: Function,
    isTypesActivated?: boolean
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const CheckboxAutocontrole: React.FC<AllProps> = ({ critere, updateCritere, goBackStateHasChanged }) => {

    const choices = !!critere.choices ? critere.choices as string[] : ["Oui", "Non"]

    let allChecked = true;
    choices.forEach((choice: string) => {
        if (choice !== NO_ACTION && choice !== NO_SOLUTION && !(critere.value as string[]).includes(choice)) {
            allChecked = false;
        }
    })

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.activated = event.target.checked;
        updateCritere(critere);
        goBackStateHasChanged();
    };

    const handleChangeCheckbox = (event: ChangeEvent<{}>, checked: boolean) => {
        let choiceInput = (event.target as HTMLInputElement).value as unknown as string;
        if ((critere.value as string[]).includes(choiceInput)) {
            critere.value = (critere.value as string[]).filter((choice: string) => { return choice !== choiceInput });
        } else {
            critere.value = (critere.value as string[]).concat(choiceInput);
        }
        updateCritere(critere);
        goBackStateHasChanged();
    };

    const selectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (critere.choices) {
            critere.choices.forEach((choice: number | string) => {
                if (choice !== NO_ACTION && choice !== NO_SOLUTION) {
                    if (!e.target.checked) {
                        critere.value = (critere.value as string[]).filter((choiceFilter: string) => { return choiceFilter !== choice });
                    } else {
                        critere.value = (critere.value as string[]).concat(choice.toString());
                    }
                    updateCritere(critere);
                    goBackStateHasChanged();
                }
            })
        }
    };

    const selectBorderColor = (choice: string) => {
        if (!critere.activated) {
            return { borderColor: colorsAutocontrole.disabled, color: colorsAutocontrole.disabled }
        }
        return (critere.value as string[]).includes(choice) ? { borderColor: colorsAutocontrole.primary } :
            { borderColor: colorsAutocontrole.checkboxDefault, color: colorsAutocontrole.checkboxDefault }
    }


    const createCheckbox = () => {
        let radioButtons: JSX.Element[] = [];

        choices.forEach((choice: string) => {
            radioButtons.push(
                <div key={critere.index.toString().concat(choice)} className="critera-box" style={selectBorderColor(choice)}>
                    <CheckboxAutocontroleStyled value={choice} onChange={handleChangeCheckbox} checked={(critere.value as string[]).includes(choice) && critere.activated} disabled={!critere.activated} />
                    <span style={selectBorderColor(choice)}>{choice}</span>
                </div>
            )
        })

        return radioButtons;
    }

    return (<div>
        <div className="critera-label">
            <LabelCritereAutocontroleStyled>{critere.title}
                {critere.active ?
                    <SwitchAutocontroleStyled className="elt-float-right" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt="" />} />
                    : ""
                }
            </LabelCritereAutocontroleStyled>
            <LabelCritereAutocontroleStyled className="elt-float-right">
                Sélectionner tout
                        <CheckboxAutocontroleStyled onChange={selectItem} disabled={!critere.activated} checked={allChecked} ></CheckboxAutocontroleStyled>
            </LabelCritereAutocontroleStyled>
        </div>

        {createCheckbox()}
    </div>);
};


const mapStateToProps = ({ goBackState }: ApplicationState) => ({
    goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxAutocontrole);