import React, { ChangeEvent } from 'react';
import {FicheAutoControleCritere} from '../../../store/types/FicheAutocontrole.Types';
import { TextField } from '@material-ui/core';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { LabelCritereSelectAutocontroleStyled, SwitchAutocontroleStyled, AutocompleteStyled} from '../../../styles/CritereAutocontrole.style';
import SwitchIconDefault from "./../../../assets/value-indicator-default.svg";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ApplicationState } from '../../../store/store/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GoBackCurrentState, GoBackCurrentStateActions } from '../../../store/types/GoBackState.Types';
import { connect } from 'react-redux';
import Intervenant from '../../../utils/IntervenantUtils'

interface PropsFromState {
    critere:FicheAutoControleCritere,
    updateCritere: Function
}

interface PropsFromDispatch {
    goBackStateHasChanged: Function,
    goBackState: GoBackCurrentState
}

type AllProps = PropsFromDispatch & PropsFromState

const SelectAutocontrole: React.FC<AllProps> = ({critere, updateCritere, goBackStateHasChanged}) => {

    const choices = !!critere.choices ? critere.choices as string[] : [""];

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        critere.activated = event.target.checked;
        updateCritere(critere);
        goBackStateHasChanged();
    };

    const handleChangeSelect = (event: ChangeEvent<{}>, value: unknown, reason: AutocompleteChangeReason) => {

        critere.value = !!value? value as string: "";
        
        updateCritere(critere);
        goBackStateHasChanged();
    };

    const getClassNameSelectState = () => {
        if(!critere.activated){
            return " select-disabled";
        }else{
            if(critere.value === ""){
                return " selectempty";
            }else{
                return " select-fill";
            }
        }
    }

    return(<div style={critere.active ? {display:'flex'} : {}}>
                <AutocompleteStyled
                    key={critere.value as string}
                    options={choices}
                    value={critere.value === "" ? undefined  : critere.value as string}
                    getOptionLabel={(choice) => choice + '' + Intervenant.getLabel(choice, critere.intervenants) as string}
                    onChange={handleChangeSelect}
                    noOptionsText={'Aucun intervenant n\'est renseigné dans le SI.'}
                    renderInput={(params) => 
                        <div className={"critera-select-box".concat(getClassNameSelectState())}>
                            <LabelCritereSelectAutocontroleStyled>{critere.title}</LabelCritereSelectAutocontroleStyled>
                            <TextField {...params} placeholder={critere.activated ? "A compléter" : ""}/>
                        </div>
                    }
                    popupIcon={<ArrowForwardIosIcon/>}
                    closeIcon={<></>}
                    disabled={!critere.activated}
                /> 
                <div>
                    {critere.active ? 
                        <SwitchAutocontroleStyled className="elt-float-right critera-select-switch" checked={critere.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt=""/>}/> 
                        : ""
                    }
                </div>
                
            </div>);
};
  

const mapStateToProps = ({ goBackState }: ApplicationState) => ({
    goBackState: goBackState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        goBackStateHasChanged: () => dispatch({ type: GoBackCurrentStateActions.CHANGING_STATE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAutocontrole);