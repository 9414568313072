import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/store/store";
import { FicheAutocontroleTab, FicheAutocontrole, FicheAutocontroleActionTypes } from "../../store/types/FicheAutocontrole.Types";
import TabAutocontrole from '../TabAutocontrole/TabAutocontrole';
import CustomTabMenu from "../CustomMenu/CustomTabMenu";
import TabInfosAutocontrole from "../TabAutocontrole/TabInfosAutocontrole";
import { useHistory, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { API } from '../../utils/callAPI'
import { CurrentState, CurrentStateActions } from "../../store/types/CurrentState.Types";
import jwt_decode from "jwt-decode";
import TabSyntheseAutocontrole from "../TabAutocontrole/TabSyntheseAutocontrole";
import { AUTOCONTROLE_STATUT, Filiale, GeneralInformationsState, GeneralInformationsTypes, Programme, TrancheTravaux } from "../../store/types/generalInformations.Types";
import { REDIRECT_AUTOCONTROLE_TERMINE, REDIRECT_HOME_ACQ, SEVERITY } from "../../utils/constantes/contantes";
import { getUserFiliales, getUserRole, isAccessTokenValid } from "../../utils/AccessToken";
import { userRoles } from "../../store/types/user.Types";
import { IntervenantsAutocontroleTypes } from "../../store/types/intervenants.Types";
import { ficheAutocontroleToGeneralInformations, infosCotationToGeneralInformations, tabInfosToGeneralInformations, trancheTravauxToGeneralInformations } from "../../utils/mappers/generalInformationsMapper";
import { MessagesAlertState, MessagesAlertTypes } from "../../store/types/MessagesAlert.Types";
import { MessageAutoControleInaccessible } from "../../utils/InformationMessage";
import { HomePageState, HomePageTypes, infosCotation } from "../../store/types/homePage.Types";
import { removeSegments } from "../../utils/autocontroleUtils";
import { updateCurrentGroup } from "../../store/actions/currentState.Action";
import { TabSynthese, TabSyntheseStateActions } from "../../store/types/TabSynthese.Types";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";

interface PropsFromDispatch {
  data: FicheAutocontrole,
  generalInformations: GeneralInformationsState
  fetchData: Function,
  updateCurrentPage: Function,
  resetIntervenantsState: Function,
  updateIsTabInfos: Function,
  resetGeneralInformationState: Function,
  setGeneralInformationsState: Function,
  updateMessageSnackBar: Function,
  updateHomePageState: Function,
  currentState: CurrentState,
  updateSynthese: Function
}

const FicheAutoControle: React.FC<PropsFromDispatch> = ({ updateSynthese, currentState, updateHomePageState, data, fetchData, updateCurrentPage, updateIsTabInfos, resetIntervenantsState, setGeneralInformationsState, resetGeneralInformationState, updateMessageSnackBar, generalInformations }) => {

  const history = useHistory();

  let search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const [isTokenPresent, setIsTokenPresent] = React.useState(!!token);
  const [loading, setLoading] = React.useState(false);
  let typeMailAC = '';


  const isNotFinished = (statut: string) => {
    return (statut === AUTOCONTROLE_STATUT.OUVERT || statut === AUTOCONTROLE_STATUT.FICHE_COMPLETEE)
  }

  const isFinished = (statut: string) => {
    return (statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE || statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE || statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE)
  }

  const isClosed = (statut: string) => {
    return (statut === AUTOCONTROLE_STATUT.FERME || !statut)
  }

  const isInaccessible = (statut: string) => {
    return (statut === AUTOCONTROLE_STATUT.INACCESSIBLE)
  }

  const getMessageInaccessible = (typeMail: string) => {
    updateMessageSnackBar(MessageAutoControleInaccessible(typeMail));
    history.push(REDIRECT_HOME_ACQ);
  }

  const getMessageAbsenceInfoReferentiel = () => {
    updateMessageSnackBar({
      messages: ["L'autocontrôle n'est plus disponible"],
      severity: SEVERITY.WARNING
    })
    history.push(REDIRECT_HOME_ACQ);
  }

  const getListesFilialesProgrammesTranches = (id_referentiel: string) => {
    return new Promise((res, reject) => {
      const idRefProgramme = removeSegments(id_referentiel, 1, "$");
      const idRefFiliale = removeSegments(id_referentiel, 2, "$");
      // Récupération des différentes listes
      API.getListesFilialesProgrammesTranches(id_referentiel).then((response) => {
        let homePageState: HomePageState = response.data;
        // prise en compte et filtrage des filiales associé au role du user si terrain.
        if (getUserRole().toLowerCase() === userRoles.terrain.toLowerCase()) {
          const userFiliales = getUserFiliales().map((i) => Number(i));
          homePageState.filiales = response.data.filiales.filter((item: { codeSociete: string; }) => userFiliales.includes(parseInt(item.codeSociete, 10)));
        }
        // Selection de la filiale, programme, tranche selectionnée. 
        const filiale = homePageState.filiales.find((filiale: Filiale) => filiale.id_referentiel === idRefFiliale) as Filiale;
        const programme = homePageState.programmes.find((programme: Programme) => programme.id_referentiel === idRefProgramme) as Programme;
        const tranche = homePageState.tranchesTravaux.find((tranche: TrancheTravaux) => tranche.id_referentiel === id_referentiel) as TrancheTravaux;

        // Si problème lors de la récupération du programme ou de la TT à partir d'un référentiel 
        if (!programme || !tranche) {
          homePageState.programmes = [] as Programme[];
          homePageState.tranchesTravaux = [] as TrancheTravaux[];
        }

        // Ajout des listes dans le homePageState =>
        updateHomePageState(homePageState)

        // vérifications pour charger les bonnes informations et faire les bonnes redirections dans le cas où on ne réussi
        // pas à récupérer la filiale, ou le programme, ou la TT.
        // Si pas de filiale on reset tout et on redirige vers l'accueil avec un message spécifique
        if (!filiale) {
          fetchData(null);
          resetGeneralInformationState();
          getMessageInaccessible(typeMailAC);
          res(homePageState);
        }
        // si pas de programme ou TT, on affiche la synthèse mais on réinit les infos générales et le programme/TT selectionné par défaut
        else if (!programme || !tranche) {
          fetchData(null);
          resetGeneralInformationState();
          getMessageAbsenceInfoReferentiel();
          res(homePageState);
        }
        else {
          generalInformations.filiale = filiale;
          generalInformations.programme = programme;
          generalInformations.trancheTravaux = tranche;
          setGeneralInformationsState(trancheTravauxToGeneralInformations(generalInformations, generalInformations.trancheTravaux, generalInformations.typeAutocontrole));
          res(homePageState);
        }
      });
    });
  }

  const redirectOnFiche = (fiche: FicheAutocontrole) => {

    if (isClosed(fiche?.statut as string) || isInaccessible(fiche?.statut as string)) {
      getMessageInaccessible(typeMailAC);
      return;
    }
    // Récupération de l'URL précédente
    const location = history?.location as any;
    const urlFrom = location?.state?.from;
    const isComingFromExpirateErrorPage = !!urlFrom;
    // Si l'URL est celle de l'erreur au statut dépassé, on récupère l'index de la current page dans le store
    const indexPage = isComingFromExpirateErrorPage ? currentState.currentPage : 0;
    const indexGroup = isComingFromExpirateErrorPage ? currentState.currentGroup : 0;
    if (isNotFinished(fiche.statut as string)) {
      updateCurrentPage(indexPage);
      updateCurrentGroup(indexGroup);
    }
    if (isFinished(fiche.statut as string)) {
      if (getUserRole().toLowerCase() !== userRoles.cmq.toLowerCase() && fiche.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE) {
        history.push(REDIRECT_AUTOCONTROLE_TERMINE);
      } else {
        updateCurrentPage(indexPage);
        updateCurrentGroup(indexGroup);
      }
    }
  }

  /**
   * Récupère l'idTT et le type de l'AC en fonction de si on vient d'un mail ou pas
   */
  const getIdTTAndType = () => {
    let infos: { idTT: string, typeTT: string, typeMail: string } = { idTT: '', typeTT: '', typeMail: '' };
    if (!!token) {
      infos = jwt_decode(token as string) as { idTT: string, typeTT: string, typeMail: string };
      typeMailAC = infos.typeMail;
    } else if (generalInformations?.typeAutocontrole && generalInformations?.trancheTravaux?.id_referentiel) {
      infos = { idTT: generalInformations.trancheTravaux.id_referentiel, typeTT: generalInformations.typeAutocontrole, typeMail: '' };
    }
    else {
      getMessageInaccessible(typeMailAC);
    }
    return infos;
  }

  const loadAutocontrole = () => {
    return new Promise((res, reject) => {
      if ((!!generalInformations?.typeAutocontrole && !!generalInformations?.trancheTravaux?.id_referentiel) || !!token) {
        const { idTT, typeTT } = getIdTTAndType() as { idTT: string, typeTT: string, typeMail: string };
        API.getAutocontrole(idTT, typeTT)
          .then((response) => {
            const ficheAutocontrole: FicheAutocontrole = response.data
            fetchData(ficheAutocontrole);
            if (!!ficheAutocontrole) {
              setGeneralInformationsState(!!token ? ficheAutocontroleToGeneralInformations(generalInformations, ficheAutocontrole) : tabInfosToGeneralInformations(generalInformations, ficheAutocontrole.tabInfos))
              updateSynthese(ficheAutocontrole.tabSynthese);
            } else {
              updateSynthese(null);
              generalInformations.typeAutocontrole = typeTT;
              API.getInfosCotationAPI(idTT + '$' + typeTT).then((response) => {
                let infosCotation_: infosCotation = response.data;
                generalInformations = infosCotationToGeneralInformations(infosCotation_, generalInformations);
                setGeneralInformationsState(generalInformations);
              })
                .catch(() => {
                  setGeneralInformationsState(generalInformations);
                  history.push(REDIRECT_HOME_ACQ);
                })
            }
            res(ficheAutocontrole);
          })
          .catch((error) => {
            console.error(error)
            reject("Erreur sur la recuperation de l'AC");
            getMessageInaccessible(typeMailAC);
          }
          )
      } else {
        setLoading(false);
        history.push(REDIRECT_HOME_ACQ);
      }
    })
  }

  React.useEffect(() => {
    setLoading(true);
    loadAutocontrole().then((response) => {
      if (isTokenPresent && isAccessTokenValid()) {
        setIsTokenPresent(false);
        updateIsTabInfos(true);
        resetIntervenantsState();
        const { idTT } = jwt_decode(token as string) as { idTT: string, typeTT: string }
        getListesFilialesProgrammesTranches(idTT).then(() => {
          setLoading(false);
          redirectOnFiche(response as FicheAutocontrole);
        })
      }
      else if ((!token && !data) || (!!token && !isAccessTokenValid())) {
        !token && !data ? getMessageInaccessible(typeMailAC) : history.push(REDIRECT_HOME_ACQ);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createFicheHeader = () => {
    let ficheHeader: { title: string, iconeTab: string }[] = [];

    if (getUserRole().toLowerCase() !== userRoles.cmq.toLowerCase() && isNotFinished((data.statut as string))) {
      ficheHeader.push({ title: data.tabInfos.title, iconeTab: data.tabInfos.iconeTab });
      data.tabs.forEach((tab: FicheAutocontroleTab) => {
        ficheHeader.push({ title: tab.title, iconeTab: tab.iconeTab });
      })
    }

    if (getUserRole().toLowerCase() !== userRoles.terrain.toLowerCase() && isNotFinished((data.statut as string))) {
      ficheHeader.push({ title: data.tabInfos.title, iconeTab: data.tabInfos.iconeTab });
      data.tabs.forEach((tab: FicheAutocontroleTab) => {
        ficheHeader.push({ title: tab.title, iconeTab: tab.iconeTab });
      })
    }

    ficheHeader.push({ title: data.tabSynthese.title, iconeTab: data.tabSynthese.iconeTab });
    return ficheHeader;
  }

  const createPages = () => {
    let pages = [];

    if (getUserRole().toLowerCase() !== userRoles.cmq.toLowerCase() && isNotFinished((data.statut as string))) {
      pages.push((<TabInfosAutocontrole key={data.tabInfos.id} tabInfos={data.tabInfos} />))
      pages.push(...data.tabs.map((tab: FicheAutocontroleTab) => <TabAutocontrole tab={tab} />));
    }

    if (getUserRole().toLowerCase() !== userRoles.terrain.toLowerCase() && isNotFinished((data.statut as string))) {
      pages.push((<TabInfosAutocontrole key={data.tabInfos.id} tabInfos={data.tabInfos} />))
      pages.push(...data.tabs.map((tab: FicheAutocontroleTab) => <TabAutocontrole tab={tab} />));
    }

    pages.push(<TabSyntheseAutocontrole />)
    
    return pages;
  }

  return (
    <div>
      {!loading && !!data ?
        <div className='card-content'>
          <CustomTabMenu headers={createFicheHeader() as { title: string, iconeTab: string }[]} tabs={createPages()} />
        </div>
        : <LoadingBackdrop loading={loading} />
      }
    </div>
  );
};

const mapStateToProps = ({ ficheAutocontrole, generalInformations, currentState }: ApplicationState) => ({
  data: ficheAutocontrole.data,
  generalInformations: generalInformations,
  currentState: currentState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateCurrentPage: (params: number) => dispatch({ type: CurrentStateActions.CHANGE_PAGE, params: params }),
    fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
    resetIntervenantsState: () => dispatch({ type: IntervenantsAutocontroleTypes.RESET_STATE }),
    updateIsTabInfos: (params: boolean) => dispatch({ type: CurrentStateActions.IS_TABINFOS, params: params }),
    resetGeneralInformationState: () => dispatch({ type: GeneralInformationsTypes.CHANGE_TYPE_AUTOCONTROLE }),
    updateHomePageState: (params: HomePageState) => dispatch({ type: HomePageTypes.UPDATE_STATE, payload: params }),
    setGeneralInformationsState: (params: GeneralInformationsState) => dispatch({ type: GeneralInformationsTypes.SET_GENERAL_INFORMATIONS, payload: params }),
    updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params }),
    updateSynthese: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FicheAutoControle);