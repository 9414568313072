import { FicheAutocontroleIntervenant } from "../../store/types/FicheAutocontrole.Types";

export const intervenantToTabInfosIntervenant = (intervenant:any) => {
    return {
        id_gpi: !!intervenant.id_gpi ? intervenant.id_gpi : '',
        nom: !!intervenant.nom ? intervenant.nom : '',
        mail: !!intervenant.mail ? intervenant.mail : '',
        isInterne : !!intervenant.isInterne ? intervenant.isInterne : false
    } as FicheAutocontroleIntervenant;
    
}