import { CurrentState, CurrentStateActions } from "../types/CurrentState.Types";

const initialState:CurrentState = {
    currentPage : 0,
    currentGroup : -1,
    isTabInfos: true,
}

const reducer  = (state = initialState, action: { type: string; params: number|boolean } = {type:'', params: -1}) =>{
    
    switch(action.type){
        case CurrentStateActions.CHANGE_PAGE:{
            let copy = {...state};
            copy.currentPage = action.params as number;
            return copy;
        }
        case CurrentStateActions.CHANGE_GROUP:{
            let copy = {...state};
            copy.currentGroup = action.params as number;
            return copy;
        }
        case CurrentStateActions.IS_TABINFOS:{
            let copy = {...state};
            copy.isTabInfos = action.params as boolean;
            return copy
        }
        default:{
            return state;
        }
    }
};

export {reducer as currentStateReducer};