export interface CurrentState{
    currentPage : number,
    currentGroup : number,
    isTabInfos: boolean,
}

export enum CurrentStateActions{
    CHANGE_PAGE = 'CHANGE_PAGE',
    CHANGE_GROUP = 'CHANGE_GROUP',
    IS_TABINFOS = 'IS_TABINFOS'
}