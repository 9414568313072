import { FicheAutocontrole } from "../store/types/FicheAutocontrole.Types";

export default class DateUtils {

  /**
   * Calculate :
   */

  static addDays = (date: number, days: number) => {
    return date + (days * (1000 * 60 * 60 * 24));
  }

  static addMonths = (date: number, months: number) => {
    let dateTmp = new Date(date)
    dateTmp.setMonth(dateTmp.getMonth() + months);
    return dateTmp.getTime();
  }

  static removeDays = (date: number, days: number) => {
    return date - (days * (1000 * 60 * 60 * 24));
  }

  static dateDiffInDays(dateToCompare: number, now: number) {
    return Math.round((dateToCompare - now) / (1000 * 60 * 60 * 24));
  }

  static hasMoreThanAnYear = (date: number) => {
    return (DateUtils.dateDiffInDays(date, Date.now()) < -366)
  }

  static hasMoreThanXMonth = (date: number, nbMonth: number) => {
    return (DateUtils.dateDiffInDays(date, Date.now()) < - (12 * nbMonth))
  }

  /**
   * Vérifie si la date passée en paramètre est bien comprise entre dateBefore et dateAfter
   * @param date la date à vérifier
   * @param dateBefore la date avant (à gauche)
   * @param dateAfter la date après (à droite)
   * @returns true si la date est entre les deux, false sinon
   */
  static isBetween = (date: number, dateBefore: number, dateAfter: number) => {
    return (date >= dateBefore) && (date <= dateAfter)
  }

  static withoutTime = (date: any) => {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static isBeforeNow = (date: number) => {
    return DateUtils.withoutTime(date) < DateUtils.withoutTime(Date.now());
  }

  /**
   * Si la date limite est supérieur à 30 jours à la date du jour, la couleur retourné est noir
   * Si la date limite est entre le jour même et 30 jours supérieur à la date du jour, la couleur retourné est orange
   * Si la date limite est expiré, la couleur retourné est rouge
   * @param dateLimite 
   * @returns la classe css correspondant à la couleur souhaitée.
   */
  static getDateLimiteColor = (dateLimite: number) => {
    let timeBetweenDate = DateUtils.withoutTime(dateLimite).getTime() - DateUtils.withoutTime(Date.now()).getTime()

    if (timeBetweenDate > 2592000000) {
      return "date-not-expired"
    } else {
      if (timeBetweenDate <= 2592000000 && timeBetweenDate >= 0) {
        return "date-near-expired"
      } else {
        return "date-expired"
      }
    }
  }

  static getDateLimiteFromDateLivraison = (dateLivraison: number, typeAutocontrole: string) => {
    let daysToAdd = typeAutocontrole === "L-4" ? -120 : -45
    return DateUtils.addDays(dateLivraison, daysToAdd);
  }

  /**
   * Mappers :
   */
  static dateBackToDateFront = (dateBack: number | string) => {
    let dateTmp = new Date(dateBack)
    let days = dateTmp.getDate().toString().length === 1 ? '0' + dateTmp.getDate() : dateTmp.getDate()
    let month = (dateTmp.getMonth() + 1).toString().length === 1 ? '0' + (dateTmp.getMonth() + 1) : (dateTmp.getMonth() + 1)

    return days + '/' + month + '/' + dateTmp.getFullYear()
  }

  static dateBackToDateCritere = (dateBack: number) => {
    let dateTmp = new Date(dateBack)
    let days = dateTmp.getDate().toString().length === 1 ? '0' + dateTmp.getDate() : dateTmp.getDate()
    let month = (dateTmp.getMonth() + 1).toString().length === 1 ? '0' + (dateTmp.getMonth() + 1) : (dateTmp.getMonth() + 1)

    return dateTmp.getFullYear() + '-' + month + '-' + days
  }

  static dateCritereToDateBack = (dateCritere: string) => {
    let dateTmp = dateCritere.substr(6, 4) + "-" + dateCritere.substr(3, 2) + "-" + dateCritere.substr(0, 2);

    return (new Date(dateTmp)).getTime()
  }

  static dateTransform = (dateCritere: any) => {
    let result: any;

    typeof (dateCritere) === 'string' ? result = new Date(dateCritere).getTime() : result = dateCritere;

    return result;
  }

  static getDateMajForStatut = (data: FicheAutocontrole, filterStatut: string) => {
    if (data.statutHisto) {
      const statusFiltered = data.statutHisto.filter((st) => st.libelle.toLowerCase() === filterStatut.toLowerCase());
      const lastStatus = statusFiltered[statusFiltered.length - 1];
      return lastStatus ? lastStatus.dateMAJ : 0;
    }
    return 0;
  }

  static formattedDate = (dateValue: number) => {
    return !!dateValue && dateValue !== 0 ? DateUtils.dateBackToDateFront(dateValue) : '-';
  }

} 