import { FicheAutocontroleIntervenant } from "./FicheAutocontrole.Types";

export interface GeneralInformationsState {
    filiale: Filiale,
    programme: Programme,
    trancheTravaux: TrancheTravaux,
    nbLots: number,
    typeAutocontrole: string,
    responsableProgramme : FicheAutocontroleIntervenant,
    conducteurTravaux : FicheAutocontroleIntervenant,
    chargeMissionQualite : FicheAutocontroleIntervenant,
    responsableTechnique: FicheAutocontroleIntervenant,
    directeurOperationnel:FicheAutocontroleIntervenant,
    directeurProgrammes:FicheAutocontroleIntervenant,
    ficheStatut : string,
    dateLimite : number,
    dateLivraison: number,
    isDisplayed: boolean,
    tranchesTravauxLiees?: TrancheTravauxLiee[]
}

export enum GeneralInformationsTypes {
    SET_GENERAL_INFORMATIONS = "@@generalInformations/SET_GENERAL_INFORMATIONS",
    CHANGE_TYPE_AUTOCONTROLE = "@@generalInformations/CHANGE_TYPE_AUTOCONTROLE"
}

export interface ReferentielFront {
    id: string,
    value: string
}

export interface Filiale{
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    libelleSociete: string,
    codeSociete: string,
    checked: boolean,
    actif: boolean,
}

export interface Programme {
    regroupementResp: string;
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    libelleProgramme: string,
    libelleCommercial: string,
    codeProgramme: string,
    codeSociete: string,
    actif: boolean,
    adresse1: string,
    adresse2: string,
    adresse3: string,
    ville: string,
    codePostal: string,
}

export interface TrancheTravaux {
    id_referentiel: string,
    id_param_type: string,
    id: string,
    id_gpi: string,
    codeSociete: string,
    codeProgramme: string,
    codePhase: string,
    libelleTranche: string,
    codeTranche: string,
    dateLivraisonPrevue: number,
    actif: boolean,
    nbLots: number
}

export interface TrancheTravauxLiee {
    id_pk?:string,
    id_referentiel: string,
    libelleTranche: string,
    codeTranche: string,
    isCreated?: boolean,
    nbLots: number
}

export enum AUTOCONTROLE_STATUT {
    INACCESSIBLE = 'Inaccessible',
    FERME= 'Fermé',
    OUVERT= 'Ouvert',
    FICHE_FINALISEE = "Fiche finalisée",
    FICHE_COMPLETEE = "Fiche complétée",
    DELAI_DEPASSE = "Délai dépassé",
    RAPPORT_ENVOYE = "Rapport envoyé",
}