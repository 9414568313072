import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@chakra-ui/core";
import customTheme from "../theme";
import { Provider } from "react-redux";
import { ApplicationState } from "../store/store/store";
import { Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Routes from "../routes";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/Navbar/NavBar";
import CustomSnackBar from "../components/CustomSnackBar/CustomSnackBar.component"
import Authentification from "./Authentification/Authentification";
import Logger from "../utils/Logger";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { BiChevronUpCircle } from "react-icons/bi";
import { connect } from 'react-redux';
import { CurrentState } from "../store/types/CurrentState.Types";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}
interface PropsFromDispatch {
  currentState: CurrentState;
}

type AllProps = MainProps & PropsFromDispatch;

const App: React.FC<AllProps> = ({ store, history, currentState }) => {

  const [visible, setVisible] = useState(false);
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  const updateMedia = () => {
    setWidthScreen(window.innerWidth);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };


  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  window.addEventListener('scroll', toggleVisible);

  // init datadog configuration
  Logger.init();

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  return (
    <>
      <Authentification>
        <Provider store={store}>
          <ThemeProvider theme={customTheme}>
            <ConnectedRouter history={history}>
              <ScrollToTop />
              <CustomSnackBar />
              <NavBar>
                <Routes />
              </NavBar>
            </ConnectedRouter>
            {widthScreen < 952 && currentState.currentGroup !== null && currentState.currentGroup !== undefined && currentState.currentGroup > -1 ? '' :
              <BiChevronUpCircle id="back-to-top" onClick={scrollToTop} style={{ display: visible ? '' : 'none' }} />}
          </ThemeProvider>
        </Provider>

      </Authentification>
      <Footer />
    </>
  );
};

const mapStateToProps = ({ currentState }: ApplicationState) => ({
  currentState: currentState
});

export default connect(mapStateToProps)(App);

