export interface UserState {
    settingRGPD:boolean
}

export enum userRoles {
    cmq = 'CMQ',
    terrain = 'TERRAIN'
}
export enum UserTypes{
    GET_USER = "@@user/GET_USER",
    SET_USER = "@@user/SET_USER"
}