import { Checkbox, Radio, Slider, Switch, TextField, Typography, withStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { colorsAutocontrole } from "./ColorsAutocontrole.style";

export const SliderAutocontroleStyled = withStyles({
    root: {
      padding:8,
      marginLeft: 8,
      width: '90%',
      color: colorsAutocontrole.default,
      '&$disabled':{
        color: colorsAutocontrole.sliderRailDisabled,
      }
    },
    active: {},
    disabled: {},
    valueLabel: {
      left: 'calc(-50%)',
      top: -22,
      '& *': {
        fontSize: 'calc(1.1rem)',
        width:100,
        background: 'transparent',
        color: colorsAutocontrole.primary,
      },
    },
    track: {
      height: 8,
      borderRadius: 4,
      color: colorsAutocontrole.secondary,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
})(Slider);

export const RadioAutocontroleStyled = withStyles({
    root: {
        color: colorsAutocontrole.checkboxDefault,
        '&$checked': {
          color: colorsAutocontrole.primary,
        },
      },
      checked: {},
})(Radio);

export const CheckboxAutocontroleStyled = withStyles({
    root: {
        color: colorsAutocontrole.checkboxDefault,
        '&$checked': {
          color: colorsAutocontrole.primary,
        },
      },
      checked: {},
})(Checkbox);

export const SwitchAutocontroleStyled = withStyles({
    root:{
        float:'inline-end',
        marginTop:-8,
    },
    track:{
      backgroundColor: colorsAutocontrole.default,
    },
    switchBase: {
        color: '#ffffff',
        '&$checked': {
            color: colorsAutocontrole.primary,
        },
        '&$checked + $track': {
            backgroundColor: colorsAutocontrole.secondary,
        }
    },
    checked: {},
})(Switch);

export const LabelCritereAutocontroleStyled = withStyles({
    root:{
        fontWeight: 600,
        fontSize: 16,
        fontStyle: 'normal',
        lineHeight: '20 (1,25)',
        fontColor: colorsAutocontrole.default,
        marginTop:32,
        marginRight: 8,
        display: 'inline-block',
        verticalAlign: 'middle'
    }
})(Typography);

export const LabelCritereSelectAutocontroleStyled = withStyles({
  root:{
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: '20 (1,25)',
      fontColor: colorsAutocontrole.default,
  }
})(Typography);

export const AutocompleteStyled = withStyles({
  root:{
    marginTop:24,
    width:'100%',
  },
  popupIndicator:{
    marginTop: '-50%',
    color:colorsAutocontrole.primary,
    transform:'rotate(90deg)',
  }
})(Autocomplete)

export const TextFieldStyled = withStyles({
  root: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
})(TextField)